import React, { useEffect } from "react";
import { Direction } from "./RangeSlideButton";

interface Props {
  onComplete(): void;
  side: Direction;
  ribbonOfferWidth: number;
  years?: number;
}

let startResizeMove = false;
let previousSize = 0;
let reSizeNode: HTMLDivElement | null = null;

const ResizeHandleBar: React.FC<Props> = ({
  onComplete,
  side,
  ribbonOfferWidth,
  years = 1,
}) => {
  const BAR_MAX_WIDTH = 300 * years;
  const MIN_SLIDER_WIDTH = 25;
  const leftWidthHandler = (e: any) => {
    const rangeBar = reSizeNode?.parentElement;
    if (!rangeBar) {
      return;
    }
    let left = rangeBar.style.left.ReomvePx(30);
    let barWidth = rangeBar.style.width.ReomvePx(BAR_MAX_WIDTH);
    barWidth = barWidth - (e.clientX - previousSize);
    left += e.clientX - previousSize;
    if (barWidth > BAR_MAX_WIDTH || barWidth < MIN_SLIDER_WIDTH || left < 30) {
      return;
    }
    rangeBar.style.width = `${barWidth}px`;
    rangeBar.style.left = `${left}px`;
    previousSize = e.clientX;
  };
  const rightWidthHandler = (e: any) => {
    const rangeBar = reSizeNode?.parentElement;
    if (!rangeBar) {
      return;
    }
    let barWidth = rangeBar.style.width.ReomvePx(BAR_MAX_WIDTH);
    const left = rangeBar.style.left.ReomvePx();
    barWidth = barWidth + e.clientX - previousSize;
    if (
      barWidth > BAR_MAX_WIDTH ||
      barWidth < MIN_SLIDER_WIDTH ||
      ribbonOfferWidth - 30 < barWidth + left
    ) {
      return;
    }
    rangeBar.style.width = `${barWidth}px`;
    previousSize = e.clientX;
  };
  const resizeMoveHandler = (e: any): void => {
    if (!startResizeMove || !reSizeNode || e.buttons === 0) {
      return;
    }
    if (reSizeNode.className.includes("left")) {
      leftWidthHandler(e);
    } else if (reSizeNode.className.includes("right")) {
      rightWidthHandler(e);
    }
  };
  const resizeStatHandler = (value: boolean) => (e: any) => {
    if ((e.target as HTMLElement).nodeName !== "DIV") {
      return;
    }
    if (!e.target.className.includes("re-s-e")) {
      if (!value) {
        reSizeNode = null;
      }
      return;
    }
    startResizeMove = value;
    previousSize = e.clientX;
    reSizeNode = e.target;
    if (reSizeNode && !value) {
      onComplete();
    }
    if (!value) {
      reSizeNode = null;
    }
  };
  useEffect(() => {
    document.addEventListener("mousemove", resizeMoveHandler);
    document.addEventListener("mouseup", resizeStatHandler(false));
    return (): void => {
      document.removeEventListener("mousemove", resizeMoveHandler);
      document.removeEventListener("mouseup", resizeStatHandler(true));
    };
    // eslint-disable-next-line
  }, []);
  let css = "re-s-e expander expander-right";
  if (side === Direction.LEFT) {
    css = "re-s-e expander expander-left";
  }
  return (
    <div
      className={css}
      onMouseDown={resizeStatHandler(true)}
      onMouseUp={resizeStatHandler(false)}
    ></div>
  );
};

export default ResizeHandleBar;
declare global {
  interface String {
    ReomvePx(emptyValue?: number): number;
  }
}
// eslint-disable-next-line
String.prototype.ReomvePx = function (emptyValue = 0): number {
  const str = this.replace("px", "");
  return parseInt(str === "" ? emptyValue.toString() : str);
};
