import React, { useState } from "react";
import { FormBox } from "@austaltech/components";
import {
  Labels,
  MaintenanceElementDemoFieldNames,
  ActivityType,
  MaintenanceElement,
  InitMaintenanceElement,
} from "../../models/maintenanceModel";
import ActivityRow from "../../components/ActivityRow";
import { Button, Modal, Form } from "antd";
import { SketchPicker, ColorResult } from "react-color";
import ActivityForm from "../../components/ActivityForm";
import MaintenanceSchedule from "../../components/MaintenanceSchedule";
import { MaintenanceElementService } from "../../services/MaintenanceElementService";
import { UpdateMaintenanceElementServerType } from "../../../../models/serverTypes";
import { PlusOutlined } from "@ant-design/icons";
import { ConfigurationId, Message, onMessage } from "../../../../utils/helper";
import {
  DEFAULT_ERROR_MESSAGE,
  MAINTAIN_VALIDATE_STATUS_CODE,
  MAINTENANCE_EXIT,
  MessageType,
  ROUTINE_ELEMENTS_ADD_SUCCESS,
  ROUTINE_ELEMENTS_UPDATE_SUCCESS,
} from "../../../../utils/constant";
import "./maintenance.scss";

interface Props {
  aircraftType: string;
  elementsList: MaintenanceElement[];
  setElements: (elements: MaintenanceElement[]) => void;
  activityTypes: ActivityType[];
  isTabView?: boolean;
}

const MaintenanceElements: React.FC<Props> = ({
  aircraftType,
  elementsList,
  activityTypes,
  setElements,
  isTabView,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [color, setColor] = useState<string>("red");
  const [selectedRow, setSelectedRow] = useState<
    MaintenanceElement | undefined
  >();
  const [form] = Form.useForm();
  const configId = ConfigurationId() as number;

  const createRequestObject = (row: MaintenanceElement) => {
    const newObj: UpdateMaintenanceElementServerType = {
      maintenanceId: row.maintenanceId,
      fleetConfigurationId: row.fleetConfigurationId,
      colorCode: row.colorCode,
      maintenanceActivityType: row.maintenanceActivityType,
      id: row.id,
      intervalHrs: row.intervalHrs,
      intervalDays: row.intervalDays,
      workingDays: row.workingDays,
      workingHrs: row.workingHrs,
      cost: row.cost,
      isDeepMaintenance: row.isDeepMaintenance,
      consumabales: row.consumabales,
      parts: row.parts,
      spares: row.spares,
      sAndTE: row.sAndTE,
      workForceAF: row.workForceAF,
      workForceAE: row.workForceAE,
      workForceAV: row.workForceAV,
      workForceAS: row.workForceAS,
      workForceAR: row.workForceAR,
      workForceSF: row.workForceSF,
      workForceNDT: row.workForceNDT,
      workForceLS: row.workForceLS,
      workForceFL: row.workForceFL,
      workForceFK: row.workForceFK,
      bayIds: row.bayIds || [],
    };
    return newObj;
  };

  const CreateElement = async (row: MaintenanceElement) => {
    return await MaintenanceElementService.Create(createRequestObject(row));
  };
  const UpdateElement = async (row: MaintenanceElement) => {
    return await MaintenanceElementService.Update(createRequestObject(row));
  };
  const deleteRow = (idx: number) => async () => {
    const result = await MaintenanceElementService.Delete(elementsList[idx].id);
    if (result) {
      elementsList.splice(idx, 1);
      setElements([...elementsList]);
    }
  };
  const setEditCancel = (idx: number) => {
    if (elementsList && selectedRow) {
      elementsList[idx] = selectedRow;
      setElements([...elementsList]);
      setSelectedRow({ ...InitMaintenanceElement });
    }
  };
  const updateRow = async (idx: number) => {
    const res = await UpdateElement(elementsList[idx]);
    if (res) {
      onMessage({
        content: ROUTINE_ELEMENTS_UPDATE_SUCCESS,
        type: MessageType.SUCCESS,
      } as Message);
    }
    if (!res) {
      onMessage({
        content: DEFAULT_ERROR_MESSAGE,
        type: MessageType.ERROR,
      } as Message);
      setEditCancel(idx);
    } else setSelectedRow({ ...InitMaintenanceElement });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const errors = await form.validateFields();
    if (errors) {
      if (selectedRow) {
        const val = elementsList.filter((a) => a.id === selectedRow.id)[0] || {
          ...selectedRow,
        };
        val.maintenanceActivityType = form.getFieldValue(
          MaintenanceElementDemoFieldNames.activityName
        );
        val.colorCode = color;
        val.maintenanceId = form.getFieldValue(
          MaintenanceElementDemoFieldNames.id
        );
        val.bayIds = null;
        if (selectedRow.id === 0) {
          const newElementId = await CreateElement(val);
          if (newElementId === MAINTAIN_VALIDATE_STATUS_CODE) {
            onMessage({
              content: MAINTENANCE_EXIT,
              type: MessageType.WARNING,
            } as Message);
            return;
          }
          if (newElementId === 0) {
            onMessage({
              content: DEFAULT_ERROR_MESSAGE,
              type: MessageType.ERROR,
            } as Message);
            return;
          }
          if (newElementId > 0) {
            onMessage({
              content: ROUTINE_ELEMENTS_ADD_SUCCESS,
              type: MessageType.SUCCESS,
            } as Message);
            val.id = newElementId;
            elementsList.push(val);
            setElements([...elementsList]);
          }
        } else if (selectedRow.id > 0 && UpdateElement(val)) {
          setElements([...elementsList]);
        }

        setSelectedRow({ ...InitMaintenanceElement });
      }
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRow({ ...InitMaintenanceElement });
  };
  const addRow = () => {
    showModal();
    form.setFields([
      { name: MaintenanceElementDemoFieldNames.activityName, value: "" },
      { name: MaintenanceElementDemoFieldNames.id, value: "" },
    ]);
    InitMaintenanceElement.fleetConfigurationId = configId;
    setSelectedRow({ ...InitMaintenanceElement });
  };
  const rowSelect = (idx: number) => () => {
    const row = elementsList[idx];
    setSelectedRow({ ...row });
    if (row) setColor(row.colorCode);
    form.setFields([
      {
        name: MaintenanceElementDemoFieldNames.activityName,
        value: row.maintenanceActivityType,
      },
      { name: MaintenanceElementDemoFieldNames.id, value: row.maintenanceId },
    ]);
    showModal();
  };
  const handleChangeComplete = (color: ColorResult) => {
    setColor(color.hex);
  };
  const changeValue = (idx: number, name: string, value: number) => {
    (elementsList[idx] as any)[name] = value;
    setElements([...elementsList]);
  };
  const setEditableRow = (idx: number) => {
    const row = elementsList[idx];
    setSelectedRow({ ...row });
  };
  return (
    <div className="route__analysis">
      {!isTabView && <h1>{Labels.StepTitle}</h1>}
      <FormBox label={`${aircraftType} ${Labels.FormTitle}`}>
        <div className="route__analysis--table--container table-fix-head">
          <table className="route__analysis--table">
            <tr>
              <th style={{ width: "140px" }}>{Labels.ThActiveType}</th>
              <th style={{ width: "80px" }}>{Labels.ThID}</th>
              <th style={{ width: "50px" }}>{Labels.ThIntervalHours}</th>
              <th style={{ width: "50px" }}>{Labels.ThIntervalDays}</th>
              <th style={{ width: "50px" }}>{Labels.ThWorkingDays}</th>
              <th style={{ width: "50px" }}>{Labels.WorkingHours}</th>
              <th style={{ width: "50px" }}>{Labels.cost}</th>
              <th style={{ width: "120px" }}></th>
            </tr>
            <tbody>
              {elementsList.map((data: MaintenanceElement, idx: number) => (
                <ActivityRow
                  {...data}
                  activityType={
                    activityTypes?.find(
                      (a) => a.id === data.maintenanceActivityType
                    )?.activityType || ""
                  }
                  selectedRowId={selectedRow?.id || 0}
                  isLast={elementsList.length - 1 === idx}
                  key={`rwa${idx}`}
                  onAdd={addRow}
                  onDelete={deleteRow}
                  idx={idx}
                  onSelect={rowSelect}
                  onChange={changeValue}
                  setEditableRow={setEditableRow}
                  setCancel={setEditCancel}
                  onUpdate={updateRow}
                />
              ))}
            </tbody>
          </table>
          {elementsList.length === 0 && (
            <Button
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={addRow}
              style={{ fontSize: "3px", marginLeft: "5px" }}
              className="add-btn-cm"
            />
          )}
        </div>
      </FormBox>
      <br />
      {!isTabView && (
        <FormBox label={`${aircraftType} ${Labels.FormChartTitle}`}>
          <MaintenanceSchedule data={elementsList} />
        </FormBox>
      )}
      <Modal
        title="Add new Unit"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ActivityForm
          form={form}
          activityTypeId={selectedRow?.maintenanceActivityType}
          id={selectedRow?.id || 0}
          activityTypes={activityTypes}
        />
        <SketchPicker onChange={handleChangeComplete} color={color} />
      </Modal>
    </div>
  );
};

export default MaintenanceElements;
