import React, { CSSProperties } from "react";
import aircraft from "./aircraft.svg";

interface Props {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

const DoubleAircraftImage: React.FC<Props> = ({
  className,
  style,
  onClick,
}) => {
  return (
    <img
      alt="aircraft"
      className={className}
      style={style}
      src={aircraft}
      onClick={onClick}
    />
  );
};

export default DoubleAircraftImage;
