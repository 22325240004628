import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "../features/navigation/redux";
import { RootSliceType } from "../redux";
import appSlice from "../redux/appSlice";
import {
  AUTH_USER,
  LOGGING_USER,
  LAST_STEP,
  LAST_URL,
  CONFIGURATION_ID,
  STRING_NUM_VALIDATION,
  AUTH_USER_NAME,
} from "../utils/constant";
import { getFromStorage } from "../utils/localStorage";
import { API } from "./";

const useAxiosInterceptor = () => {
  const dispatch = useDispatch();
  const { currentStep } = useSelector<RootSliceType, Navigation>(
    (state) => state.naviReducer
  );
  const { fleetType } = useSelector<RootSliceType, Navigation>(
    (state) => state.naviReducer
  );
  let configId = "";
  const url = window.location.pathname;
  if (url.split("/").length > 2) {
    configId = STRING_NUM_VALIDATION.test(
      url.split("/")[url.split("/").length - 1]
    )
      ? url.split("/")[url.split("/").length - 1]
      : "";
  }
  const user = getFromStorage(LOGGING_USER);
  const email = user ? JSON.parse(user).email : "";
  const name = user ? JSON.parse(user).name : "";
  const setInterceptor = () => {
    const request = API.interceptors.request.use((config) => {
      const headers = config.headers;
      config.headers = {
        ...headers,
        [AUTH_USER]: email,
        [LAST_STEP]: currentStep,
        [AUTH_USER_NAME]: name,
        [LAST_URL]: url,
        [CONFIGURATION_ID]: configId || fleetType?.id || 0,
      };
      dispatch(appSlice.actions.requestData({ value: true, error: "" }));
      return config;
    });
    const response = API.interceptors.response.use(
      (response) => {
        dispatch(
          appSlice.actions.requestData({
            value: false,
            error: response ? "success" : "error",
          })
        );
        return response;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (error) => {
        dispatch(
          appSlice.actions.requestData({ value: false, error: "error" })
        );
      }
    );
    return () => {
      API.interceptors.request.eject(request);
      API.interceptors.response.eject(response);
    };
  };
  useEffect(setInterceptor, [email, currentStep]);
};

export default useAxiosInterceptor;
