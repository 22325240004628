import React from "react";
import icon from "./icon.svg";

interface Props {
  className?: string;
  handleChange?: () => void;
}

const ScheduleViewIcon: React.FC<Props> = ({ className, handleChange }) => {
  return (
    <img
      alt="ScheduleViewIcon"
      className={className}
      src={icon}
      onClick={handleChange}
    />
  );
};

export default ScheduleViewIcon;
