import React, { useEffect } from "react";
import * as d3 from "d3";

const EngineArrow = () => {
  const drawChart = () => {
    const svg = d3
      .select(".ac__arrowsvg")
      .append("svg")
      .attr("width", "130")
      .attr("height", "320");

    const lineGenerator = d3.line();
    const line1: Array<[number, number]> = [
      [0, 67],
      [50, 67],
      [50, 30],
      [110, 30],
    ];
    const line2: Array<[number, number]> = [
      [0, 167],
      [110, 167],
    ];
    const line3: Array<[number, number]> = [
      [0, 105],
      [110, 105],
    ];
    const line4: Array<[number, number]> = [
      [0, 200],
      [50, 200],
      [50, 237],
      [110, 237],
    ];
    const lineColor = "#cccccc";
    svg
      .append("path")
      .attr("d", lineGenerator(line1) as string)
      .attr("fill", "none")
      .attr("stroke", lineColor);
    svg
      .append("path")
      .attr("d", lineGenerator(line2) as string)
      .attr("fill", "none")
      .attr("stroke", lineColor);
    svg
      .append("path")
      .attr("d", lineGenerator(line3) as string)
      .attr("fill", "none")
      .attr("stroke", lineColor);
    svg
      .append("path")
      .attr("d", lineGenerator(line4) as string)
      .attr("fill", "none")
      .attr("stroke", lineColor);
  };
  useEffect(drawChart, []);
  return <div className="ac__arrowsvg"></div>;
};
export default EngineArrow;
