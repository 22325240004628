import React from "react";

import { AppProviders } from "./AppProviders";
import routes, { RenderRoutes } from "./routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppProviders>
          <RenderRoutes routes={routes} />
        </AppProviders>
      </PersistGate>
    </Provider>
  );
};

export default App;
