import React from "react";
import "./fleetbuildlayout.scss";

const LeftSidePanel: React.FC = ({ children }) => {
  return (
    <div className="left-container">
      <div className="side-contents">{children}</div>
    </div>
  );
};
export default LeftSidePanel;
