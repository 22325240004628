import React, { CSSProperties } from "react";
import fma from "./fma.png";

interface Props {
  className?: string;
  style?: CSSProperties;
}

const FmaImage: React.FC<Props> = ({ className, style }) => {
  return <img alt="fma" className={className} style={style} src={fma} />;
};

export default FmaImage;
