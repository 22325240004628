const FLAMINGO = "#E6614B";
const STEEL_BLUE = "#3890A7";

const LABEL_ONE = "Build Fleet/SQN Objectives + Schedules";
const LABEL_TWO = "Initial Operating Capability";
const LABEL_THREE = "Operating Profiles";
const LABEL_FOUR = "A/C Configuration";
const LABEL_FIVE = "A/C Operating Limits";
const LABEL_SIX = "Maintenance";

export const stepperItems = [
  {
    step: 1,
    type: "main",
    label: LABEL_ONE,
    activecolor: FLAMINGO,
    className: "",
    highlighted: 0,
  },
  {
    step: 1.1,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-1",
    highlighted: 1,
  },
  {
    step: 1.2,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-1",
    highlighted: 1,
  },
  {
    step: 1.3,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-1",
    highlighted: 1,
  },
  {
    step: 1.4,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-1",
    highlighted: 1,
  },
  {
    step: 1.5,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-1",
    highlighted: 1,
  },
  {
    step: 1.6,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-1",
    highlighted: 1,
  },
  {
    step: 1.7,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-1",
    highlighted: 1,
  },
  {
    step: 1.8,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-1",
    highlighted: 1,
  },
  {
    step: 1.85,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-1",
    highlighted: 1,
  },
  {
    step: 2,
    type: "main",
    label: LABEL_TWO,
    activecolor: FLAMINGO,
    className: "",
    highlighted: 0,
  },
  {
    step: 2.1,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-2",
    highlighted: 0,
  },
  {
    step: 2.2,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-2",
    highlighted: 0,
  },
  {
    step: 2.3,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-2",
    highlighted: 0,
  },
  {
    step: 2.4,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-2",
    highlighted: 0,
  },
  {
    step: 2.5,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-2",
    highlighted: 0,
  },
  {
    step: 2.6,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-2",
    highlighted: 0,
  },
  {
    step: 2.7,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-2",
    highlighted: 0,
  },
  {
    step: 3,
    type: "main",
    label: LABEL_THREE,
    activecolor: FLAMINGO,
    className: "",
    highlighted: 0,
  },
  {
    step: 3.1,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-3",
    highlighted: 0,
  },
  {
    step: 3.2,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-3",
    highlighted: 0,
  },
  {
    step: 4,
    type: "main",
    label: LABEL_FOUR,
    activecolor: STEEL_BLUE,
    highlighted: 0,
    className: "",
  },
  {
    step: 4.1,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-4",
    highlighted: 0,
  },
  {
    step: 4.2,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-4",
    highlighted: 0,
  },
  {
    step: 4.3,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-4",
    highlighted: 0,
  },
  {
    step: 5,
    type: "main",
    label: LABEL_FIVE,
    activecolor: STEEL_BLUE,
    highlighted: 0,
    className: "",
  },
  {
    step: 5.1,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-5",
    highlighted: 0,
  },
  {
    step: 5.2,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-5",
    highlighted: 0,
  },
  {
    step: 5.3,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-5",
    highlighted: 0,
  },
  {
    step: 6,
    type: "main",
    label: LABEL_SIX,
    className: "",
    activecolor: STEEL_BLUE,
    highlighted: 0,
  },
];

export const maintenanceStepperItems = [
  {
    step: 6.1,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-6",
    highlighted: 0,
  },
  {
    step: 6.2,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-6",
    highlighted: 0,
  },
  {
    step: 6.3,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-6",
    highlighted: 0,
  },
  {
    step: 6.4,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-6",
    highlighted: 0,
  },
  {
    step: 6.5,
    type: "sub",
    label: "",
    activecolor: FLAMINGO,
    className: "sub-6",
    highlighted: 0,
  },
];
