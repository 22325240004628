import React from "react";
import { useHistory } from "react-router-dom";
import { Label } from "@austaltech/components";
import RoutePaths from "../../routePaths";
import "./fleetbuildlayout.scss";

const TitleBar: React.FC = ({ children }) => {
  const { push } = useHistory();
  return (
    <div className="header-container">
      <div className="header-title">
        <h1 className="title" onClick={() => push(RoutePaths.root)}>
          Build
        </h1>
        <div className="underline"></div>
      </div>
      <div>
        <div className="title-bar-img">
          <img
            src="/assets/images/fleetcapability-managemet-steps.svg"
            alt="Fleet Capability Management"
          />
          <Label>Fleet Capability Management</Label>
        </div>
      </div>
      {children}
    </div>
  );
};

export default TitleBar;
