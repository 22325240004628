import React, { useState } from "react";
import "./runtime.scss";
import { Labels } from "../../models/runTimeScheduleModel";
import MaintenanceSchedule from "../../components/MaintenanceSchedule";
import type {
  MaintenanceElement,
  MaintenanceElementDemo,
  RibbonYear,
} from "../../models/maintenanceModel";
import dataset from "../../models/RoutineElement.json";
import RangeRibbon from "../../../fleetOperationManagement/forms/eventScheduleForm/controls/RangeRibbon";
import RunTimeOther from "./RunTimeOther";
import ACRunTimeSchedule from "./ACRunTimeSchedule";
import moment from "moment";
const fixHours = 16.66;
const startDate = new Date("2020/1/1");
interface Props {
  elementsList: MaintenanceElement[];
  isTabView?: boolean;
}
const RunTimeSchedule: React.FC<Props> = ({ elementsList, isTabView }) => {
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(fixHours * 12);

  const updateHandler = (e: any) => {
    const dates = e as Array<RibbonYear>;
    if (dates.length <= 0) {
      return;
    }

    const firstItem = dates[0];
    const lastItem = dates[dates.length - 1];
    const chartStartDate = new Date(`${firstItem.year}/${firstItem.month}/1`);
    const chartEndDate = new Date(`${lastItem.year}/${lastItem.month}/28`);

    const months = Math.round(
      moment.duration(moment(chartEndDate).diff(startDate)).asMonths()
    );
    const startMonths = Math.round(
      moment.duration(moment(chartStartDate).diff(startDate)).asMonths()
    );
    setMin(startMonths * fixHours);
    setMax(months * fixHours);
  };

  return (
    <div className="runtime__schedule">
      {!isTabView && <h1>{Labels.StepTitle}</h1>}
      <div style={{ width: "120px" }} className="runtime__schedule--row">
        <div style={{ width: "80px" }} className="runtime__schedule--vtext">
          <label htmlFor="">Hawk Run Time Schedule</label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <ACRunTimeSchedule min={min} max={max} />
        </div>
      </div>
      <div style={{ width: "120px" }} className="runtime__schedule--row">
        <div style={{ width: "80px" }} className="runtime__schedule--vtext">
          <label htmlFor="">Hawk 127 Maintenance Activity (Other)</label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <RunTimeOther min={min} max={max} />
        </div>
      </div>
      <div className="runtime__schedule--row">
        <div style={{ flex: "100px" }} className="runtime__schedule--vtext">
          <label htmlFor="">Hawk 127 Maintenance Schedule</label>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MaintenanceSchedule
              min={min}
              max={max}
              ticks={28}
              data={elementsList}
            />
          </div>
        </div>
      </div>
      <div>
        <div style={{ marginLeft: "280px", marginRight: "20px" }}>
          <RangeRibbon
            maxYear={4}
            update={updateHandler}
            range={[2020, 2025]}
          />
        </div>
      </div>
    </div>
  );
};
export default RunTimeSchedule;
