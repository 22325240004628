import { combineReducers } from "redux";
import AppSlice, { App } from "./appSlice";
import { navigationSlice, treeSlice } from "../features/navigation/redux";
import type { TreeList } from "../features/navigation/redux";
import { fleetScheduleSlice } from "../features/navigation/redux";
import type { Navigation, FleetSchedule } from "../features/navigation/redux";
import facilityReducer, {
  FacilitySliceType,
} from "../features/fleetBuilder/reducer/facilitySlice";
import {
  maintenanceSlice,
  MaintenanceSliceInitialType,
} from "../features/fleetMaintenance";
import {
  scenarioModelSlice,
  ScenarioModel,
} from "../features/InitialBuildRegistration/redux/scenarioModelSlice";
import {
  CLEAR_RECORD,
  CLEAR_SCHEDULER_DETAILS,
  LOGOUT,
} from "../features/navigation/redux/actionTypes";
import authSlice, { AuthUser } from "../views/home/redux/authSlice";
import {
  tabNavigationSlice,
  NavigateTabs,
} from "../features/navigation/redux/";
import { removeMultipleItemsFromStorage } from "../utils/localStorage";
import { STEP_ARRAY } from "../utils/constant";
import MissionPlanningSlice, {
  MissionPlanningSliceType,
} from "../features/fleetPlanning2/redux/MissionPlanning";

export interface RootSliceType {
  appReducer: App;
  naviReducer: Navigation;
  facilityReducer: FacilitySliceType;
  maintenance: MaintenanceSliceInitialType;
  tree: TreeList;
  fleetScheduleReducer: FleetSchedule;
  modelReducer: ScenarioModel;
  tabNavigation: NavigateTabs;
  MissionPlanningSlice: MissionPlanningSliceType;
  auth: AuthUser;
}

const appReducer = combineReducers<RootSliceType>({
  facilityReducer,
  appReducer: AppSlice.reducer,
  naviReducer: navigationSlice.reducer,
  maintenance: maintenanceSlice.reducer,
  tree: treeSlice.reducer,
  fleetScheduleReducer: fleetScheduleSlice.reducer,
  modelReducer: scenarioModelSlice.reducer,
  tabNavigation: tabNavigationSlice.reducer,
  MissionPlanningSlice: MissionPlanningSlice.reducer,
  auth: authSlice.reducer,
});

export const rootReducer = (state: any, action: any) => {
  switch (action.type) {
    case CLEAR_RECORD:
      state.naviReducer = undefined; //To test
      state.fleetScheduleReducer = undefined;
      state.modelReducer = undefined;
      removeMultipleItemsFromStorage(STEP_ARRAY);
      break;
    case CLEAR_SCHEDULER_DETAILS:
      state.fleetScheduleReducer = undefined;
      break;
    case LOGOUT:
      state.auth = undefined;
      break;
    default:
      break;
  }
  return appReducer(state, action);
};
