export const pageReportRoutes: Array<{
  key: number;
  name: string;
  navigateTo: string;
  color: string;
  description: string;
}> = [
  {
    key: 0,
    name: "CM",
    navigateTo: "/reporting-management/cm",
    color: "#e3472c",
    description: "Capability Management Report",
  },
  {
    key: 1,
    name: "OM",
    navigateTo: "/reporting-management/om",
    color: "#3890a7",
    description: "Operational Management Report",
  },
  // {
  //   key: 2,
  //   name: "MP",
  //   navigateTo: "/reporting-management/mp",
  //   color: "#fad538",
  //   description: "Mission Planning Report",
  // },
  {
    key: 2,
    name: "MM",
    navigateTo: "/reporting-management/mm",
    color: "#a9d18e",
    description: "Maintenance Management Report",
  },
  {
    key: 3,
    name: "MD",
    navigateTo: "/reporting-management/md",
    color: "#ff6b6b",
    description: "Modelling Report",
  },
  {
    key: 4,
    name: "IA",
    navigateTo: "/reporting-management/ia",
    color: "#6b9cff",
    description: "Tail Report",
  },
];
