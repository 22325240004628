export const RoutePaths = {
  root: "/",
  functions: "/functions",
  fleetBuild: "/fleetbuild/:id?",
  operationManagement: "/operationmanagement/:id?",
  fleetProfile: "/profilemanagement/:id?",
  reports: "/reports/:section?/:name?/:reportId?",
  reportsUUC: "/reports-uuc",
  fleetConfiguration: "/configurationmanagemnt",
  fleetOperatingLimits: "/operatinglimits",
  fleetPlanning: "/fleetplanning",
  fleetPlanning2: "/fleetplanning2",
  strips: "/strips",
  allViewStrips: "/strips/all",
  fleetBuildTargetRoe: "/target-roe/:id?",
  eventRoe: "/eventroe/:id?",
  acceptanceConfiguration: "/acceptance-configuration/:id?",
  roeSummary: "/roe-summary/:id?",
  fleetOperationalStatus: "/fleetoperationalstatus",
  individualAsset: "/individualasset",
  individualTails: "/individualtails/:flight/:configid",
  initialBuildRegistration: "/initialbuildregistration/:id?",
  integratedOperations: "/integratedOperations/:configid",
  maintenance: "/maintenance",
  scheduleOfConstraints: "/scheduleOfConstraints",
  fleetSchedule: "/fleetSchedule/:id?",
  iliasMaintenanceSchedule: "/iliasmaintenanceschedule/:id?",
  cmcTree: "/cmctree",
  admin: "/adminal",
  fleetConfigurationSummery: "/fleetConfigurationSummery",
  routingService: "/routing-service",
  acceptanceSchedule: "/acceptance-schedule",
  ProfileManagement: "/profile-manager",
  capabilityManagement: "/capability-management/:id?",
  operationsManagement: "/operation-management/:id?",
  reportingManagement: "/reporting-management/:id?",
  reportingManagementCM: "/reporting-management/cm/:id?",
  reportingManagementCMDate: "/reporting-management/cm/:id?",
  reportingManagementMM: "/reporting-management/mm/:id?",
  reportingManagementIA: "/reporting-management/ia/:id?",
  reportingManagementMD: "/reporting-management/md/:id?",

  reportingManagementOM: "/reporting-management/om/:flight/:id?",
  missionPlanning:"/mission-planning/:id?",
  maintenanceManagement:"/maintenance-management/:id?",
  squadronManagement: "/squadron-management",
  
  pageNotFound: "*",
};

export default RoutePaths;
