export const addToSessionStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, value);
};

export const getFromSessionStorage = (key: string) => {
  return sessionStorage.getItem(key) || null;
};

export const removeSessionFromStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const removeMultipleItemsFromSessionStorage = (
  keysToRemove: Array<string>
) => {
  keysToRemove.forEach((k) => sessionStorage.removeItem(k));
};
