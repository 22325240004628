import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Steps } from "@austaltech/components";
import FluidContainer from "../../../layouts/fleetbuild/FluidContianer";
import TitleBar from "../../../layouts/fleetbuild/TitleBar";
import { BodyContainer, LeftSidePanel, MainContainer } from "../../../layouts";
import { TopNavigation } from "../../../components";
import { stepsSource } from "../models/stepperData";
import {
  STEPPER_MAIN_NODES,
  STEP_ARRAY,
  STEP_FIVE,
  STEP_FOUR,
  STEP_ONE,
  STEP_SIX,
  STEP_THREE,
  STEP_TWO,
} from "../../../utils/constant";
import {
  maintenanceStepperItems,
  stepperItems,
} from "../../../components/topNavigation/TopNavSteps";
import { NodeItem } from "../../../components/topNavigation/NodeItem";
import {
  addToStorage,
  getFromStorage,
  removeMultipleItemsFromStorage,
} from "../../../utils/localStorage";
import RoutePaths from "../../../routePaths";
import ScheduleActivity from "../forms/scheduleActivity/ScheduledActivities";
import RunTimeSchedule from "../forms/runTimeSchedule/RunTimeSchedule";
import RunTimeFullSchedule from "../forms/runtimeFullSchedule/RunTimeFullSchedule";
import MainElement from "../forms/MaintenanceElements/MaintenanceElements";
import MaintenanceActivity from "../forms/MaintenanceActivity/MaintenanceActivity";
import DoubleAircraftImage from "../../../components/icons/double-aircraft/DoubleAircraftImage";
import { ConfigurationId } from "../../../utils/helper";
import { ActivityType, MaintenanceElement } from "../models/maintenanceModel";
import { MaintenanceElementService } from "../services/MaintenanceElementService";
import "../forms/MaintenanceElements/maintenance.scss";
import "../../../layouts/fleetbuild/fleetbuildlayout.scss";

const initialStep = 29;

const FleetMaintenanceContainer: React.FC = () => {
  const { push } = useHistory();
  const configId = ConfigurationId() as number;
  const step = getFromStorage(STEP_SIX);

  const [current, setCurrent] = useState<number>(
    step ? parseInt(step) - 1 : initialStep
  );
  const [elementsList, setElements] = useState<Array<MaintenanceElement>>([]);
  const [activityTypes, setActivityTypes] = useState<Array<ActivityType> | []>(
    []
  );
  useEffect(() => {
    const load = async () => {
      const activityType = await MaintenanceElementService.GetAllActivityTypes();
      const maintenanceElements = await MaintenanceElementService.GetAllMaintenanceElements();
      if (maintenanceElements) setElements([...maintenanceElements]);
      if (activityType) setActivityTypes([...activityType]);
    };
    load();
  }, []);
  const nextHandler = () => {
    const next = current + 1;
    if (next > current) {
      if (next === 33) return push(`/eventroe/${configId}`);
      setCurrent(next);
    }
  };

  const previousHandler = () => {
    const previous = current - 1;
    if (previous >= 0) {
      if (previous === 28) {
        addToStorage(STEP_FIVE, 28);
        return push(RoutePaths.fleetOperatingLimits);
      }
      setCurrent(previous);
    }
  };

  const forms = [
    <MainElement
      key="oanalysis"
      aircraftType="HawK 127"
      elementsList={elementsList}
      activityTypes={activityTypes}
      setElements={setElements}
    />,
    <ScheduleActivity
      key="oanschedule"
      aircraftType="HawK 127"
      elementsList={elementsList}
      activityTypes={activityTypes}
    />,
    <RunTimeSchedule key="runschedule" elementsList={elementsList} />,
    <RunTimeFullSchedule key="runfull" />,
    <MaintenanceActivity key="oanalysis" />,
  ];

  const [nodes, setNodes] = useState<Array<NodeItem>>(stepperItems);
  const stepChangeHandler = (step: number) => {
    setCurrent(step < initialStep ? step + initialStep : step);
    nodes.map((item) => {
      if (item.index === step) {
        if (STEPPER_MAIN_NODES.indexOf(step) !== -1) {
          item.highlighted = 0;
        }
      }
      return item;
    });

    if (step === 5) {
      removeMultipleItemsFromStorage(STEP_ARRAY);
      push(`/eventroe/${configId}`);
      return;
    }
    if (step === 6) {
      removeMultipleItemsFromStorage(STEP_ARRAY);
      push(`/acceptance-configuration/${configId}`);
      return;
    }
    if (step === 7) {
      removeMultipleItemsFromStorage(STEP_ARRAY);
      push(`/roe-summary/${configId}`);
      return;
    }
    setNodes([...nodes]);
  };

  const topNavStepperHandler = (step: number) => {
    if (step > initialStep) {
      if (STEPPER_MAIN_NODES.indexOf(step) !== -1) {
        stepChangeHandler(step + 0.5);
        addToStorage(STEP_SIX, (step + 0.5).toString());
      } else {
        stepChangeHandler(step === 1 ? 0 : step - 1);
        addToStorage(STEP_SIX, step.toString());
      }
    }
    if (step >= 26 && step <= 28) {
      addToStorage(STEP_FIVE, step.toString());
      push(RoutePaths.fleetOperatingLimits);
      return;
    }
    if (step >= 22 && step <= 24) {
      addToStorage(STEP_FOUR, step.toString());
      push(RoutePaths.fleetConfiguration);
      return;
    }
    if (step >= 19 && step <= 20) {
      addToStorage(STEP_THREE, step.toString());
      push(`/profilemanagement/${configId}`);
      return;
    }
    if (step >= 11 && step <= 17) {
      addToStorage(STEP_TWO, step.toString());
      push(`/operationmanagement/${configId}`);
      return;
    }
    if (step >= 1 && step <= 9) {
      addToStorage(STEP_ONE, step.toString());
      push(`/fleetbuild/${configId}`);
      return;
    }
  };

  return (
    <>
      {/* <ActiveProgress show={app.activeProgress.value} />
      <Toast /> */}
      <FluidContainer>
        <TitleBar>
          <TopNavigation
            dataSource={[...stepperItems, ...maintenanceStepperItems]}
            currentStep={current + 1}
            onChange={topNavStepperHandler}
            isMaintenance={true}
          />
        </TitleBar>
        <BodyContainer>
          <LeftSidePanel>
            <div
              style={{ height: "100%", position: "relative" }}
              className="side-stepper"
            >
              {[...Array(3)].map((_, index) => (
                <div
                  style={{ cursor: "pointer" }}
                  className={`image-location ${"image-" + String(index + 1)}`}
                  key={String(index)}
                >
                  <DoubleAircraftImage />
                </div>
              ))}
              <Steps
                onChange={stepChangeHandler}
                current={
                  current >= initialStep ? current - initialStep : current
                }
                steps={stepsSource}
              />
            </div>
          </LeftSidePanel>

          <MainContainer>
            <div>
              {" "}
              {forms[current - initialStep]}
              <div className="btm-btn-wrapper d-flex justify-content-end">
                <button
                  onClick={previousHandler}
                  className="btn-next radius-50"
                >
                  Previous
                </button>
                <button onClick={nextHandler} className="btn-next radius-50">
                  Next
                </button>
              </div>
            </div>
          </MainContainer>
        </BodyContainer>
      </FluidContainer>
    </>
  );
};

export default FleetMaintenanceContainer;
