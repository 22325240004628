import React, { useEffect } from "react";
import * as d3 from "d3";
import moment from "moment";
import { ReportLabels, UUCCategoryColor } from "../../../models";
import "./uuc.scss";
interface UUC {
  year: Date;
  rank: number;
  color: string;
  roe: number;
  status: string;
}
interface UUCEvent {
  start: Date;
  end: Date;
  name: string;
  color: string;
  id: number;
}
interface Props {
  startDate: Date;
  endDate: Date;
}
const drawLine = (
  svg: any,
  path: string,
  color: string,
  div: any,
  roe: number
) => {
  svg
    .append("path")
    .attr("d", path)
    .attr("fill", "none")
    .attr("stroke", color)
    .on("mouseover", function (event: any) {
      div.style("opacity", 0.9);
      div
        .html(`ROE ${roe}`)
        .style("left", event.pageX + "px")
        .style("top", event.pageY - 28 + "px");
    })
    .on("mouseout", function () {
      div.style("opacity", 0);
    });
};
const UUCGraph: React.FC<Props> = ({ startDate, endDate }) => {
  const dimensios = {
    height: 450,
    width: 0,
    boundedHeight: 0,
    boundedWidth: 0,
    margin: {
      top: 30,
      left: 50,
      bottom: 50,
      right: 10, //0716200738 hashan id s
    },
  };
  const ribbonRecHeight = 20;
  const itemHieght = 30;
  const itemCount = 7;
  const draw = () => {
    const width = document.getElementById("chartWrapper")?.clientWidth || 600;
    dimensios.width = width;
    dimensios.boundedHeight =
      dimensios.height - dimensios.margin.top - dimensios.margin.bottom;
    dimensios.boundedWidth =
      dimensios.width - dimensios.margin.left - dimensios.margin.right;
    const monthsDiff = Math.round(
      moment.duration(moment(endDate).diff(startDate)).asMonths()
    );
    d3.selectAll("#chartWrapper > *").remove();
    const svg = d3
      .selectAll("#chartWrapper")
      .append("svg")
      .attr("width", dimensios.width)
      .attr("height", dimensios.height);
    const xScale = d3
      .scaleTime()
      .domain([startDate, endDate])
      .range([0, dimensios.width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, 17])
      .range([dimensios.height - 20, 0]);

    let xAxis = d3.axisBottom(xScale).tickFormat(function (date: any) {
      if (monthsDiff === 1) {
        return d3.timeFormat("%d")(date);
      }
      if (d3.timeYear(date) < date) {
        return d3.timeFormat("%b")(date);
      } else {
        return d3.timeFormat("%Y")(date);
      }
    });
    if (
      monthsDiff === 2 ||
      monthsDiff === 3 ||
      monthsDiff === 4 ||
      monthsDiff === 5
    ) {
      xAxis = d3
        .axisBottom(xScale)
        .ticks(
          monthsDiff === 2
            ? 8
            : monthsDiff === 3
            ? 12
            : monthsDiff === 4
            ? 16
            : 20
        );
    }
    const todayline: Array<[number, number]> = [
      [xScale(Date.now()), 0],
      [xScale(Date.now()), dimensios.height],
    ];
    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", yScale(4))
      .attr("height", dimensios.height - 45)
      .style("fill", function (d) {
        return "#FDEFE6";
      })
      .attr("width", dimensios.width);

    svg
      .append("rect")
      .attr("x", 0)
      .attr("y", yScale(17))
      .attr("height", yScale(4))
      .style("fill", function (d) {
        return "#FFF7E0";
      })
      .attr("width", dimensios.width);

    svg
      .append("g")
      .attr("class", "x-axis")
      .call(xAxis)
      .style("transform", `translateY(${dimensios.height - 20}px)`);
    const vgridline = d3.axisLeft(yScale).ticks(17);

    svg
      .append("g")
      .attr("class", "m-v")
      .call(vgridline.tickSize(-width).tickFormat(null));

    const hgridLine = d3.axisBottom(xScale).tickPadding(10);

    const hsubgridLine = d3.axisBottom(xScale).ticks(11 * 3);

    const chartdivRect = (
      d3.select("#chartWrapperSummary2").node() as HTMLDivElement
    ).getBoundingClientRect();
    svg
      .append("g")
      .attr("class", "m-h")
      .call(hgridLine.tickSize(dimensios.height).tickFormat(null));

    svg
      .append("g")
      .attr("class", "s-h")
      .call(
        hsubgridLine.tickSize(dimensios.height - 20).tickFormat(function () {
          return "";
        })
      );
    const svg2 = d3
      .selectAll("#chartWrapperSummary2")
      .append("svg")
      .attr("width", dimensios.width)
      .attr("height", 50);
    const div = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    d3.json<Array<UUC>>("./data/uuc.json").then((data) => {
      if (data) {
        let previous = 0;
        let previousX = 0;
        const y = 0;
        svg2
          .selectAll("rect")
          .data(data)
          .enter()
          .append("rect")
          .attr("x", function (d) {
            const nx = xScale(new Date(d.year));
            const px = previousX;
            previousX = nx;
            return px;
          })
          .attr("y", y)
          .attr("width", function (d) {
            const nx = xScale(new Date(d.year));
            const px = nx - previous;
            previous = nx;
            return px < 0 ? 0 : px;
          })
          .on("mouseover", function (event: any, item: UUC) {
            div.style("opacity", 0.9);

            div
              .html(item.status)
              .style("left", event.pageX + "px")
              .style("top", chartdivRect.y + y - 27 + "px");
          })
          .on("mouseout", function (event: any) {
            div.style("opacity", 0);
          })
          .style("fill", function (d) {
            return d.color;
          })
          .attr("class", "uu-line-ribbonbar")
          .attr("height", ribbonRecHeight);
        previousX = 0;
        const textSvg = svg2.append("g").attr("class", "bar-group");
        textSvg
          .selectAll("text")
          .data(data)
          .enter()
          .append("text")
          .attr("x", function (d) {
            const nx = xScale(new Date(d.year));
            const px = previousX;
            previousX = nx;
            if (nx < 70) {
              return px;
            } else if (nx < 100) {
              return px + 5;
            } else if (nx < 140) {
              return px + 15;
            } else {
              return px + 20;
            }
          })
          .text(function (d, i) {
            const nx = xScale(new Date(d.year));
            const px = previousX;
            previousX = nx;
            if (nx - px > 50 || i == 0) {
              return d.status;
            } else {
              return "";
            }
          })

          // .attr("text-xaxis", "middle")
          .attr("class", "text-fore-color")
          .attr("y", y + 13)
          .attr("fill", "black");
        previousX = 0;
      }
    });
    const lineGenerator = d3.line();

    svg
      .append("path")
      .attr("d", lineGenerator(todayline) as string)
      .attr("stroke-dasharray", "6,5")
      .attr("stroke-width", ".6")
      .attr("fill", "none")
      .attr("stroke", "#949494");

    svg
      .append("text")
      .attr("x", -10 + xScale(Date.now()))
      .attr("class", "today")
      .attr("y", dimensios.height / 2)
      .attr("transform", function (d) {
        return `rotate(-90 ${xScale(Date.now())} ${dimensios.height / 2})`;
      })
      .text(ReportLabels.Today);

    svg.select(".m-h .domain").remove();
    svg.select(".s-h .domain").remove();

    d3.json<Array<UUCEvent>>("./data/uucEvents.json").then((data) => {
      if (data) {
        svg
          .selectAll(".text")
          .data(data)
          .enter()
          .append("text")
          .attr("x", function (d) {
            return xScale(new Date(d.start)) - d.name.length * 5;
          })
          .attr("class", "eventText")
          .attr("y", function (d) {
            return (
              yScale(8) +
              (xScale(new Date(d.end)) - xScale(new Date(d.start))) / 2
            );
          })
          .attr("transform", function (d) {
            return `rotate(-90 ${xScale(new Date(d.start))} ${yScale(8)})`;
          })
          .text(function (d) {
            return d.name;
          });
      }
    });

    d3.json<Array<UUC>>("./data/uuc.json").then((data) => {
      if (data) {
        const lineGenerator = d3.line();
        let lineList: Array<[number, number]> = [
          [xScale(0), yScale(data[0].rank)],
        ];
        let previous = lineList[0];
        for (let i = 0; i < data.length; i++) {
          lineList.push([previous[0], yScale(data[i].rank)]);
          const node: [number, number] = [
            xScale(new Date(data[i].year)),
            yScale(data[i].rank),
          ];
          lineList.push(node);
          previous = node;
          drawLine(
            svg,
            lineGenerator(lineList) as string,
            UUCCategoryColor.OPFMC,
            div,
            data[i].roe
          );
          lineList = [];
          lineList.push(node);
        }
        let previousX = 0;
        const y = 20;
        const textSvg = svg.append("g").attr("class", "bar-group-2");
        const xAxisLabel = svg.append("g").attr("class", "bar-group-3");
        const dateArray=[]

        for(let x=0;x<51;x++){
          dateArray.push(x*27)
        }

        textSvg
          .selectAll("rect")
          .data(dateArray)
          .enter()
          .append("rect")
          .attr("x", function (d) {
            
            return 18+d;
          })
          .attr("y", 4)
          .attr("height", 15)
          .style("fill",function (d, i) {   
            if(i<40)       
           return "#e2f0d9";
           else
           return "#f4b083";
          })
          .attr("width", 20);
        previousX = 0;

        textSvg
          .selectAll(".text")
          .data(dateArray)
          .enter()
          .append("text")
          .attr("x", function (d) {
            return 20+d;
            
          })
          .attr("y", 15)
          .attr("class", function (d, i) {
           if(i<40)
           return "label-font3"
           else{
            return "label-font2"
           }
            
          })
          .text(function (d, i) {   
            if(i<40)       
           return "1.3";
           else
           return "1.2";
          });
          const xAxisData=["Jan-22","Feb-22","Mar-22","Apr-22","May-22","Jun-22","Jul-22","Aug-22","Sep-22","Oct-22","Nov-22","Dec-22"]
          xAxisLabel
          .selectAll(".text")
          .data(xAxisData)
          .enter()
          .append("text")
          .attr("x", function (d,i) {
            return 30+(i*117);
            
          })
          .attr("y", 445)
          .attr("fill","#f22c00")
          .text(function (d, i) {   
          return d
          });
      }
    });
  };
  useEffect(draw, [startDate, endDate]);
  return (
    <>
      <div id="chartWrapper"></div>
      <div id="chartWrapperSummary2"></div>
    </>
  );
};
export default UUCGraph;
