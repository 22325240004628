import { ServerResponse } from "../../../utils";
import { API } from "../../../api";
import {
  MAINTENANCE_ACTIVITY_TYPES,
  MAINTENANCE_ELEMENT,
  MAINTENANCE_LOCATION,
  MAINTENANCE_ROUTINE_ELEMENTS,
} from "../../../api/endpoint";

import {
  CreateMaintenanceElementServerType,
  MaintenanceActivityTypeServerType,
  MaintenanceElementServerType,
  MaintenanceUnits,
  UpdateMaintenanceElementServerType,
} from "../../../models/serverTypes";
export interface CreateResponse {
  isSuccess: boolean;
  statusCode: number;
  maintenanceRoutineElement: number;
}
const GetAllActivityTypes = async (): Promise<
  Array<MaintenanceActivityTypeServerType> | []
> => {
  try {
    const response = await API.get<
      ServerResponse<Array<MaintenanceActivityTypeServerType>>
    >(MAINTENANCE_ACTIVITY_TYPES);
    if (response.data.error !== "") {
      return [];
    }
    return response.data.result;
  } catch (e) {
    return [];
  }
};
const GetAllMaintenanceElements = async (): Promise<
  Array<MaintenanceElementServerType> | []
> => {
  try {
    const response = await API.get<
      ServerResponse<Array<MaintenanceElementServerType>>
    >(MAINTENANCE_ELEMENT);
    if (response.data.error !== "") {
      return [];
    }
    return response.data.result;
  } catch (e) {
    return [];
  }
};

const GetMaintenanceElements = async (
  configId: string | number
): Promise<Array<MaintenanceElementServerType> | []> => {
  try {
    const response = await API.get<
      ServerResponse<Array<MaintenanceElementServerType>>
    >(`${MAINTENANCE_ROUTINE_ELEMENTS}/${configId}`);
    if (response.data.error !== "") {
      return [];
    }
    return response.data.result && response.data.result.length > 0
      ? response.data.result
      : [];
  } catch (e) {
    return [];
  }
};
const CreateMaintenanceElement = async (
  payload: CreateMaintenanceElementServerType
): Promise<number> => {
  try {
    let response = null;
    response = await API.post<ServerResponse<CreateResponse>>(
      MAINTENANCE_ELEMENT,
      payload
    );

    if (
      response &&
      response.data &&
      response.data.result &&
      response.data.result.isSuccess == false &&
      response.data.result.statusCode === 409
    ) {
      return response.data.result.statusCode;
    }
    if (
      response &&
      response.data &&
      response.data.result &&
      response.data.result.isSuccess
    )
      return response.data.result.maintenanceRoutineElement;
    return 0;
  } catch (e) {
    return 0;
  }
};
const UpdateMaintenanceElement = async (
  payload: UpdateMaintenanceElementServerType
): Promise<boolean> => {
  try {
    let response = null;
    response = await API.put<ServerResponse<CreateResponse>>(
      MAINTENANCE_ELEMENT,
      payload
    );
    if (response && response.data && response.data.result)
      return response.data.result.isSuccess;
    return false;
  } catch (e) {
    return false;
  }
};

const DeleteMaintenanceElement = async (
  elementId: number
): Promise<boolean> => {
  try {
    let response = null;
    if (elementId > 0) {
      response = await API.delete<ServerResponse<CreateResponse>>(
        MAINTENANCE_ELEMENT,
        {
          data: { id: elementId },
        }
      );
      if (response && response.data && response.data.result)
        return response.data.result.isSuccess;
    }
    return false;
  } catch (e) {
    return false;
  }
};

const GetMaintenanceUnits = async (
  configId: number
): Promise<Array<MaintenanceUnits> | []> => {
  try {
    const response = await API.get<ServerResponse<Array<MaintenanceUnits>>>(
      `${MAINTENANCE_LOCATION}/${configId}`
    );
    if (response.data.error !== "") {
      return [];
    }
    return response.data.result;
  } catch (e) {
    return [];
  }
};
const MaintenanceElementService = {
  GetAllMaintenanceElements: GetAllMaintenanceElements,
  Create: CreateMaintenanceElement,
  Update: UpdateMaintenanceElement,
  Delete: DeleteMaintenanceElement,
  GetAllActivityTypes: GetAllActivityTypes,
  GetMaintenanceUnits: GetMaintenanceUnits,
  GetMaintenanceElements: GetMaintenanceElements,
};
export { MaintenanceElementService };
