import React from "react";
import austal from "../../../assets/images/austal.png";
import { UserInfo } from "../../UserInfo";
import { Link, useHistory } from "react-router-dom";
import "./header.scss";
import { useAuth } from "../../../context/authContext";

export const Header: React.FC = () => {
  const { location } = useHistory();
  const { user } = useAuth();
  const isFleetSchedule = location.pathname.includes(
    "fleetSchedule"
  ) as boolean;
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <Link className="navbar-brand" to="/">
        <img src={austal} alt="Austal logo" className="company-logo" />
      </Link>
      <ul className="navbar-nav mr-auto">
        {isFleetSchedule && (
          <div className="header-title outter-ly-ot" style={{ width: 350 }}>
            <h6 className="title" style={{ fontSize: 30 }}>
              Maintenance Schedule
            </h6>
            <div className="underline"></div>
          </div>
        )}
      </ul>
      <ul className="navbar-nav ml-auto d-flex justify-content-center align-items-center">
        <li className="nav-item">
          <Link to="/" className="nav-link link-gray">
            Support
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/" className="nav-link link-gray">
            Help
          </Link>
        </li>
        {user && (
          <li className="nav-item">
            <Link to="/" className="nav-link link-gray">
              {user.name}
            </Link>
          </li>
        )}
        <li className="nav-item">
          <UserInfo />
        </li>
      </ul>
    </nav>
  );
};
