import React from "react";
import FluidContainer from "../../../layouts/fleetbuild/FluidContianer";
import { Toast } from "../../../components";
import CMCTree from "../forms/CMCTree/CMCTree";
import { BodyContainer, MainContainer } from "../../../layouts";
import "../forms/MaintenanceElements/maintenance.scss";
import "../../../layouts/fleetbuild/fleetbuildlayout.scss";
import "antd/dist/antd.css";

const CMCContainer: React.FC = () => {
  return (
    <>
      <Toast />
      <FluidContainer>
        <BodyContainer>
          <MainContainer>
            <CMCTree />
          </MainContainer>
        </BodyContainer>
      </FluidContainer>
    </>
  );
};

export default CMCContainer;
