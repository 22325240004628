import React from "react";
import "./loader.scss";

interface Props {
  show: boolean;
}
const Progress: React.FC<Props> = ({ show }) => {
  return (
    <>
      {show && (
        <div className="active__progress">
          <div className="active__progress--bar"></div>
        </div>
      )}
    </>
  );
};
export default Progress;
