import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ScenarioModelType {
  modelClassification: string;
  modelType: string | any;
  stringModelId: string;
  modelId: number;
  fleet: string;
  fleetId: number;
  createdBy: string;
  isPrecessed: boolean;
}

export interface Configuration {
  startDate: string;
  endDate: string;
  fleetSize: number;
}
const modelData: ScenarioModelType = {
  modelClassification: "",
  modelType: "",
  stringModelId: "",
  modelId: 0,
  fleet: "",
  fleetId: 0,
  createdBy: "",
  isPrecessed: false,
};

export interface ScenarioModel {
  selectConfigurationData: Configuration | null;
  selectModelData: ScenarioModelType;
  errors: Array<string>;
}
const initial: ScenarioModel = {
  selectModelData: modelData,
  selectConfigurationData: null,
  errors: Array<string>(),
};

export const scenarioModelSlice = createSlice({
  name: "scenarioModel",
  initialState: initial,
  reducers: {
    setModelData: (state, action: PayloadAction<ScenarioModelType>) => {
      state.selectModelData = action.payload;
    },
    setModelConfigurationData: (
      state,
      action: PayloadAction<Configuration | null>
    ) => {
      state.selectConfigurationData = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.errors.push(action.payload);
    },
  },
});
