import React from "react";
import { Form, Input, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import {
  MaintenanceElementDemoFieldNames,
  Labels,
  ActivityType,
} from "../models/maintenanceModel";

const { Option } = Select;
interface Props {
  form: FormInstance;
  activityTypeId: number | undefined;
  id: number;
  activityTypes: ActivityType[];
}
const ActivityForm: React.FC<Props> = ({
  form,
  activityTypeId,
  id,
  activityTypes,
}) => {
  return (
    <Form form={form} layout="vertical" initialValues={{ activityTypeId, id }}>
      <Form.Item
        rules={[{ required: true, message: "Activity Type is Required" }]}
        name={MaintenanceElementDemoFieldNames.activityName}
        label={Labels.ThActiveType}
      >
        <Select defaultValue={id}>
          {activityTypes.map((activity) => (
            <Option
              value={activity.id}
              key={activity.id}
            >
              {activity.activityType}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Id is Required" }]}
        name={MaintenanceElementDemoFieldNames.id}
        label={Labels.ThID}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};

export default ActivityForm;
