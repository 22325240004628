import { createAsyncThunk } from "@reduxjs/toolkit";
import { NavigationService } from "../../navigation/services";
import {
  FETCH_GROUP_REQUEST,
  FETCH_GROUP_FLEET_REQUEST,
  FETCH_WING_REQUEST,
} from "./thunkActions";

export const fetchAllGroups = createAsyncThunk(
  FETCH_GROUP_REQUEST,
  async () => {
    return await NavigationService.getFleetGroups();
  }
);
export const fetchWingByGroup = createAsyncThunk(
  FETCH_GROUP_FLEET_REQUEST,
  async (groupId: number) => {
    return await NavigationService.getWingsByGroupId(groupId);
  }
);

export const fetchFleetConfigByWin = createAsyncThunk(
  FETCH_WING_REQUEST,
  async (groupId: number) => {
    return await NavigationService.getFleetsByGroupId(groupId);
  }
);
