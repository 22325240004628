import { getFromStorage } from "../utils/localStorage";
import { SessionStorageField } from "../utils/constant";

const useRoleCheck = (userRole: any) => {
  const authUser: any = getFromStorage(SessionStorageField.user);
  const roles: Array<string> = (authUser && JSON.parse(authUser).roles) || [];
  const hasUserRole =
    roles &&
    roles.length > 0 &&
    roles.find((i: string) => {
      return i === userRole;
    });
  return {
    hasUserRole: !!hasUserRole,
  };
};

export default useRoleCheck;
