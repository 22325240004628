import React, { useState } from "react";
import {
  MaintenanceElement,
  MaintenanceElementsFieldNames,
} from "../models/maintenanceModel";
import {  Select, Button, Popconfirm, InputNumber } from "antd";
import { DeleteFilled, EditFilled, PlusOutlined } from "@ant-design/icons";

interface Props extends MaintenanceElement {
  selectedRowId: number;
  activityType: string;
  isLast: boolean;
  onAdd: () => void;
  onDelete: (id: number) => () => void;
  idx: number;
  onSelect: (id: number) => () => void;
  onChange: (idx: number, name: string, val: number) => void;
  setEditableRow: (idx: number) => void;
  setCancel: (idx: number) => void;
  onUpdate: (idx: number) => void;
}
export const ActivityRow: React.FC<Props> = ({
  maintenanceId,
  selectedRowId,
  colorCode,
  intervalHrs,
  intervalDays,
  workingDays,
  workingHrs,
  cost,
  activityType,
  isLast,
  onAdd,
  onDelete,
  idx,
  onSelect,
  onChange,
  setEditableRow,
  setCancel,
  onUpdate,
}) => {
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const manageEdit = (idx: number) => {
    setIsReadOnly(false);
    setEditableRow(idx);
  };
  const inputChange = (name: string) => (value: number) => {
    onChange(idx, name, value);
  };

  const inputHandler = (fieldName: string, value: number) => {
    return (
      <td style={{ textAlign: "center" }}>
        <InputNumber
          onChange={inputChange(fieldName)}  
          value={value}
          readOnly={isReadOnly}
          min={0}
          size={"small"}
        />
      </td>
    );
  };
  return (
    <>
      <tr>
        <td>{activityType}</td>
        <td>
          <div
            onClick={
              isReadOnly && selectedRowId === 0 ? onSelect(idx) : undefined
            }
            className="route__analysis--id"
            style={{ backgroundColor: colorCode }}
          >
            {maintenanceId}
          </div>
        </td>
        {inputHandler(MaintenanceElementsFieldNames.intervalHrs, intervalHrs)}
        {inputHandler(MaintenanceElementsFieldNames.intervalDays, intervalDays)}
        {inputHandler(MaintenanceElementsFieldNames.workingDays, workingDays)}
        {inputHandler(MaintenanceElementsFieldNames.workingHrs, workingHrs)}
        {inputHandler(MaintenanceElementsFieldNames.cost, cost)}
        {isReadOnly ? (
          <div>
            <Button
              shape="circle"
              icon={<EditFilled />}
              style={{ fontSize: "12px" }}
              onClick={() => {
                manageEdit(idx);
              }}
              className="edit-action-btn"
              disabled={selectedRowId > 0}
            />
            <Popconfirm title="Sure to delete?" onConfirm={onDelete(idx)}>
              <Button
                shape="circle"
                icon={<DeleteFilled />}
                style={{ fontSize: "6px", margin: "5px 5px" }}
                className="delete-action-btn"
              />
            </Popconfirm>

            {isLast && (
              <Button
                shape="circle"
                type="primary"
                icon={<PlusOutlined />}
                onClick={onAdd}
                style={{ fontSize: "3px", marginLeft: "5px" }}
                className="add-btn-cm"
              />
            )}
          </div>
        ) : (
          <div>
            <span>
              <a
                onClick={() => {
                  onUpdate(idx);
                  setIsReadOnly(true);
                }}
                style={{
                  marginRight: 8,
                  color: "green",
                }}
                className="save-record"
                //    aria-disabled={!isReadyToSave}
              >
                Save
              </a>
              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => {
                  setIsReadOnly(true);
                  setCancel(idx);
                }}
              >
                <a className="cancel-record">Cancel</a>
              </Popconfirm>
            </span>
          </div>
        )}
      </tr>
    </>
  );
};

export default ActivityRow;
