import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Credentials {
  email: string;
  name: string;
  roles: Array<string>;
}

export interface AuthUser {
  loginUser: Credentials | null;
}
const initial: AuthUser = {
  loginUser: null,
};

const authSlice = createSlice({
  name: "authUser",
  initialState: initial,
  reducers: {
    setAuthUser: (state, action: PayloadAction<Credentials | null>) => {
      state.loginUser = action.payload;
    },
  },
});

export default authSlice;
