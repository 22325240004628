import * as React from "react";
import "./icon.scss";

const Aircraft = (props: React.SVGProps<SVGSVGElement>) => {
  const { color, onClick } = props;
  return (
    <svg
      {...props}
      width={33}
      height={26}
      overflow="hidden"
      onClick={onClick}
      className="pointer"
    >
      <defs>
        <clipPath id="clip1">
          <rect x={594} y={303} width={33} height={26} />
        </clipPath>
      </defs>
      <g clipPath="url(#clip1)" transform="translate(-594 -303)">
        <path
          d="M625.5 316.398 624.92 315.936 620.755 315.064 613.427 314.962 613.216 314.192 612.794 313.782 611.265 314.192 610.58 313.526 606.889 305.269 607.575 305.167 607.575 305.167 607.522 304.603 603.673 304.5 604.781 314.808 601.723 314.962 600.036 315.064 599.403 314.757 598.507 314.705 595.765 310.962 595.238 310.91 594.5 311.064 595.713 315.936 595.238 316.141 595.291 316.91 595.66 317.218 594.5 322.346 595.133 322.295 595.66 322.346 598.665 318.244 599.878 318.141 600.299 317.936 603.779 318.09 604.781 318.192 603.673 328.398 606.889 328.5 607.68 328.346 607.575 327.731 606.784 327.782 610.58 319.474 611.371 318.91 612.742 319.167 613.374 318.962 613.638 318.039 620.175 318.039 622.758 317.577 624.815 317.013 625.5 316.398Z"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M598.5 314.5 598.5 314.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M625.403 316.5C625.533 316.368 625.663 316.236 624.859 316.08 624.055 315.925 622.473 315.661 620.58 315.568 618.687 315.474 616.093 315.498 613.5 315.521"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M613.5 315.5C613.456 314.661 613.412 313.823 613.079 313.58 612.746 313.337 612.123 313.691 611.5 314.044"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M611.5 314.5 610.5 313.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M610.5 313.5 607.5 305.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M606.858 305.5C607.125 305.484 607.391 305.468 607.44 305.31 607.489 305.151 607.639 304.659 607.149 304.548 606.659 304.437 605.579 304.54 604.5 304.643"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M604.5 304.5 605.5 314.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M605.03 314.5 604.5 314.576"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M604.502 314.5 600.5 314.638"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M600.5 315.5C600.244 315.12 599.989 314.74 599.656 314.588 599.322 314.436 598.911 314.512 598.5 314.588"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M598.5 314.503C597.584 313.112 596.667 311.721 596.143 311.069 595.62 310.416 595.601 310.675 595.358 310.589 595.115 310.502 594.815 310.459 594.685 310.552 594.554 310.644 594.41 310.318 594.572 311.143 594.734 311.967 595.196 313.734 595.657 315.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M595.803 315.5 595.5 315.879"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M595.5 316.5 595.5 316.879"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M604.5 315.5C605.365 315.396 606.23 315.292 607.397 315.125 608.563 314.958 610.032 314.729 611.5 314.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M604.5 314.536 604.683 314.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M0 0 3.77879 0.489239"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 604.989 308.279)"
        />
        <path
          d="M605.143 308.5 604.5 308.687"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M0 0 6.40976 0.734698"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(6.12323e-17 1 1 -6.12323e-17 604.5 308.5)"
        />
        <path
          d="M600.5 315.5C600.066 315.731 599.632 315.962 599.191 316.077 598.751 316.192 598.307 316.122 597.858 316.192 597.409 316.263 596.955 316.382 596.5 316.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M0 0 0.748451 0.179633"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(6.12323e-17 1 1 -6.12323e-17 598.5 314.5)"
        />
        <path
          d="M603.5 317.5C602.839 317.07 602.179 316.64 601.335 316.528 600.492 316.416 599.246 316.665 598.44 316.827 597.634 316.989 597.067 317.245 596.5 317.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M606.834 305.5 604.5 305.562"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M0 0 0.0284514 2.58257"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(6.12323e-17 1 1 -6.12323e-17 604.5 305.5)"
        />
        <path
          d="M608.954 309.5 607.5 309.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M609.178 309.5 607.5 309.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M0 0 0.589921 0.000104987"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 607.5 309.09)"
        />
        <path
          d="M610.986 317.349C610.653 316.922 610.32 316.495 610.327 316.066 610.335 315.637 610.683 315.206 611.031 314.776"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M619.519 316.095C619.532 315.771 619.545 315.447 619.137 315.264 618.728 315.082 618.454 315.068 617.07 315.001 615.686 314.934 613.258 314.899 610.831 314.863"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M621.5 315.5 620.588 317.125C620.435 317.625 620.512 318.063 620.588 318.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M623.5 315.5 623.5 317.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M627.063 316.5 623.5 316.541"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M0 0 0.000104987 0.000104987"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 598.5 318.5)"
        />
        <path
          d="M2 0.0966371C1.73577-0.0330343 1.47156-0.16269 1.16072 0.641235 0.849887 1.44516 0.321447 3.0271 0.134941 4.92022-0.0515645 6.81334-0.004938 9.40666 0.0416885 12"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 625.5 318.5)"
        />
        <path
          d="M1 0C0.580718 0.0438596 0.16145 0.0877193 0.0400855 0.421053-0.0812787 0.754386 0.0952486 1.37719 0.271789 2"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 613.5 319.5)"
        />
        <path
          d="M1 0 0 1"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 611.5 319.5)"
        />
        <path
          d="M8 0 0 3"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 610.5 327.5)"
        />
        <path
          d="M1 0.642237C0.984126 0.375404 0.96827 0.108583 0.809584 0.0600729 0.650899 0.0115631 0.159004-0.138837 0.0479225 0.351155-0.0631595 0.841146 0.039976 1.92057 0.14313 3"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 607.5 328.5)"
        />
        <path
          d="M0 1 10 0"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 605.5 328.5)"
        />
        <path
          d="M0 0 0.0760105 0.529554"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 605.03 318.576)"
        />
        <path
          d="M0 0 0.146352 4.00199"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 604.502 318.646)"
        />
        <path
          d="M1 0C0.619856 0.255556 0.239764 0.511111 0.0877373 0.844444-0.0642894 1.17778 0.0116983 1.58889 0.0877373 2"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 600.5 319.5)"
        />
        <path
          d="M4.00295 0C2.612 0.916491 1.22107 1.83298 0.568685 2.35669-0.0836978 2.8804 0.174796 2.89911 0.0886263 3.14226 0.002457 3.38541-0.0406204 3.68467 0.0516987 3.8156 0.144018 3.94652-0.182167 4.08993 0.642541 3.92782 1.46725 3.76572 3.23362 3.30435 5 2.84299"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 598.5 322.5)"
        />
        <path
          d="M0 0 0.379003 0.302572"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 595.803 316.879)"
        />
        <path
          d="M0 0 0.379003 0.000104987"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 595.5 316.879)"
        />
        <path
          d="M1 7C0.895833 6.13506 0.791667 5.27011 0.625 4.10345 0.458333 2.93678 0.229167 1.46839 0 0"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 611.5 319.5)"
        />
        <path
          d="M0 0 0.0364304 0.182572"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(6.12323e-17 1 1 -6.12323e-17 604.5 318.5)"
        />
        <path
          d="M604.989 324.5 604.5 328.241"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M0 0 0.187402 0.643045"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 605.143 324.687)"
        />
        <path
          d="M604.5 324.91 605.235 318.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M0 0C0.230769 0.434153 0.461538 0.868319 0.576923 1.30864 0.692308 1.74898 0.621805 2.19342 0.692308 2.64198 0.76281 3.09053 0.881405 3.54526 1 4"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 600.5 318.5)"
        />
        <path
          d="M598.5 318.249 598.68 317.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M1 0C0.570094 0.660635 0.140188 1.32129 0.0280612 2.16466-0.0841052 3.00803 0.16511 4.25435 0.327119 5.06024 0.489089 5.86613 0.744545 6.43306 1 7"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 603.5 317.5)"
        />
        <path
          d="M0 0 0.000104987 2.49386"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 606.994 327.5)"
        />
        <path
          d="M0 0 0.000104987 2.70016"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(6.12323e-17 1 1 -6.12323e-17 604.5 327.5)"
        />
        <path
          d="M608.977 324.5 607.5 324.5"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M0 0 0.0136483 1.71055"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 609.211 323.514)"
        />
        <path
          d="M607.5 323.5 607.5 323.988"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
        />
        <path
          d="M1.08535 0.00183158C0.760877-0.00525649 0.436401-0.0123446 0.261145 0.398972 0.0858896 0.810288 0.076401 1.08452 0.033777 2.46977-0.00884707 3.85503-0.00174554 6.28278 0.00535598 8.71055"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-0.0174524 -0.999848 -0.999848 0.0174524 619.54 317.127)"
        />
        <path
          d="M2 1C1.6592 0.516394 1.3184 0.0327885 0.985075 0.00160503 0.651749-0.0295784 0.325867 0.391595 0 0.812801"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 618.5 317.5)"
        />
        <path
          d="M0 0 2 0"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-6.12323e-17 -1 -1 6.12323e-17 623.5 317.5)"
        />
        <path
          d="M2 1C1.6592 0.516394 1.3184 0.0327885 0.985075 0.00160503 0.651749-0.0295784 0.325867 0.391595 0 0.812801"
          stroke="#BFBFBF"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill={color || "#EA700D"}
          fillRule="evenodd"
          transform="matrix(1.83697e-16 1 1 -1.83697e-16 613.5 315.5)"
        />
      </g>
    </svg>
  );
};

export default Aircraft;
