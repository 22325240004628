import React from "react";
import { useSelector } from "react-redux";
import { RootSliceType, App } from "../../redux";

const Toast = () => {
  const app = useSelector<RootSliceType, App>((state) => state.appReducer);
  return (
    <div className="toastAc">
      {app.activeProgress.error === "success" && (
        <div className="toastAc__success toastAc__showAni">success</div>
      )}
      {app.activeProgress.error === "error" && (
        <div className="toastAc__error">Some thing went wrong</div>
      )}
    </div>
  );
};

export default Toast;
