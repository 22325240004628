import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Stepper, Step } from "@progress/kendo-react-layout";
import { Tooltip } from "antd";
import classNames from "classnames";
import FleetBottomImageIcon from "../shared/icons/FleetBottomIcon";
import OperationManagementIcon from "../shared/icons/OperationManagementIcon";
import FleetAnalyzeNextImage from "../shared/icons/FleetAnalyzeNextImage";
import LeftLineArrow from "../../components/shared/icons/LeftLineArrow";
import FleetMaintenance from "../../components/shared/icons/FleetMaintenance";
import FleetCapability from "../../components/shared/icons/FleetCapability";
import { STEPPER_MAIN_NODES, STEP_ARRAY } from "../../utils/constant";
import { subStepsLabels, mainStepsLabels } from "./NavData";
import AircraftImage from "../icons/aircraft/AircraftImage";
import { removeMultipleItemsFromStorage } from "../../utils/localStorage";
import RoutePaths from "../../routePaths";
import { navigationSlice } from "../../features/navigation/redux";
import { ConfigurationId } from "../../utils/helper";
import "./top-navigation.scss";
import { PageRouting } from "../header/innerHeader/PageRouting";

interface Props {
  dataSource: Array<NodeItem>;
  onChange?: (step: number) => void;
  currentStep?: any;
  isMaintenance?: boolean;
}

export interface NodeItem {
  step: number;
  type: string;
  label: string;
  activecolor: string;
  className: string;
  highlighted: number;
  index?: number;
}

const TopNavigation: React.FC<Props> = ({
  dataSource,
  onChange,
  currentStep,
}) => {
  const [value, setValue] = useState(0);
  const [itemsValue, setItemsValue] = useState(dataSource);
  const { push } = useHistory();
  const dispatch = useDispatch();

  const configId = ConfigurationId() as number;
  const clickOnMain = useCallback(
    (props: any) => {
      const currentStep = Math.floor(props.step);
      itemsValue.map((item) => {
        item.highlighted = 0;
        if (currentStep + 1 > item.step && item.step > currentStep) {
          item.highlighted = 1;
          //console.log(item.step);
        }

        //TODO: 6 need to
        [...Array(6)].forEach((element, index) => {
          if (item.step === index) {
            item.className = "";
          }
        });

        [...Array(currentStep)].forEach((element, index) => {
          //item.className="";
          if (item.step === index) {
            item.className = "main-done";
          }
        });
        return item;
      });
      setItemsValue([...itemsValue]);
    },
    [itemsValue]
  );

  useEffect(() => {
    setValue(1);
    itemsValue.forEach((node: any, index: number) => {
      node.index = index;
    });
    setItemsValue([...itemsValue]);
    // eslint-disable-next-line
  }, [setValue]);

  const stepHandler = (value: any) => {
    setValue(value);
    if (STEPPER_MAIN_NODES.indexOf(value) !== -1) setValue(value + 1);
    onChange?.(value);
  };

  const handleChange = (e: any) => {
    stepHandler(
      STEPPER_MAIN_NODES.indexOf(e.value) !== -1 ? e.value + 1 : e.value
    );
  };

  useEffect(() => {
    stepHandler(currentStep);
  }, [currentStep]);

  const CustomStep = (props: any) => {
    const className = classNames({
      "k-step-focus": true,
    });

    const stepViewHandler = () => {
      if (props.step === 1.85) {
        return <AircraftImage style={{ width: 23, marginRight: -5 }} />;
      }
      if (currentStep < 11) {
        return props.step < 2 && props.step;
      }
      if (currentStep < 18) {
        return props.step < 3 && props.step;
      }
      if (currentStep < 21) {
        return props.step < 4 && props.step;
      }
      if (currentStep < 25) {
        return props.step < 5 && props.step;
      }
      if (currentStep < 29) {
        return props.step < 6 && props.step;
      }
      if (currentStep < 40) {
        return props.step < 7 && props.step;
      }
    };

    return (
      <Step {...props}>
        {props.type === "main" ? (
          <Tooltip
            className="k-step-link"
            placement="bottom"
            color={props.step < 3 ? "#e6614b" : "#3890A7"}
            title={mainStepsLabels[props.step]}
          >
            <div className="k-step-link" title="">
              <span
                key={props.step}
                className="k-step-indicator"
                onClick={() => clickOnMain(props)}
                aria-hidden="true"
              >
                <span className="k-step-indicator-icon">{props.step}</span>
                <span className="k-step-label">
                  <span className="k-step-text">{props.label}</span>
                </span>
                <span
                  className={props.highlighted === 1 ? "highlighted" : "not"}
                ></span>
              </span>
            </div>
          </Tooltip>
        ) : (
          <div className={className}>
            <Tooltip
              className="k-step-link"
              placement="bottom"
              title={subStepsLabels[props.step]}
            >
              <div>
                <div
                  key={props.step}
                  onClick={() => clickOnMain(props)}
                  className="sub-step"
                >
                  <span id={props.step}>{stepViewHandler()}</span>
                  <span className="k-step-top-arrow"></span>
                  <span className="k-step-dot"></span>
                  <span className="progress-grow-active"></span>
                  <span
                    className={props.highlighted === 1 ? "highlighted" : "not"}
                  ></span>
                </div>
              </div>
            </Tooltip>
          </div>
        )}
      </Step>
    );
  };
  const capabilityManagement = () => {
    push(`/fleetbuild/${configId}`);
    removeMultipleItemsFromStorage(STEP_ARRAY);
  };

  useEffect(() => {
    dispatch(navigationSlice.actions.setCurrentStep(currentStep));
  }, [currentStep]);

  return (
    <>
      {dataSource.length >= 31 && (
        <>
          <div className="fleet-maintenance-icon">
            <div className="inner-view">
              <FleetMaintenance />
            </div>
            <div className="inner-view">
              <label className="label">Fleet Maintenance Analysis</label>
            </div>
          </div>
          <div className="fleet-capability-icon">
            <div className="inner-view">
              <FleetCapability onClick={() => capabilityManagement()} />
            </div>
            <div className="inner-view">
              <label className="label" onClick={() => capabilityManagement()}>
                Fleet Capability Management
              </label>
            </div>
          </div>
          <div className="left-line-arrow">
            <LeftLineArrow onClick={() => capabilityManagement()} />
            <style>
              {`
              .horiz-nav .k-step.k-step-first {
               display: none;
              }
              `}
            </style>
          </div>
        </>
      )}
      <div
        className="horiz-nav"
        style={{ width: "100%", position: "relative" }}
      >
        {dataSource.length < 31 && (
          <>
            <div className="fleet-bottom-line-wrapper" />
            <FleetBottomImageIcon className="fleet-btm-img" />
          </>
        )}
        <div
          className={
            dataSource.length < 31
              ? "fleet-bottom-line-second-wrapper"
              : "fleet-bottom-line-second-maintenance-wrapper"
          }
        />
        <OperationManagementIcon
          className={
            dataSource.length < 31
              ? "fleet-btm-second-img"
              : "fleet-btm-second-maintenance-img"
          }
        />

        {dataSource.length < 31 && (
          <FleetAnalyzeNextImage
            className="fleet-analyze-next-img"
            onClick={() => push(RoutePaths.maintenance)}
          />
        )}
        <Stepper
          style={{ width: "100%" }}
          value={value}
          onChange={handleChange}
          items={itemsValue}
          item={CustomStep}
        />
        <div className="route-icon">
          <PageRouting />
        </div>

        {/* According to kendo react lib use custom inline */}
        {dataSource.length >= 31 && (
          <style>
            {`.horiz-nav .k-stepper .k-step-first.k-step-done .k-step-indicator,
            .horiz-nav .k-stepper .k-step-second.k-step-done .k-step-indicator,
            .horiz-nav li.k-step:nth-child(11),
            .horiz-nav .k-step.sub-1,
            .horiz-nav .k-step.sub-2 {
            display: none !important;}
            .horiz-nav {
              margin-left: 120px;
            }
            .title-bar-img {
             visibility: hidden !important;
            }`}
          </style>
        )}
      </div>
    </>
  );
};

export default TopNavigation;
