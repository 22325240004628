import React from "react";
import Images from "../images";
import "./taillogo.scss";
interface Props {
  vertcalTitle: string;
  topSubTitle: string;
  topTitle: string;
  footerTitle: string;
}
const TailLogo: React.FC<Props> = ({
  vertcalTitle,
  topSubTitle,
  topTitle,
  footerTitle,
}) => {
  return (
    <div className="tail__logo">
      <div className="tail__logo__left">
        <label>{vertcalTitle}</label>
      </div>
      <div className="tail__logo__right">
        <div className="tail__logo--main">
          <label className="tail__logo--sm--text">{topSubTitle}</label>
          <div className="tail__logo--title">{topTitle}</div>
          <div className="tail__logo--image">
            <Images name="Hawk127" />
          </div>
          {footerTitle ?
            <div className="tail__logo--footer">{footerTitle}</div> : <div style={{
              height: 22
            }} />}
        </div>
      </div>
    </div>
  );
};
export default TailLogo;
