import * as React from "react";
import "./icon.scss";

const FleetCapability = (props: React.SVGProps<SVGSVGElement>) => {
  const { onClick } = props;

  return (
    <svg
      width={50}
      height={22}
      overflow="hidden"
      onClick={onClick}
      className="pointer"
      {...props}
    >
      <defs>
        <clipPath id="clip0">
          <rect x={249} y={83} width={50} height={22} />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0)" transform="translate(-249 -83)">
        <rect x={250} y={87} width={48} height={11} fill="#FFFFFF" />
        <path
          d="M271.5 88.0001C271.5 87.1716 272.395 86.5001 273.5 86.5001 274.605 86.5001 275.5 87.1716 275.5 88.0001 275.5 88.8285 274.605 89.5001 273.5 89.5001 272.395 89.5001 271.5 88.8285 271.5 88.0001Z"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M272.486 90.5001C272.508 90.6322 272.529 90.7644 272.341 90.827 272.153 90.8896 271.691 90.8633 271.357 90.8754 271.024 90.8875 270.623 90.7927 270.341 90.8997 270.059 91.0066 269.787 91.2468 269.663 91.5172 269.54 91.7877 269.607 91.606 269.599 92.5223 269.591 93.4385 269.37 96.2297 269.615 97.0148 269.86 97.7999 270.795 97.4467 271.067 97.2327 271.338 97.0188 271.212 96.4981 271.244 95.7312 271.277 94.9643 271.268 93.7978 271.26 92.6313"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M271.573 101.947 271.5 96.5001"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M1.99082 0C2.00516 0.13219 2.0195 0.264385 1.89404 0.326947 1.76858 0.389509 1.46031 0.363275 1.23807 0.375384 1.01583 0.387493 0.748778 0.292636 0.56059 0.399602 0.372401 0.506568 0.191381 0.746729 0.108938 1.01717 0.0264946 1.28761 0.0712999 1.10597 0.0659231 2.02223 0.0605463 2.93849-0.0864185 5.72965 0.0766767 6.51472 0.239772 7.2998 0.863482 6.94661 1.0445 6.73269 1.22552 6.51876 1.14129 5.99807 1.16279 5.23115 1.1843 4.46424 1.17892 3.29772 1.17355 2.13121"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-1 0 0 1 276.5 90.5001)"
        />
        <path
          d="M0 0 0.0730709 5.44735"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 275.5 101.947)"
        />
        <path
          d="M263.758 94.5194 259.5 94.5001"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M256.5 94.5001C256.5 93.9478 257.172 93.5001 258 93.5001 258.828 93.5001 259.5 93.9478 259.5 94.5001 259.5 95.0523 258.828 95.5001 258 95.5001 257.172 95.5001 256.5 95.0523 256.5 94.5001Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M264.812 88.3833 262.345 85.8825"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M260.706 84.2438C261.254 83.6954 262.066 83.6177 262.518 84.0702 262.971 84.5227 262.893 85.3341 262.345 85.8825 261.796 86.4309 260.985 86.5086 260.532 86.0561 260.08 85.6036 260.158 84.7922 260.706 84.2438Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M265.142 98.6157 262.043 101.535"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M260.008 103.471C259.567 103.007 259.665 102.198 260.227 101.663 260.789 101.129 261.602 101.072 262.043 101.535 262.484 101.999 262.386 102.808 261.824 103.343 261.262 103.877 260.449 103.935 260.008 103.471Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M268.629 102.24C264.199 100.975 262.346 96.0937 264.491 91.3367 265.448 89.2129 267.103 87.3726 269.137 86.1693"
          stroke="#E34429"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M0 0 4.25753 0.0193176"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 283.5 94.5194)"
        />
        <path
          d="M0 1.5C-1.9097e-16 0.671573 0.671573 -1.9097e-16 1.5 -3.8194e-16 2.32843 -7.6388e-16 3 0.671573 3 1.5 3 2.32843 2.32843 3 1.5 3 0.671573 3 -9.5485e-16 2.32843 0 1.5Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-1 0 0 1 290.5 92.5001)"
        />
        <path
          d="M0 0 3.51328 0.0234121"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 280.919 88.2909)"
        />
        <path
          d="M0 1.40425C-1.4753e-16 0.628705 0.518809 -1.7878e-16 1.15879 -3.5756e-16 1.79878 -7.1512e-16 2.31758 0.628705 2.31758 1.40425 2.31758 2.1798 1.79878 2.8085 1.15879 2.8085 0.518809 2.8085 -7.37649e-16 2.1798 0 1.40425Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 284.033 83.1584)"
        />
        <path
          d="M0 0 4.25753 0.0193176"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(0.724679 0.689087 0.689087 -0.724679 280.59 98.5233)"
        />
        <path
          d="M0 1.15879C-1.7878e-16 0.518809 0.628705 -1.4753e-16 1.40425 -2.9506e-16 2.1798 -5.90119e-16 2.8085 0.518809 2.8085 1.15879 2.8085 1.79878 2.1798 2.31758 1.40425 2.31758 0.628705 2.31758 -8.939e-16 1.79878 0 1.15879Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-0.724679 -0.689087 -0.689087 0.724679 286.522 102.539)"
        />
        <path
          d="M4.678 0.885056C8.70529-1.35326 13.4674 0.788539 15.3145 5.66889 16.1391 7.84778 16.2704 10.319 15.6831 12.6081"
          stroke="#E34429"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 274.42 106.082)"
        />
        <path
          d="M0 0 0.000104987 5.18131"
          stroke="#696969"
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 273.5 102.681)"
        />
      </g>
    </svg>
  );
};

export default FleetCapability;
