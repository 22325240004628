import React from "react";
import { Row, Col } from "antd";
const MaintenanceLayout: React.FC<{ children: Array<any> }> = ({
  children,
}) => {
  return (
    <div className="integrated-operation-container">
      <div className="individual-asset-objectives-wrapper">
        <Row wrap={false}>
          <Col flex="285px">
            <div className="p-2">
              <div className="d-flex flex-column">{children[0]}</div>
            </div>
          </Col>
          <Col flex="auto">
            <div className="p-2">
              <div className="d-flex top-box-group flex-wrap justify-content-between">
                {children[1]}
              </div>
              <div className="operation-container d-flex">
                {/* <div className="operation-status-col"></div> */}
                <div className="operation-graph-col col p-0">{children[2]}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MaintenanceLayout;
