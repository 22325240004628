import React, { useEffect, useRef, useState } from "react";
import RangeSlideButton, { Direction } from "./RangeSlideButton";
import ResizeHandleBar from "./ResizeHandleBar";
interface Props {
  range: Array<number>;
  update(list: Array<any>): void;
  maxYear?: number;
  sync?: boolean;
  startYear?: number;
}

function* getrange(start: number, end: number, step = 1) {
  if (end === undefined) [end, start] = [start, 0];
  for (let n = start; n < end; n += step) yield n;
}

let startRibbonMove = false;
let previousMove = 0;
const RIBBON_SCROLL = 100;
const SIDE_BUTTON_WIDTH = 30;
const MONTHLIWIDTH = 25;

const RangeRibbon: React.FC<Props> = ({
  range,
  update,
  maxYear = 1,
  sync = false,
  startYear = range[0],
}) => {
  const years = Array.from(getrange(range[0], range[1]));
  const months = Array.from(getrange(1, 13));
  const BAR_MAX_WIDTH = 300 * maxYear;
  const START_LEFT = (startYear - range[0]) * 300 + SIDE_BUTTON_WIDTH;
  const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const longMonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const rangeRef = useRef<HTMLUListElement>(null);
  const ribbonDivRef = useRef<HTMLDivElement>(null);
  const [ribbonWidth, setRibbonWidth] = useState<number>(0);

  const selectmonth = (slider: HTMLDivElement) => {
    const childrens = rangeRef.current?.children;
    const sliderDiv = slider.getBoundingClientRect();
    const selectedList = [];
    if (childrens) {
      for (let liIndex = 0; liIndex < childrens.length; liIndex++) {
        const liList = childrens.item(liIndex) as HTMLUListElement;

        const htmlidvs = liList.getElementsByClassName(
          "month-number"
        ) as HTMLCollection;
        for (let monthIndex = 0; monthIndex < htmlidvs.length; monthIndex++) {
          const divmonth = htmlidvs[monthIndex] as HTMLDivElement;
          const clientRect = divmonth.getBoundingClientRect();
          divmonth.className = divmonth.className.replace("select-month", "");
          if (
            clientRect.left >= sliderDiv.left - 2 &&
            clientRect.left + clientRect.width <= sliderDiv.right + 2
          ) {
            divmonth.className += " select-month";

            const year = divmonth.getAttribute("data-year") || "{}";
            const month = divmonth.innerHTML;

            selectedList.push({
              year: year,
              month: month,
              shortMonthName: shortMonthNames[parseInt(month) - 1],
              longMonthName: longMonthNames[parseInt(month) - 1],
            });
          }
        }
      }
    }
    if (!sync) {
      if (!startRibbonMove) {
        update(selectedList);
      }
    } else {
      update(selectedList);
    }
  };

  const slideClickHandler = (side: Direction) => () => {
    if (side === Direction.LEFT) {
      if (rangeRef.current) {
        rangeRef.current.scrollLeft -= RIBBON_SCROLL;
      }
    } else {
      if (rangeRef.current) {
        rangeRef.current.scrollLeft += RIBBON_SCROLL;
      }
    }
  };
  const moveHandler = (e: any) => {
    e.preventDefault();
    if (!startRibbonMove || e.buttons === 0 || !ribbonDivRef.current) {
      return;
    }
    const target = ribbonDivRef.current.firstChild as HTMLDivElement;
    if (!target) {
      return;
    }
    if (
      e.clientX - previousMove + target.offsetWidth >
        ribbonDivRef.current?.offsetWidth - SIDE_BUTTON_WIDTH ||
      e.clientX - previousMove - SIDE_BUTTON_WIDTH < 0
    ) {
      return;
    }
    target.style.left = `${e.clientX - previousMove}px`;
    setTimeout(
      (element: HTMLDivElement) => {
        selectmonth(element);
      },
      0,
      target
    );
  };
  const moveStartHandler = (start: boolean) => (e: any) => {
    startRibbonMove = false;
    if ((e.target as HTMLElement).nodeName !== "DIV") {
      return;
    }
    const rangeDiv = e.target as HTMLDivElement;

    if (rangeDiv.parentElement === null) {
      return;
    }
    const cssName = rangeDiv.className;
    //only allow to run when mouse up in range bar
    if (
      !(
        (cssName && cssName.includes("range-selector-bg")) ||
        (cssName && cssName.includes("re-s-e"))
      )
    ) {
      return;
    }
    const leftOld = rangeDiv.parentElement.style.left.replace("px", "");
    if (!start) {
      const leftCutoff = MONTHLIWIDTH / 2; // check width cut off value
      const currentLeft: number = parseInt(leftOld) - SIDE_BUTTON_WIDTH;
      const nextLeft = Math.ceil(currentLeft / MONTHLIWIDTH) * MONTHLIWIDTH;
      let fixedLeft = nextLeft;
      if (nextLeft - currentLeft > leftCutoff) {
        fixedLeft = Math.abs(
          Math.ceil(-currentLeft / MONTHLIWIDTH) * MONTHLIWIDTH
        );
      }
      if (ribbonDivRef.current) {
        const target = ribbonDivRef?.current.firstChild as HTMLDivElement;
        target.style.left = `${fixedLeft + SIDE_BUTTON_WIDTH}px`;
        const slideWidth = parseInt(target.style.width.replace("px", ""));
        const nextWidth = Math.ceil(slideWidth / MONTHLIWIDTH) * MONTHLIWIDTH;
        let fixedWidth = nextWidth;
        if (nextWidth - slideWidth > leftCutoff) {
          fixedWidth = Math.abs(
            Math.ceil(-slideWidth / MONTHLIWIDTH) * MONTHLIWIDTH
          );
        }
        target.style.width = `${fixedWidth}px`;

        selectmonth(target);
      }
    }

    startRibbonMove = start;

    const leftWidth = leftOld === "" ? SIDE_BUTTON_WIDTH : parseInt(leftOld);
    previousMove = e.clientX - leftWidth;
  };
  useEffect(() => {
    if (ribbonDivRef.current) {
      setRibbonWidth(ribbonDivRef.current?.offsetWidth);
    }
  }, [ribbonDivRef]);
  const sliderComplete = () => {
    if (ribbonDivRef.current?.firstChild) {
      selectmonth(ribbonDivRef.current?.firstChild as HTMLDivElement);
    }
  };
  // useEffect(() => {
  //   // document.addEventListener("mousemove", moveHandler);
  //   // document.addEventListener("mouseup", moveStartHandler(false));
  //   return () => {
  //     // document.removeEventListener("mousemove", moveHandler);
  //     // document.removeEventListener("mouseup", moveStartHandler(false));
  //   };
  //   // eslint-disable-next-line
  // }, []);
  return (
    <div
      onMouseMove={moveHandler}
      onMouseUp={moveStartHandler(false)}
      draggable="false"
      ref={ribbonDivRef}
      className="date-range-wrapper"
    >
      <div
        style={{ width: `${BAR_MAX_WIDTH}px`, left: START_LEFT }}
        className="range-selector"
      >
        {ribbonWidth > 0 && (
          <ResizeHandleBar
            side={Direction.LEFT}
            ribbonOfferWidth={ribbonWidth}
            onComplete={sliderComplete}
            years={maxYear}
          />
        )}
        <div
          className="range-selector-bg"
          onMouseDown={moveStartHandler(true)}
        ></div>

        {ribbonWidth > 0 && (
          <ResizeHandleBar
            side={Direction.RIGHT}
            ribbonOfferWidth={ribbonWidth}
            onComplete={sliderComplete}
            years={maxYear}
          />
        )}
      </div>

      <ul
        draggable="false"
        ref={rangeRef}
        className="date-range"
        role="listitem"
      >
        {years.map((year, index) => (
          <li key={index} className="year-box">
            <div className="row" style={{ paddingTop: "10px" }}>
              <div className="col">{year}</div>
            </div>
            <div className="row">
              {months.map((month) => (
                <div
                  data-year={year}
                  key={`${year}-${month}`}
                  className="month-number"
                >
                  {month}
                </div>
              ))}
            </div>
          </li>
        ))}
      </ul>
      <div className="paddles">
        <RangeSlideButton
          Side={Direction.LEFT}
          clickHandler={slideClickHandler(Direction.LEFT)}
        />
        <RangeSlideButton
          Side={Direction.RIGHT}
          clickHandler={slideClickHandler(Direction.RIGHT)}
        />
      </div>
    </div>
  );
};

export default RangeRibbon;
