import axios from "axios";
import { EnvUtils } from "../utils";

const instance = axios.create({
  baseURL: EnvUtils.BASE_API_URL,
  timeout: 10000,
  headers: {},
});

export { instance };
