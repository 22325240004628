import React, { useMemo, useEffect } from "react";
import dataJson from "../../models/acruntimefull.json";
import { OtherActivity, groupBy } from "../../models/maintenanceModel";
import * as d3 from "d3";
import EngineArrow from "../../components/EngineArrow";

interface Props {
  min?: number;
  max?: number;
}
let width = 600;
let widthmain = 600;
const strokeWidth = 0.3;
const ACRunTimeFullSchedule: React.FC<Props> = ({ min = 200, max = 5600 }) => {
  const data = dataJson as Array<OtherActivity>;
  const groupData = useMemo(() => {
    return groupBy(data);
  }, []);
  const headerHeight = 0;
  const itemHeight = 20;
  const itemCount = Math.floor(280 / itemHeight);
  const dimensios = {
    height: itemHeight * itemCount, // itemHeight * groupData.length + headerHeight,
    width: 0,
    boundedHeight: 0,
    boundedWidth: 0,
    margin: {
      top: headerHeight,
      left: 10,
      bottom: 0,
      right: 20,
    },
  };
  const findMax = (id: number): number => {
    const list = data.filter((a) => a.id === id);
    let maxItem = 0;
    if (list) {
      maxItem = Math.max(...list.map((a) => a.day));
    }
    return maxItem;
  };
  const drawChart = () => {
    const mainChartWidth =
      document.getElementById("chartfullWrapper")?.clientWidth || 600;
    if (widthmain === 600) {
      widthmain = mainChartWidth;
    }
    const clientWidth =
      document.getElementById("acruntimefulltChart")?.clientWidth || 600;
    if (width === 600) {
      width = clientWidth + 12;
    }
    dimensios.width = width;
    dimensios.boundedHeight =
      dimensios.height - dimensios.margin.top - dimensios.margin.bottom;
    dimensios.boundedWidth =
      dimensios.width - dimensios.margin.left - dimensios.margin.right;

    const domainMax = max;
    const domainMin = min;
    const ariaLeft = 80;
    const scaleDiff = 6;

    d3.selectAll("#acruntimefulltChart > *").remove();
    const svg = d3
      .selectAll("#acruntimefulltChart")
      .append("svg")
      .attr("width", width)
      .attr("height", dimensios.height);
    const bounded = svg
      .append("g")
      .style(
        "transform",
        `translate(${ariaLeft}px, ${dimensios.margin.top}px)`
      );

    const yScale = d3
      .scaleLinear()
      .domain([0, itemCount])
      .range([dimensios.boundedHeight, 0]);
    const fullXscale = d3
      .scaleLinear()
      .domain([domainMin, domainMax])
      .range([0, widthmain - (dimensios.margin.right + dimensios.margin.left)]);

    d3.selectAll(".x-axis .domain").remove();
    d3.selectAll(".x-axis .tick line").remove();
    const lineGenerator = d3.line();
    const widthDiff = widthmain - width - scaleDiff + ariaLeft;
    groupData.forEach((item) => {
      const line: Array<[number, number]> = [
        [0, yScale(item.id) + itemHeight / 4],
        [
          fullXscale(findMax(item.id)) - widthDiff,
          yScale(item.id) + itemHeight / 4,
        ],
      ];
      bounded
        .append("path")
        .attr("d", lineGenerator(line) as string)
        .attr("fill", "none")
        .attr("stroke-width", strokeWidth)
        .attr("stroke", item.color);

      svg
        .append("text")
        .attr("x", 0)
        .attr("y", yScale(item.id) + 3 + itemHeight / 4)
        .attr("class", "text")
        .text(item.name);

      bounded
        .append("circle")
        .attr("cx", 0)
        .attr("cy", yScale(item.id) + itemHeight / 4)
        .attr("r", 3)
        .attr("fill", item.color);
    });

    data.forEach((item) => {
      const line: Array<[number, number]> = [
        [fullXscale(item.day) - widthDiff, yScale(item.id) + itemHeight / 4],
        [fullXscale(item.day) - widthDiff, yScale(0)],
      ];
      bounded
        .append("path")
        .attr("d", lineGenerator(line) as string)
        .attr("fill", "none")
        .attr("stroke-width", strokeWidth)
        .attr("stroke", item.color);
      bounded
        .append("circle")
        .attr("cx", fullXscale(item.day) - widthDiff)
        .attr("cy", yScale(item.id) + itemHeight / 4)
        .attr("r", 2)
        .attr("fill", item.color);
      bounded
        .append("text")
        .attr(
          "x",
          fullXscale(item.day) -
            (widthDiff + dimensios.margin.left) -
            item.description.length * 4
        )
        .attr("y", yScale(item.id) + itemHeight / 4)
        .attr("class", "text-engine")
        .text(item.description);
    });
  };
  useEffect(drawChart, [data, max]);
  useEffect(() => {
    width = 600;
    widthmain = 600;
  }, []);
  return (
    <div className="runtimefull__schedule__other">
      <div className="runtimefull__schedule__other--ac">
        <div className="runtimefull__schedule__other--ac-box">
          <img src="../assets/images/plan1.png" />
          <EngineArrow />
        </div>
      </div>
      <div className="runtimefull__schedule__other--chart">
        <div id="acruntimefulltChart"></div>
      </div>
    </div>
  );
};

export default ACRunTimeFullSchedule;
