import { FormBox, Label, TextBox } from "@austaltech/components";
import React, { useEffect, useState } from "react";
import { Tabs, Tree, Row, Col } from "antd";
import { Images } from "../../../../components";
import { TreeNode } from "../../../../components/treeView/TreeView";
import * as d3 from "d3";
import { DownOutlined } from "@ant-design/icons";
import "./maintenance-activity.scss";
import "../../../fleetPlanning/styles/fleet-planing.scss";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  fetchIliasAssets,
  selectAirCraft,
  fetchAssetsWorkOrder,
  selectWorkOrder,
} from "../../thunk/maintenanceIliasThunk";
import { RootSliceType } from "../../../../redux/reducer";
import WorkOrderTabContent from "../../components/WorkOrderTabContent";
import {
  ELiasWorkOrder,
  ELiasWorkOrderActivity,
  emptyELiasWorkOrder,
  WorkOrderResponse,
} from "../../models/maintenanceModel";
import WorkActivityTabs from "../../components/WorkActivityTabs";
import { AppDispatch } from "../../../../redux";
import { EliasResponse, ServerResponse } from "../../../../utils";
const woTemplateColors = [
  {
    wo: "R10",
    color: "#FF6565",
  },
  {
    wo: "R20",
    color: "#FF6600",
  },
  {
    wo: "R30",
    color: "#FF3300",
  },
  {
    wo: "R40",
    color: "#993300",
  },
  {
    wo: "R50",
    color: "#70AD47",
  },
];
const woTree = [
  {
    workordertemplate: "R10",
    workorders: [
      {
        wo: "wo1",
        key: "wo_1",
        systems: [
          {
            sys: "HWK A–System",
            key: "wo_sys_1",
            subsystems: [
              {
                sub: "HWK E–Sub System",
                key: "wo_sub_sys_1",
                components: [
                  {
                    com: "Component 1",
                    key: "wo_sub_sys_com_1",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        wo: "wo2",
        key: "wo_2",
        systems: [],
      },
      {
        wo: "wo3",
        key: "wo_3",
        systems: [],
      },
      {
        wo: "wo4",
        key: "wo_4",
        systems: [],
      },
      {
        wo: "wo5",
        key: "wo_5",
        systems: [],
      },
    ],
  },
  {
    workordertemplate: "R20",
    workorders: [],
  },
  {
    workordertemplate: "R30",
    workorders: [],
  },
  {
    workordertemplate: "R40",
    workorders: [],
  },
  {
    workordertemplate: "R50",
    workorders: [],
  },
];

const onSelect = (selectedKeys: React.Key[], info: any) => {
  console.log("selected", selectedKeys, info);
};

const { TabPane } = Tabs;

interface Props {
  test?: number;
}

interface AntTreeNode {
  title: any;
  key: "";
  children: AntTreeNode[];
}

const MaintenanceActivity: React.FC<Props> = ({ test }) => {
  // const [treeItems, setTreeItems] = useState<Array<TreeNode>>(treeSource);
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<string>(
    "wo_0"
  );

  const squadrons = useSelector<RootSliceType, Array<TreeNode>>(
    (state) => state.maintenance.squadrons
  );
  const workOrders = useSelector<RootSliceType, Array<ELiasWorkOrder>>(
    (state) => state.maintenance.workOrders
  );
  const workOrder = useSelector<RootSliceType, ELiasWorkOrder>(
    (state) => state.maintenance.workOrder || emptyELiasWorkOrder
  );

  const dispatch: AppDispatch = useDispatch();
 
  const woTemplateContent = (wo: any, color: any): JSX.Element => {
    return (
      <div>
        <span className="r-circle" style={{ background: color }}>
          {wo}
        </span>
      </div>
    );
  };

  const woTemplateFirstContent = (wo: any, color: any): JSX.Element => {
    return (
      <div>
        <span className="r-circle" style={{ background: color }}>
          {wo}
        </span>
        <div className="tree-node-top-title">
          <ul>
            <li>CMC</li>
            <li>Status</li>
            <li>Hours</li>
          </ul>
        </div>
      </div>
    );
  };

  const woContent = (wo: any): JSX.Element => {
    return (
      <div className="r1">
        <span className="wo-circle" style={{ background: "#FF9B9B" }}>
          {wo}
        </span>
        <div className="node-content-list">
          <ul>
            <li>
              <span
                className="circle-hw"
                style={{ background: "#A9D18E" }}
              ></span>
            </li>
            <li>
              <span
                className="circle-hw"
                style={{ background: "#A9D18E" }}
              ></span>
            </li>
            <li>1.5 hrs</li>
          </ul>
        </div>
      </div>
    );
  };

  const woSystemContent = (sys: any): JSX.Element => {
    return (
      <div className="line-sub-ly">
        <div className="ln-dash"></div>
        <div className="ml-3 d-flex align-items-center sub-t-line-v">
          <div className="d-flex align-items-center">
            <span
              className="rectangle-hw"
              style={{ background: "#FF9494" }}
            ></span>
            <span
              className="rectangle-hw"
              style={{ background: "#E2F0D9" }}
            ></span>
          </div>
          <span className="pl-2">{sys}</span>
        </div>
      </div>
    );
  };

  const woSubSystemContent = (subSys: any): JSX.Element => {
    return (
      <div className="line-sub-ly2">
        <div className="ml-3 d-flex align-items-center  sub-t-line-v">
          <div className="d-flex align-items-center">
            <span
              className="rectangle-hw"
              style={{ background: "#E2F0D9" }}
            ></span>
          </div>
          <span className="pl-2">{subSys}</span>
        </div>
      </div>
    );
  };

  const woComponentContent = (com: any): JSX.Element => {
    return (
      <div className="line-sub-ly3">
        <div className="ml-3 d-flex align-items-center  sub-t-line-v">
          <div className="d-flex align-items-center">
            <span
              className="rectangle-hw"
              style={{ background: "#A9D18E" }}
            ></span>
          </div>
          <span className="pl-2">{com}</span>
        </div>
      </div>
    );
  };

  const [tree, setTree] = useState<Array<any>>([]);
  const setTreeData = () => {
    const tree: Array<any> = [];
    //work order templates
    woTree.forEach((wot: any, i: number) => {
      const woTemp = {
        title:
          i === 0
            ? woTemplateFirstContent(
                wot.workordertemplate,
                woTemplateColors.filter(
                  (item) => item.wo === wot.workordertemplate
                )[0]?.color
              )
            : woTemplateContent(
                wot.workordertemplate,
                woTemplateColors.filter(
                  (item) => item.wo === wot.workordertemplate
                )[0]?.color
              ),
        key: `wot_${i}`,
        children: [],
      } as AntTreeNode;

      //work order
      wot.workorders.forEach((wo: any, j: number) => {
        const woItem = {
          title: woContent(wo.wo),
          key: `wo_${j}`,
          children: [],
        } as AntTreeNode;

        //work order
        wo.systems.forEach((wos: any, k: number) => {
          const woSItem = {
            title: woSystemContent(wos.sys),
            key: `wos_${k}`,
            children: [],
          } as AntTreeNode;

          wos.subsystems.forEach((woss: any, l: number) => {
            const woSSItem = {
              title: woSubSystemContent(woss.sub),
              key: `woss_${l}`,
              children: [],
            } as AntTreeNode;

            woss.components.forEach((wocom: any, m: number) => {
              const woComItem = {
                title: woComponentContent(wocom.com),
                key: `wocom_${m}`,
                children: [],
              } as AntTreeNode;

              woSSItem.children.push(woComItem);
            });

            woSItem.children.push(woSSItem);
          });

          woItem.children.push(woSItem);
        });

        woTemp.children.push(woItem);
      });

      tree.push(woTemp);
    });

    setTree([...tree]);
    // setDefaultExpandedKeys("wo_0");
  };

  const onSqdClick = (item: TreeNode) => {
    batch(async () => {
      dispatch(selectAirCraft(item.key));
      if (!item.selected) {
        const server = await dispatch(fetchAssetsWorkOrder(item.key));
        const payload = server.payload as ServerResponse<
          EliasResponse<WorkOrderResponse>
        >;
        if (payload !== undefined) {
          if (payload.result.message.body) {
            const workOrderId =
              payload.result.message.body.workorder[0].workorderid;
            dispatch(selectWorkOrder(workOrderId));
          }
        }
      }
    });
  };

  const genarateTopArrow = () => {
    //l-arrow
    const svg = d3
      .select(".top-left-arrow")
      .append("svg")
      .style("position", "relative")
      .style("top", "-2px")
      .attr("width", 105)
      .attr("height", 150);

    svg
      .append("circle")
      .attr("cx", 40)
      .attr("cy", 120)
      .attr("fill", "#FF9494")
      .attr("r", 12);

    svg
      .append("text")
      .attr("x", 34)
      .attr("y", 123)
      .text("wo")
      .attr("fill", "#fff")
      .style("font-size", "11px");
  };

  React.useEffect(() => {
    setTreeData();
  }, []);

  const generateSqudrons = () => {
    const itm: any = [];
    squadrons.forEach((wing) => {
      wing.items
        .filter((t) => t.type === "squadron")
        .map((sqd: any, i: number) => {
          itm.push(
            <li key={`sqd_menu_item_${i}`}>
              <div
                onClick={() => onSqdClick(sqd)}
                style={{
                  cursor: "pointer",
                  display: "inline",
                }}
              >
                <span>{sqd.text}</span>
                <Images className="sqd-image" name={"Aircraft1"} />
                <div
                  style={{ display: sqd.selected ? "block" : "none" }}
                  className="arrow-left"
                ></div>
              </div>
            </li>
          );
        });
    });
    return itm;
  };
  const workOrderChange = (workOrderId: string) => {
    dispatch(selectWorkOrder(workOrderId));
  };
  useEffect(() => {
    dispatch(fetchIliasAssets());
  }, []);
  return (
    <div className="planning-container">
      <div className="maintenance-activity-container">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }} wrap={false}>
          <Col flex="none">
            <div>
              <div className="left-panel">
                <FormBox
                  label={`Template ${
                    squadrons.length > 0 ? squadrons[0].text : ""
                  }`}
                  style={{ width: "270px", marginLeft: "20px" }}
                >
                  {squadrons && (
                    <ul className="sqd-items">
                      <li>
                        <Images className="sqd-image" name={"Aircraft1"} />
                      </li>
                      {generateSqudrons()}
                    </ul>
                  )}
                </FormBox>
                <Tabs tabPosition={"left"} className="tab-style-customize">
                  <TabPane tab="Parent Work Order Structure" key="1">
                    <div className="notransition">
                      <FormBox
                        label="Template Hawk 127"
                        style={{ minHeight: "70vh" }}
                      >
                        <Tree
                          showLine={{ showLeafIcon: false }}
                          showIcon={true}
                          defaultExpandedKeys={[defaultExpandedKeys]}
                          onSelect={onSelect}
                          treeData={tree}
                          switcherIcon={<DownOutlined />}
                        />
                      </FormBox>
                    </div>
                  </TabPane>
                  <TabPane tab="Asset Hierarchy/Structure…" key="2">
                    Content of Tab 2
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col flex="auto">
            <div>
              <div className="col right-panel">
                <div className="row section-1-content">
                  <div className="top-left-arrow">
                    <span
                      className="circle"
                      style={{
                        backgroundColor: "#FF9494",
                        margin: "3px",
                        fontSize: "11px",
                      }}
                    >
                      R10
                    </span>
                  </div>
                  <div className="col r-specific-info">
                    <h4 className="md-font-size">R10 Specific Info</h4>
                    <div className="tasking-tab-content ground-support-equipment-form-container flight-sector-block ">
                      <div className="">
                        <div className="">
                          <div className="crew-content-block d-flex justify-content-between flex-wrap pr-3 specific-info-top">
                            <div className="d-flex initial-start-date align-items-center">
                              <div className="">
                                <Label>R10 Initial Start Date:</Label>
                                <TextBox
                                  className="primary-color-text text-center"
                                  value="21/03/2019"
                                  width={90}
                                />
                              </div>
                              <Images name={"CalenderIcon"} />
                            </div>
                            <div className="d-flex row-item pr-3 align-items-center">
                              <Label>Start Unit :</Label>
                              <TextBox
                                className="seashell-bg primary-color-text text-center"
                                value="Day"
                                width={60}
                              />
                            </div>
                            <div className="d-flex row-item pr-3 align-items-center">
                              <Label>Interval Unit :</Label>
                              <TextBox
                                className="seashell-bg primary-color-text text-center"
                                value="hours"
                                width={60}
                              />
                            </div>
                            <div className="d-flex row-item pr-3 align-items-center">
                              <Label>Interval :</Label>
                              <TextBox
                                className="seashell-bg primary-color-text text-center"
                                value="125 hours"
                                width={60}
                              />
                            </div>
                            <div className="d-flex  row-item pr-3 align-items-center">
                              <Label>R10-R20 Diff:</Label>
                              <TextBox
                                className="seashell-bg primary-color-text text-center"
                                value="0 hours"
                                width={70}
                              />
                            </div>
                            <div className="d-flex initial-start-date align-items-center mr-3">
                              <div>
                                <Label>R10 Valid To:</Label>
                                <TextBox
                                  className="primary-color-text text-center"
                                  value="21/03/2019"
                                  width={90}
                                />
                              </div>
                              <Images name={"CalenderIcon"} />
                            </div>
                          </div>
                          <hr />
                          <div className="crew-content-block d-flex justify-content-between flex-wrap mb-3 pr-3">
                            <div className="d-flex pl-3 align-items-center row-item">
                              <Label className="mr-2">
                                R10 Next Planned Date:
                              </Label>
                              <TextBox
                                className="primary-color-text text-center"
                                value="11/10/2020"
                                width={80}
                              />
                            </div>
                            <div className="d-flex pr-3 align-items-center row-item">
                              <Label className="mr-2">
                                Days to Next Planned R10:
                              </Label>
                              <TextBox
                                className="primary-color-text text-center"
                                value="12 days"
                                width={60}
                              />
                            </div>
                            <div className="d-flex initial-start-date align-items-center">
                              <div className=" ml-3 d-flex flex-column align-items-end mr-3">
                                <Label className="tacao-bg text-white mb-2 lbl-taco">
                                  R10 Revised Planned Date:
                                </Label>
                                <TextBox
                                  className="tacao-bg text-white text-center"
                                  value="05/10/2020"
                                  width={90}
                                />
                              </div>
                              <Images name={"CalenderIcon"} />
                            </div>
                            <div className="d-flex pr-3 align-items-center mr-3 row-item">
                              <Label className="mr-2">
                                Hours/Days R10 Advanced
                              </Label>
                              <TextBox
                                className="blue orange-peel-bg text-white text-center"
                                value="95 hours"
                                width={70}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="circle-efficiency-block text-center">
                    <div className="cycle-efficiency">76%</div>
                    <span>Cycle Efficiency</span>
                  </div>
                  <div className="col-12 border-top red-tab">
                    <Tabs
                      defaultActiveKey={workOrder.workorderid}
                      onChange={workOrderChange}
                      type="card"
                      size={"small"}
                    >
                      {workOrders.map((order) => (
                        <TabPane
                          tab={order.workorderid}
                          key={order.workorderid}
                          className="wo-tab-1"
                        >
                          <WorkOrderTabContent />
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>

                  {workOrder.activities.activity.length > 0 && (
                    <div className="col-12 row-data border-top green-tab">
                      <div className="wo-circle-inner-tab btm-wo-circle">
                        <span>WO</span>
                        <span className="arrow-lft-right"></span>
                      </div>
                      <div className="wo-tab">{workOrder.workorderid}</div>
                      <div className="l-arrow"></div>
                      <Tabs type="card" size={"small"} className="tab-set-1">
                        {workOrder.activities.activity.map((activity) => (
                          <TabPane
                            tab={activity.activitynumber}
                            key={activity.activitynumber}
                            style={{ paddingTop: "10px" }}
                          >
                            <div className="wo-circle-inner-tab btm-wo-circle green-tab-line-arrow">
                              <span className="t1">WO</span>
                              <span className="arrow-lft-right"></span>
                            </div>
                            <WorkActivityTabs />
                          </TabPane>
                        ))}
                      </Tabs>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="row align-items-start"></div>
      </div>
    </div>
  );
};

export default MaintenanceActivity;
