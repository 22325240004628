import React from "react";
import CategoryRow from "./CategoryRow";
import type { UUCCategory } from "../../../models";
import "./uuc.scss";
interface Props {
  title: string;
  items: Array<UUCCategory>;
}
const UUCCategoryContainer: React.FC<Props> = ({ title, items }) => {
  return (
    <div className="uuc__Box">
      <div className="uuc__Box--h-title">
        <div>
          <span>{title}</span>
        </div>
      </div>
      <div className="uuc__Box--main">
        {/* <span className="uuc__Box--title">{title}</span> */}
        {/* <div> */}
        {items.map((item, key: number) => (
          <CategoryRow
            key={`${title.substr(3)}${key}`}
            {...item}
            includeLabel={false}
          />
        ))}
        {/* </div> */}
      </div>
    </div>
  );
};

export default UUCCategoryContainer;
