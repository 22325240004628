import * as React from "react";

const FleetBottomImageIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={167} height={28} overflow="hidden" {...props}>
      <defs>
        <clipPath id="clip11">
          <rect x={436} y={110} width={167} height={28} />
        </clipPath>
      </defs>
      <g clipPath="url(#clip11)" transform="translate(-436 -110)">
        <rect x={436} y={115} width={49} height={17} fill="#FFFFFF" />
        <rect
          x={442.5}
          y={111.5}
          width={33}
          height={20}
          stroke="#FFFFFF"
          strokeWidth={1.33333}
          strokeMiterlimit={8}
          fill="#FFFFFF"
        />
        <rect x={436} y={115} width={49} height={12} fill="#FFFFFF" />
        <path
          d="M458.5 116C458.5 115.172 459.172 114.5 460 114.5 460.828 114.5 461.5 115.172 461.5 116 461.5 116.828 460.828 117.5 460 117.5 459.172 117.5 458.5 116.828 458.5 116Z"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M459.486 118.5C459.508 118.632 459.529 118.764 459.341 118.827 459.153 118.89 458.691 118.863 458.357 118.875 458.024 118.888 457.623 118.793 457.341 118.9 457.059 119.007 456.787 119.247 456.663 119.517 456.54 119.788 456.607 119.606 456.599 120.522 456.591 121.439 456.37 124.23 456.615 125.015 456.86 125.8 457.795 125.447 458.067 125.233 458.338 125.019 458.212 124.498 458.244 123.731 458.277 122.964 458.268 121.798 458.26 120.631"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M457.573 130.947 457.5 125.5"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M2.98623 0C3.00774 0.13219 3.02924 0.264385 2.84106 0.326947 2.65287 0.389509 2.19046 0.363275 1.8571 0.375384 1.52374 0.387493 1.12317 0.292636 0.840884 0.399602 0.558602 0.506568 0.287071 0.746729 0.163406 1.01717 0.0397418 1.28761 0.10695 1.10597 0.0988846 2.02223 0.0908194 2.93849-0.129628 5.72965 0.115015 6.51472 0.359658 7.2998 1.29522 6.94661 1.56675 6.73269 1.83828 6.51876 1.71193 5.99807 1.74419 5.23115 1.77645 4.46424 1.76838 3.29772 1.76032 2.13121"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-1 0 0 1 463.5 118.5)"
        />
        <path
          d="M0 0 0.0730709 5.44735"
          stroke="#696969"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 461.5 130.947)"
        />
        <path
          d="M449.758 122.519 445.5 122.5"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M442.5 122.5C442.5 121.948 443.172 121.5 444 121.5 444.828 121.5 445.5 121.948 445.5 122.5 445.5 123.052 444.828 123.5 444 123.5 443.172 123.5 442.5 123.052 442.5 122.5Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M451.429 116.613 448.962 114.112"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M447.323 112.473C447.871 111.925 448.683 111.847 449.135 112.3 449.588 112.752 449.51 113.564 448.962 114.112 448.413 114.661 447.602 114.738 447.149 114.286 446.697 113.833 446.775 113.022 447.323 112.473Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M451.759 126.845 448.66 129.765"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M446.625 131.7C446.184 131.237 446.282 130.427 446.844 129.893 447.406 129.359 448.219 129.301 448.66 129.765 449.101 130.229 449.003 131.038 448.441 131.573 447.879 132.107 447.066 132.164 446.625 131.7Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M455.246 130.47C450.816 129.205 448.963 124.323 451.108 119.566 452.065 117.442 453.72 115.602 455.754 114.399"
          stroke="#E34429"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M0 0 4.25753 0.0193176"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 469.5 122.519)"
        />
        <path
          d="M0 1C-1.27313e-16 0.447715 0.447715 -1.27313e-16 1 -2.54627e-16 1.55228 -5.09253e-16 2 0.447715 2 1 2 1.55228 1.55228 2 1 2 0.447715 2 -6.36567e-16 1.55228 0 1Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-1 0 0 1 476.5 121.5)"
        />
        <path
          d="M0 0 3.51328 0.0234121"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 467.536 116.52)"
        />
        <path
          d="M0 1.40425C-1.4753e-16 0.628705 0.518809 -1.7878e-16 1.15879 -3.5756e-16 1.79878 -7.1512e-16 2.31758 0.628705 2.31758 1.40425 2.31758 2.1798 1.79878 2.8085 1.15879 2.8085 0.518809 2.8085 -7.37649e-16 2.1798 0 1.40425Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 470.65 111.388)"
        />
        <path
          d="M0 0 4.25753 0.0193176"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(0.724679 0.689087 0.689087 -0.724679 467.207 126.753)"
        />
        <path
          d="M0 1.15879C-1.7878e-16 0.518809 0.628705 -1.4753e-16 1.40425 -2.9506e-16 2.1798 -5.90119e-16 2.8085 0.518809 2.8085 1.15879 2.8085 1.79878 2.1798 2.31758 1.40425 2.31758 0.628705 2.31758 -8.939e-16 1.79878 0 1.15879Z"
          stroke="#E34D34"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-0.724679 -0.689087 -0.689087 0.724679 473.139 130.768)"
        />
        <path
          d="M4.678 0.885056C8.70529-1.35326 13.4674 0.788539 15.3145 5.66889 16.1391 7.84778 16.2704 10.319 15.6831 12.6081"
          stroke="#E34429"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 461.037 134.311)"
        />
        <path
          d="M0 0 0.000104987 5.18131"
          stroke="#696969"
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 460.5 130.681)"
        />
        <path
          d="M479 120.854C479 120.382 479.382 120 479.853 120L601.146 120C601.618 120 602 120.382 602 120.854L602 131.146C602 131.618 601.618 132 601.146 132L479.853 132C479.382 132 479 131.618 479 131.146Z"
          fill="#FFFFFF"
          fillRule="evenodd"
        />
        <text
          fill="#717275"
          fontFamily="Calibri,Calibri_MSFontService,sans-serif"
          fontWeight={300}
          fontSize={9}
          transform="translate(485.76 129)"
        >
          {"Fleet Capability Management"}
        </text>
      </g>
    </svg>
  );
};

export default FleetBottomImageIcon;
