import { rootReducer } from "./reducer";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { FleetGroupType } from "../features/navigation/types";
import { getFromSessionStorage } from "../utils/sessionStorage";
import { SessionStorageField } from "../utils/constant";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const middleware = [...getDefaultMiddleware({ thunk: true })];
function getFromSession<T>(key: string): T {
  const jstr = getFromSessionStorage(key);
  if (jstr !== null) {
    return JSON.parse(jstr) as T;
  }
  return {} as T;
}
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["fleetScheduleReducer", "modelReducer", "naviReducer"],
};

const preLoadState = {
  naviReducer: {
    currentStep: "",
    selectedGroup: getFromSession<FleetGroupType>(
      SessionStorageField.fleetsGroups
    ),
    errors: [],
    fleetType: {
      id: 0,
      isSelect: false,
      name: "",
      modellingFinish: "",
      modellingStart: "",
    },
    fleets: [],
    fleetsGroups: [],
    reportName: "",
    reportSection: "",
    selectWing: "",
    wings: [],
    profileManagerInnerTabStep: 1,
    collapse: false,
  },
  tree: {
    assetList: [],
    selectAsset: null,
    isLeftPanelOpen: false,
    errors: [],
  },
  fleetScheduleReducer: {
    errors: [],
    lastVersionPrimaryKey: null,
    baselineModel: {
      id: null,
      name: null,
    },
    isModelChange: false,
    isOptimized: false,
    baselineModels: [],
  },
  modelReducer: {
    selectModelData: {},
    errors: [],
  },
} as any;
const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware,
  preloadedState: preLoadState,
});

const persistor = persistStore(store);
export { store, persistor };
export type { RootSliceType } from "./reducer";
export type { App } from "./appSlice";
export type AppDispatch = typeof store.dispatch;
