import React from "react";
import { FormBox } from "@austaltech/components";
import { Labels, ActivityRow } from "../models/ScheduleActivityModel";

interface Props {
  dataSet: Array<ActivityRow>;
  title: string;
}

const ScheduleActivityForm: React.FC<Props> = ({ dataSet, title }) => {
  return (
    <FormBox label={title}>
      <div className="route__activities--table--container table-fix-head" >
        <table className="route__activities--table">
          <tr>
            <th style={{ width: "140px" }}>{Labels.ThActiveType}</th>
            <th style={{ width: "80px" }}>{Labels.ThID}</th>
            <th style={{ width: "50px" }}>{Labels.ThIntervalHours}</th>
            <th style={{ width: "50px" }}>{Labels.ThIntervalDays}</th>
            <th style={{ width: "50px" }}>{Labels.EffortInDays}</th>
          </tr>
          <tbody>
            {dataSet.map((item, idx: number) => (
              <tr key={`saw${idx}`}>
                <td>{item.activityName}</td>
                <td>
                  <div
                    className="route__analysis--id"
                    style={{ backgroundColor: item.color }}
                  >
                    {item.id}
                  </div>
                </td>
                <td>{item.intervalHours}</td>
                <td>{item.intervalDays}</td>
                <td>{item.workingDays}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </FormBox>
  );
};

export default ScheduleActivityForm;
