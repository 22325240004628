import * as React from "react";
import { Select } from "antd";
import { FLAMINGO } from "../../utils/constant";

const { Option } = Select;

type Props = {
  style?: Record<string, any>;
  data: Array<{ name: string; value: string }>;
  width?: number;
  placeHolder?: string;
  defaultValue?: string;
  onSelect?: (x: any) => void;
  value?: string;
  disabled?: boolean;
};

const SelectBox: React.FC<Props> = ({
  style,
  data,
  width,
  value,
  placeHolder,
  onSelect,
  disabled,
}) => {
  const onChange = (selectValue: any) => {
    if (onSelect) onSelect(selectValue);
  };

  const onBlur = () => {
    // console.log("blur");
  };

  const onFocus = () => {
    // console.log("focus");
  };

  const onSearch = (val: any) => {
    // console.log("search:", val);
  };

  return (
    <Select
      showSearch
      style={{ width: width || 140, ...style }}
      placeholder={placeHolder || ""}
      optionFilterProp="children"
      showArrow={false}
      onChange={onChange}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
    >
      {data.map((_option, i) => (
        <Option key={_option.value + i} value={_option.value}>
          {_option.name}
        </Option>
      ))}
    </Select>
  );
};

export default SelectBox;
