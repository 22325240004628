import React from "react";
import { useSelector } from "react-redux";
import { ActiveProgress, Toast } from "../../components";
import { App, RootSliceType } from "../../redux";
import "./fleetbuildlayout.scss";

const MainContainer: React.FC = ({ children }) => {
  const app = useSelector<RootSliceType, App>((state) => state.appReducer);

  return (
    <>
      {/* For demo and testing purpose */}
      <ActiveProgress show={app.activeProgress.value} />
      <Toast />
      <div className="contents fade-container">
        <div className="fleet-content-wrapper animation-container">
          {children}
        </div>
      </div>
    </>
  );
};

export default MainContainer;
