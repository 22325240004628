import React, { CSSProperties } from "react";
import omgt from "./operationM.png";

interface Props {
  className?: string;
  style?: CSSProperties;
}

const OmgtImage: React.FC<Props> = ({ className, style }) => {
  return <img alt="omgt" className={className} style={style} src={omgt} />;
};

export default OmgtImage;
