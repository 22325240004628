import React, { useState } from "react";
import "./runtimefull.scss";
import { Labels } from "../../models/runTimeScheduleModel";
import MaintenanceSchedule from "./MaintenanceSchedule";
import GPMaintenanceSchedule from "./GPMaintenanceSchedule";
import type { MaintenanceElementDemo } from "../../models/maintenanceModel";
import dataset from "../../models/RoutineElement.json";
import otherActivities from "../../models/otherActivityFull.json";
import dataSummarySet from "../../models/RouteElementSummary.json";
import RunTimeOther from "../runTimeSchedule/RunTimeOther";
import AcRunTimeFullSchedule from "./AcRunTimeFullSchedule";

const RunTimeSchedule = () => {
  const [min, setMin] = useState<number>(200);
  const [max, setMax] = useState<number>(5600);

  const [routes, setRoutes] = useState<Array<MaintenanceElementDemo>>(
    dataset as Array<any>
  );

  return (
    <div className="runtime__schedule">
      <h1>{Labels.StepTitle}</h1>
      <div className="runtime__schedule--row">
        <div className="runtime__schedule--vtext">
          <label htmlFor="">Hawk Run Time Schedule</label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <AcRunTimeFullSchedule min={min} max={max} />
        </div>
      </div>
      <div className="runtime__schedule--row">
        <div className="runtime__schedule--vtext">
          <label htmlFor="">Hawk 127 Maintenance Activity (Other)</label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <RunTimeOther
            activities={otherActivities}
            isFull={true}
            strokeWidth={0.3}
            min={min}
            max={max}
            mainChartName="chartfullWrapper"
          />
        </div>
      </div>
      <div className="runtime__schedule--row">
        <div className="runtime__schedule--vtext">
          <label htmlFor="">Hawk 127 Maintenance Schedule</label>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MaintenanceSchedule
              min={min}
              max={max}
              ticks={28}
              data={routes.filter((a) => a.intervalHours > 0)}
              summary={dataSummarySet as Array<any>}
            />
          </div>
        </div>
      </div>
      <div className="runtime__schedule--row">
        <div className="runtime__schedule--vtext">
          <label style={{ color: "transparent" }} htmlFor="">
            Hawk 127 Maintenance Schedule
          </label>
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <GPMaintenanceSchedule
              min={min}
              max={max}
              ticks={28}
              data={dataSummarySet as Array<any>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RunTimeSchedule;
