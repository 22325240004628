import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  FacilityDBType,
  FacilityConfigurationResponse,
  Facility,
  initFacilityConfiguration,
  GetAllFacilityDBType,
  FacilityFleetDBType,
  Fleet,
} from "../fleetForms/model";
import { fetchFacilityByFleet } from "../thunk/facilityThunk";

export interface FacilitySliceType {
  facilityTypes: Array<FacilityDBType>;
  facilityConfig: FacilityConfigurationResponse;
  facilityFleets: Array<FacilityFleetDBType>;
}
const initialState: FacilitySliceType = {
  facilityTypes: [],
  facilityConfig: initFacilityConfiguration,
  facilityFleets: [],
};

const facilitySlice = createSlice({
  name: "facility",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchFacilityByFleet.fulfilled,
      (state, action: PayloadAction<GetAllFacilityDBType>) => {
        state.facilityTypes = action.payload
          ? action.payload.facilityConfigurations
          : [];
        const db = state.facilityTypes.map((item) => {
          const facility: Facility = {
            baysPerLane: item.bayPerLane,
            constraints: item.constraints,
            endDate: moment(item.finishDate, "DD/MM/yyyy").toString(),
            startDate: moment(item.startDate, "DD/MM/yyyy").toString(),
            id: item.id,
            hangarName: item.hangarName,
            lanes: item.lanes,
            baysForFleets: item.facilityBays.map((bay) => ({
              baysPerFleet: bay.bayCount,
              fleetName: bay.bayName,
              fleetId: bay.fleetId,
              id: bay.id,
            })),
            hangarLocation: item.locationId,
            maintenanceUnit: item.maintenanceId,
            totalBays: item.bayPerLane * item.lanes,
          };
          return facility;
        });
        state.facilityConfig.facilities = db;
        state.facilityFleets = action.payload
          ? action.payload.facilityFleets
          : [];
        const facilityFleets = state.facilityFleets.map((item) => {
          const fleet: Fleet = {
            id: item.fleetId,
            fleetId: item.name,
          };
          return fleet;
        });
        state.facilityConfig.fleets = facilityFleets;
      }
    );
  },
});

export default facilitySlice.reducer;
