import * as React from "react";

const OperationManagementIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={149} height={32} overflow="hidden" {...props}>
      <defs>
        <clipPath id="clip13">
          <rect x={865} y={26} width={149} height={32} />
        </clipPath>
      </defs>
      <g clipPath="url(#clip13)" transform="translate(-865 -26)">
        <path
          d="M891 40.8535C891 40.3821 891.382 40 891.854 40L1013.15 40C1013.62 40 1014 40.3821 1014 40.8535L1014 51.1465C1014 51.6179 1013.62 52 1013.15 52L891.854 52C891.382 52 891 51.6179 891 51.1465Z"
          fill="#FFFFFF"
          fillRule="evenodd"
        />
        <text
          fill="#717275"
          fontFamily="Calibri,Calibri_MSFontService,sans-serif"
          fontWeight={300}
          fontSize={9}
          transform="translate(905.375 49)"
        >
          {"Operations Management"}
        </text>
        <path
          d="M867.5 41.5001C867.5 34.3204 873.32 28.5001 880.5 28.5001 887.68 28.5001 893.5 34.3204 893.5 41.5001 893.5 48.6798 887.68 54.5001 880.5 54.5001 873.32 54.5001 867.5 48.6798 867.5 41.5001Z"
          stroke="#FFFFFF"
          strokeWidth={1.33333}
          strokeMiterlimit={8}
          fill="#FFFFFF"
          fillRule="evenodd"
        />
        <path
          d="M0 0 2.27192 1.4611"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-1 0 0 1 873.772 45.5001)"
        />
        <path
          d="M868.452 47.5696C868.085 46.9992 868.25 46.2395 868.82 45.8726 869.391 45.5058 870.15 45.6708 870.517 46.2411 870.884 46.8115 870.719 47.5713 870.149 47.9381 869.578 48.3049 868.819 48.1399 868.452 47.5696Z"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M0 0 0.11685 7.96378"
          stroke="#00718E"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 870.5 46.4638)"
        />
        <path
          d="M0 0 7.6084 4.85911"
          stroke="#00718E"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 871.5 36.3592)"
        />
        <path
          d="M873.617 39.4233 871.5 37.5"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M868.894 35.6906C869.35 35.1887 870.127 35.1514 870.628 35.6074 871.13 36.0634 871.168 36.84 870.712 37.3419 870.256 37.8439 869.479 37.8811 868.977 37.4251 868.475 36.9691 868.438 36.1926 868.894 35.6906Z"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M0 0 0.0586877 2.64924"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 879.5 35.1493)"
        />
        <path
          d="M879.405 29.4625C880.082 29.43 880.657 29.9528 880.69 30.6302 880.722 31.3076 880.2 31.8831 879.522 31.9155 878.845 31.948 878.269 31.4253 878.237 30.7479 878.204 30.0705 878.727 29.495 879.405 29.4625Z"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M886.5 45.5001 888.926 47.0604"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M0 1.22787C-1.56331e-16 0.549738 0.549761 -1.56325e-16 1.22793 -3.12649e-16 1.90609 -6.25299e-16 2.45585 0.549738 2.45585 1.22787 2.45585 1.90601 1.90609 2.45575 1.22793 2.45575 0.549761 2.45575 -7.81657e-16 1.90601 0 1.22787Z"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-0.841073 -0.540921 -0.540921 0.841073 891.139 46.6364)"
        />
        <path
          d="M889.617 46.4638 889.5 38.5001"
          stroke="#00718E"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M889.108 36.3592 881.5 31.5001"
          stroke="#00718E"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M0 0 1.94131 1.76357"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 886.5 39.2636)"
        />
        <path
          d="M0 1.22793C-1.56331e-16 0.549761 0.549761 -1.56331e-16 1.22793 -3.12663e-16 1.90609 -6.25326e-16 2.45585 0.549761 2.45585 1.22793 2.45585 1.90609 1.90609 2.45585 1.22793 2.45585 0.549761 2.45585 -7.81657e-16 1.90609 0 1.22793Z"
          stroke="#E34D34"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-0.740175 0.672414 0.672414 0.740175 889.207 34.8801)"
        />
        <path
          d="M877.5 39.0001C877.5 37.6193 878.396 36.5001 879.5 36.5001 880.605 36.5001 881.5 37.6193 881.5 39.0001 881.5 40.3808 880.605 41.5001 879.5 41.5001 878.396 41.5001 877.5 40.3808 877.5 39.0001Z"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M877.5 42.5001 880.079 46.3092"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M877.191 42.5521C876.61 42.6987 876.029 42.8454 875.611 43.102 875.193 43.3586 874.976 43.2853 874.685 44.0917 874.394 44.8982 873.922 47.0609 873.868 47.9407 873.813 48.8204 872.815 49.1412 874.358 49.3703 875.902 49.5994 881.44 49.4894 883.129 49.3153 884.817 49.1412 884.409 49.3336 884.49 48.3256 884.572 47.3175 884.118 44.22 883.619 43.2669 883.12 42.3139 882.307 42.4605 881.494 42.6071"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M0 0 1.71538 3.89806"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-1 0 0 1 881.215 42.5001)"
        />
        <path
          d="M880.5 48.5001C879.865 48.0675 879.229 47.635 878.581 47.4048 877.933 47.1747 876.89 47.4366 876.612 47.1191 876.335 46.8016 876.625 46.1508 876.915 45.5001"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M876.5 46.4408C877.022 46.6906 877.544 46.9405 878.105 46.9699 878.666 46.9993 879.356 46.6171 879.865 46.6171 880.374 46.6171 880.728 46.8327 881.159 46.9699 881.59 47.1071 882.298 47.6853 882.453 47.4403 882.608 47.1953 882.35 46.3477 882.091 45.5001"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M0 0 0.000104987 2.73617"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 876.5 52.2362)"
        />
        <path
          d="M0 0 0.000104987 2.73617"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 882.5 52.2362)"
        />
        <path
          d="M0 0 0.107192 3.50824"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          fill="none"
          fillRule="evenodd"
          transform="matrix(1 0 0 -1 879.5 46.0083)"
        />
        <path
          d="M0 0 1.13848 0.000104987"
          stroke="#595959"
          strokeWidth={0.333333}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          fill="none"
          fillRule="evenodd"
          transform="matrix(-1 0 0 1 880.639 42.5001)"
        />
      </g>
    </svg>
  );
};

export default OperationManagementIcon;
