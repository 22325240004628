import { API } from "../../../api";
import { ServerResponse } from "../../../utils";
import type { FleetGroupType } from "../types";
import type {
  FleetServerType,
  WingServerType,
} from "../../fleetBuilder/fleetForms";
import { FLEET_CONFIGURATION, GROUP, WINGS_GROUP } from "../../../api/endpoint";
import {
  DEFAULT_ERROR_MESSAGE,
  SessionStorageField,
} from "../../../utils/constant";
import {
  removeSessionFromStorage,
  addToSessionStorage,
} from "../../../utils/sessionStorage";

const getFleetGroups = async (): Promise<Array<FleetGroupType>> => {
  try {
    //Temporary add
    removeSessionFromStorage(SessionStorageField.GroupsError);
    const response = await API.get<ServerResponse<Array<FleetGroupType>>>(
      GROUP
    );
    if (response.data.error !== "") {
      return [];
    }
    return response.data.result !== null ? response.data.result : [];
  } catch (e) {
    //Temporary add
    addToSessionStorage(SessionStorageField.GroupsError, {
      statusCode: 500,
      message: DEFAULT_ERROR_MESSAGE,
    });
    return [];
  }
};
const getFleetsByGroupId = async (
  groupId: number
): Promise<Array<FleetServerType>> => {
  try {
    const response = await API.get<
      ServerResponse<{ fleetConfigurations: Array<FleetServerType> }>
    >(`${FLEET_CONFIGURATION}/${groupId}`);
    // console.log("win grous", response.data.result.fleetConfigurations);
    return response.data.result !== null
      ? response.data.result.fleetConfigurations
      : [];
  } catch (e) {
    return [];
  }
};

const getWingsByGroupId = async (
  groupId: number
): Promise<Array<WingServerType>> => {
  try {
    const response = await API.get<any>(`${WINGS_GROUP}/${groupId}`);
    return response.data !== null ? response.data : [];
  } catch (e) {
    return [];
  }
};

const NavigationService = {
  getFleetGroups: getFleetGroups,
  getFleetsByGroupId: getFleetsByGroupId,
  getWingsByGroupId: getWingsByGroupId,
};
export { NavigationService };
