import React, { FC, useState } from "react";

import { Label, ListBox, ScrollListBox } from "@austaltech/components";
import { DataSource } from "@austaltech/components/build/selectionBox/Box";
interface TargetCalenderViewTypes {
  listChangeHandler: (_name: string) => (_data: Array<DataSource>) => void;
  weeks: Array<DataSource>;
  years: Array<DataSource>;
  hours: Array<DataSource>;
  labelWeeklyFlyingDays: string;
  labelFlyingWeeksPerYear: string;
  labelFlyingHoursPerDay: string;
}
const labelSubDescriptionHandler = (text: string, top?: any, left?: any) => {
  return (
    <span
      style={{
        position: "absolute",
        top: top || "24px",
        left: left || "1px",
        color: "#81ADBD",
        fontSize: "13px",
        overflow: "hidden",
        width: 80,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {text}
    </span>
  );
};

const TargetCalenderView: FC<TargetCalenderViewTypes> = ({
  weeks,
  hours,
  years,
  labelWeeklyFlyingDays,
  labelFlyingWeeksPerYear,
  labelFlyingHoursPerDay,
  listChangeHandler,
}) => {
  return (
    <div>
      <div className="form-group row  cmb-5">
        <Label className="col-form-label">Weekly flying Days</Label>
        <div className="pl-34 weekend-color-wrapper">
          <ListBox
            dataSource={weeks}
            OnListChanged={listChangeHandler(labelWeeklyFlyingDays)}
            multiSelect={true}
          />
        </div>
      </div>

      <div className="form-group row">
        <Label className="col-form-label">Flying weeks per Year:</Label>
        <div className="pl-sm scroll-l">
          <ScrollListBox
            dataSource={years}
            OnSelectChanged={listChangeHandler(labelFlyingWeeksPerYear)}
          />
        </div>
      </div>

      <div className="form-group row cmb-5">
        <Label className="col-form-label">Flying Hours per Day (Asset):</Label>
        {labelSubDescriptionHandler("Initial ROE")}
        <div className="pl-sm scroll-l">
          <ScrollListBox
            dataSource={hours}
            OnSelectChanged={listChangeHandler(labelFlyingHoursPerDay)}
          />
        </div>
      </div>
    </div>
  );
};
export default TargetCalenderView;
