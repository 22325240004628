import React from "react";
import { FormBox, Label, TextBox } from "@austaltech/components";

const WorkOrderTabContent = () => {
  return (
    <>
      <div className="wo-circle-inner-tab">
        <span>WO</span>
        <span className="arrow-lft-right"></span>
      </div>
      <div className="row">
        <div className="col-lg-9 pb-2 pl-0">
          <div className="row">
            <div className="col-8">
              <div className="section-1 tasking-tab-content">
                <h4 className="md-font-size">
                  WO ID & Equipment Connection Info:
                </h4>
                <div>
                  <div className="d-flex row-item">
                    <Label>Aircraft Type:</Label>
                    <TextBox
                      className="primary-color-text text-center"
                      value="Hawk A127"
                    />
                  </div>
                </div>
                <div>
                  <div className="d-flex row-item">
                    <Label>Location Applied:</Label>
                    <TextBox
                      className="primary-color-text text-center"
                      value="76 SQN"
                    />
                  </div>
                </div>
                <div>
                  <div className="d-flex row-item">
                    <Label>Connection Type:</Label>
                    <TextBox
                      className="primary-color-text text-center"
                      value="Task to Equipment"
                    />
                  </div>
                </div>

                <div>
                  <div className="d-flex row-item">
                    <Label>CMC Equipment Code:</Label>
                    <TextBox
                      className="primary-color-text text-center"
                      value="CMC_Object ID"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="section-2">
                <h4 className="md-font-size">Tasks Hierarchy Belongs to:</h4>
                <div>
                  <div style={{ display: "flex" }}>
                    <Label>Work Order:</Label>
                    <TextBox
                      className="primary-color-text text-center"
                      value="WO 01"
                    />
                  </div>
                </div>
                <div>
                  <div style={{ display: "flex" }}>
                    <Label>Maintenance Activity :</Label>
                    <TextBox
                      className="primary-color-text text-center"
                      value="R10"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <hr />
            <div className="crew-content-block tasking-tab-content d-flex justify-content-between flex-wrap">
              <div className="w-100">
                <div className="d-flex">
                  <div className="col pl-0">
                    <h4 className="md-font-size">WO 01 Specific Info</h4>
                    <div className="d-flex ">
                      <div className="d-flex pr-3 row-item">
                        <Label>Maintenance Org:</Label>
                        <TextBox
                          className="primary-color-text text-center"
                          value="BAEW"
                          width={80}
                        />
                      </div>
                      <div className="d-flex pr-3 row-item">
                        <Label>Hangar:</Label>
                        <TextBox
                          className="primary-color-text text-center"
                          value=" Hangar 1"
                          width={80}
                        />
                      </div>
                      <div className="d-flex pr-3 row-item">
                        <Label>Lane:</Label>
                        <TextBox
                          className="primary-color-text text-center"
                          value="01"
                          width={80}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="avalability-status-block pt-2 pl-3 pr-3 pb-2">
                    <div className="d-flex mb-2 justify-content-between w-100">
                      <Label>Facility Availability for next R10: </Label>
                      <span
                        className="text-white text-center"
                        style={{ background: "#FF6B6B" }}
                      >
                        No
                      </span>
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <Label>Resource Availability for next R10: </Label>
                      <span
                        className="text-white text-center"
                        style={{ background: "#8EC26A" }}
                      >
                        Yes
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-3 justify-content-between col wo-name-block">
                  <div className="d-flex pr-3 row-item">
                    <Label>WO 01 Name:</Label>
                    <TextBox
                      className="primary-color-text text-center"
                      value="Steering Assembly"
                      width={80}
                    />
                  </div>
                  <div className="d-flex pr-3 row-item">
                    <Label>WO 01 Description:</Label>
                    <TextBox
                      className="primary-color-text text-center"
                      value="Routine Maintenance of Steering Assy"
                      width={250}
                    />
                  </div>
                  <div className="d-flex pr-3 row-item">
                    <Label>Planned By:</Label>
                    <TextBox
                      className="primary-color-text text-center"
                      value="B B Kennedy"
                      width={150}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wo-information">
          <div className="section-3 tasking-tab-content">
            <h4 className="md-font-size">WO Information:</h4>
            <div className="wo-inner-block">
              <div>
                <div className="d-flex row-item">
                  <Label>WO 01 Last Modified:</Label>
                  <TextBox
                    className="primary-color-text text-center"
                    value="15/11/2020"
                  />
                </div>
              </div>
              <div>
                <div className="d-flex row-item">
                  <Label>WO Execution Time:</Label>
                  <TextBox
                    className="primary-color-text text-center"
                    value="1.5 hrs"
                  />
                </div>
              </div>
              <div>
                <div className="d-flex row-item">
                  <Label>WO Last Performed:</Label>
                  <TextBox
                    className="primary-color-text text-center"
                    value="15/11/2020"
                  />
                </div>
              </div>
              <div>
                <div className="d-flex  row-item">
                  <Label>Maintenance Yield Limit:</Label>
                  <TextBox
                    className="primary-color-text text-center"
                    value="50%"
                  />
                </div>
              </div>
              <div>
                <div className="d-flex  row-item">
                  <Label>Interval Limit:</Label>
                  <TextBox
                    className="primary-color-text text-center"
                    value="125 hrs"
                  />
                </div>
              </div>
              <div>
                <div className="d-flex  row-item">
                  <Label>Lead Time</Label>
                  <TextBox
                    className="mantis-bg text-white text-center"
                    value="Nil"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WorkOrderTabContent;
