import React from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
// import { RoleProvider } from "./context/roleContext";
// import { AssetProvider } from "./context/assetContext";

const history = createBrowserHistory();

// This is a great place to combine app providers so that we can keep App.tsx nice and clean!
export const AppProviders: React.FC = ({ children }) => {
  return (
    <Router history={history}>
      <AuthProvider>
        {/* <RoleProvider> */}
        {/* <AssetProvider>{children}</AssetProvider> */}
        {children}
        {/* </RoleProvider> */}
      </AuthProvider>
    </Router>
  );
};
