import React from "react";
import { IMAGE_PATH } from "../../utils/constant";

type Props = {
  name?: any;
  src?: string;
  style?: Record<string, any>;
  className?: string;
  onClick?: () => void;
  id?: any;
};

const Images: React.FC<Props> = ({
  name,
  src,
  style,
  className,
  id,
  onClick,
}) => {
  return !name ? (
    <div style={{ height: 100, width: 100, backgroundColor: "#dddddd" }} />
  ) : (
    <img
      src={IMAGE_PATH[name]}
      style={{ ...style, objectFit: "contain" }}
      className={className}
      onClick={onClick}
      id={id}
    />
  );
};

export default Images;
