import React from "react";
import icon from "./icon.svg";

interface Props {
  className?: string;
  handleChange?: () => void;
}

const EventIcon: React.FC<Props> = ({ className, handleChange }) => {
  return (
    <img
      alt="EventIcon"
      className={className}
      src={icon}
      onClick={handleChange}
    />
  );
};

export default EventIcon;
