export const Labels = {
  step2: {
    stepTitle: "Step 2:",
    group: "Group:",
    wing: "Wing:",
    totalAssets: "Total Assets:",
    maximumAssets: "Fleet Size Limit:",
    modelingDateRange: "Modeling Date Range:",
    fleetClassConfiguration: "Fleet/Class Configurations",
    fleetClassId: "Fleet/Class ID:",
    unitBase: "Unit:",
    unitLocation: "Unit Location:",
    maintenanceUnit: "Maintenance Unit:",
    assets: "Assets:",
    buttonAddLocation: "Add More Location",
    buttonAddAssets: "Add Assets",
    assetIdentifier: "Asset Identifier:",
    assignmentTag: "Assignment Tag",
    numberingStart: "Numbering Start",
    numberingFinish: "Numbering Finish",
    assignFleet: "Assign Fleet/SQN",
    location: "Location",
    fleetClassIdentification: "Fleet/Class Identification",
    primaryDeploymentSQN: "Squadron:",
  },
  step3: {
    stepTitle: "Step 3:",
    totalBays: " Total Bays",
    modelingDateRange: "Modeling Date Range:",
  },
  modalData: {
    unitLocation: {
      formName: "unitLocation",
      fields: [
        {
          name: "unitLocation",
          displayName: "Unit Location",
          required: true,
        },
      ],
    },
    fleet: {
      formName: "fleet",
      fields: [
        {
          name: "fleetId",
          displayName: "Fleet/Class",
          required: true,
        },
        {
          name: "aircraftType",
          displayName: "Aircraft Type",
          required: true,
        },
      ],
    },
    maintenanceUnit: {
      formName: "maintenanceUnit",
      fields: [
        {
          name: "maintenanceUnit",
          displayName: "Maintenance Unit",
          required: true,
        },
        {
          name: "description",
          displayName: "Description",
          required: false,
        },
      ],
    },
  },
  Actions: {
    updateRow: "updateRow",
    resetRow: "resetRow",
  },
  tabsTitle: {
    facility: "Facility Management",
    initial: "Initial Configuration",
  },
};
export interface CreateFleet {
  id: number;
  fleetId: string;
  wingId: number;
  aircraftType: string;
}
export interface Fleet {
  id: number;
  fleetId: string;
  totalAssets?: number;
  wingId?: number;
  aircraftType?: string;
}
export interface Asset {
  id: number;
  name?: any;
  assetNumber: string;
  isActive: boolean;
  createdDate: Date;
  lastUpdate?: Date | null;
  sequence: number;
  squadronId: number;
  squadron?: any;
  isOPDAsset: boolean;
}
export interface UnitLocation {
  id: number;
  name: string;
  isActive?: boolean;
}
export interface MaintenanceUnit {
  id: number;
  name: string;
  description: string;
  isActive?: boolean;
}

export interface Squadron {
  fleet: Fleet;
  assets: Asset[];
  id: number;
  base: string;
  unitLocation: UnitLocation;
  maintenanceUnit: MaintenanceUnit;
  assetsCount: number;
  isActive: boolean;
  plannedStartDate: Date;
  plannedEndDate: Date;
  isPrimaryDeploymentSqn: boolean;
  wingId: number;
  fleetId: number;
  fleetConfigurationId: number;
  opdCount: number;
  scenarioModelId: number;
}
export interface FleetConfiguration {
  id: number;
  primaryConfigurationSqnId: number;
  primaryConfigurationSqn: string;
  modelingStart: Date;
  modelingFinish: Date;
  assetIdentifier: string;
  assignmentTag: string;
  assignFleet: boolean;
  numberingStart: string;
  numberingFinish: string;
  isLocked: boolean;
  isInitialized: boolean;
  totalAssets: number;
  wingId: number;
  wing: Wing;
}
export interface Wing {
  serial: string;
  groupId: number;
  squadrons: Squadron[];
  createdDate: Date;
  lastUpdate?: Date;
  userId?: number;
  id: number;
  isActive: boolean;
}
export interface FleetConfigurationResponse {
  error: string;
  result: FleetConfiguration;
}
const today = new Date();
const yearEnd = new Date(`${today.getFullYear() + 1}/01/01`);
export const initWing: Wing = {
  serial: "",
  groupId: 0,
  squadrons: Array<Squadron>(),
  createdDate: new Date(),
  lastUpdate: new Date(),
  userId: 0,
  id: 0,
  isActive: false,
};

export const initMaintenanceUnit: MaintenanceUnit = {
  id: 0,
  name: "",
  isActive: false,
  description: "",
};
export const initLocation: UnitLocation = {
  id: 0,
  name: "",
  isActive: false,
};
export const initFleet: Fleet = {
  id: 0,
  fleetId: "",
  totalAssets: 0,
};
export const initAsset: Asset = {
  id: 0,
  name: null,
  assetNumber: "0",
  isActive: true,
  createdDate: new Date(),
  lastUpdate: null,
  sequence: 0,
  squadronId: 0,
  squadron: null,
  isOPDAsset: false,
};
export const initSquadron: Squadron = {
  id: 0,
  base: "",
  unitLocation: initLocation,
  maintenanceUnit: initMaintenanceUnit,
  assetsCount: 0,
  isActive: true,
  plannedStartDate: new Date(),
  plannedEndDate: yearEnd,
  isPrimaryDeploymentSqn: false,
  fleet: initFleet,
  assets: Array<Asset>(),
  wingId: 0,
  fleetId: 0,
  fleetConfigurationId: 0,
  opdCount: 0,
  scenarioModelId: 0,
};
export const initFleetConfiguration: FleetConfiguration = {
  id: 0,
  primaryConfigurationSqnId: 0,
  primaryConfigurationSqn: "",
  modelingStart: new Date(),
  modelingFinish: yearEnd,
  assetIdentifier: "",
  assignmentTag: "",
  assignFleet: false,
  numberingStart: "",
  numberingFinish: "",
  isLocked: false,
  isInitialized: false,
  totalAssets: 0,
  wingId: 0,
  wing: initWing,
};
export const InitResponse: FleetConfigurationResponse = {
  error: " ",
  result: initFleetConfiguration,
};

export interface ModalDataType {
  fields: [
    {
      name: string;
      displayName: string;
      required: boolean;
    }
  ];
  formName: string;
}
export interface PrimarySQNRequest {
  fleetConfigurationId: number;
  squadronId: number;
}
export interface FleetConfigurationRequest {
  id: number;
  primaryConfigurationSqnId: number;
  primaryConfigurationSqn: string;
  modelingStart: Date | string;
  modelingFinish: Date | string;
  assetIdentifier: string;
  assignmentTag: string;
  assignFleet: boolean;
  numberingStart: string;
  numberingFinish: string;
  isLocked: boolean;
  isInitialized: boolean;
  totalAssets: number;
  wingId: number;
}
export interface SquadronDeleteRequest {
  squadronId: number;
}
export interface FleetConfigurationDeleteRequest {
  fleetConfigurationId: number;
}
export type FieldNames<T> = {
  [K in keyof T]: K;
};
const getFieldNameMap = (srcObj: any) =>
  Object.keys(srcObj).reduce((obj: Record<string, unknown>, k) => {
    obj[k] = k;
    return obj;
  }, {});

type SquadronFieldNames = FieldNames<Squadron>;
export const SquadronFieldNames = getFieldNameMap(
  initSquadron
) as SquadronFieldNames;

//FacilityConfiguration

export interface BaysForFleet {
  id: number;
  fleetName: string | null;
  fleetId: number;
  baysPerFleet: number;
}

export interface Facility {
  id: number;
  hangarName: string | null;
  hangarLocation: number; //UnitLocation;
  maintenanceUnit: number; // MaintenanceUnit;
  lanes: number;
  baysPerLane: number;
  totalBays: number;
  baysForFleets: BaysForFleet[];
  constraints: string | null;
  startDate: string;
  endDate: string;
}
export interface FacilityDBType {
  id: number;
  hangarName: string;
  locationId: number;
  maintenanceId: number;
  lanes: number;
  bayPerLane: number;
  startDate: string;
  finishDate: string;
  constraints: string;
  fleetConfigurationId: number;
  facilityBays: Array<{
    id: number;
    bayName: string;
    bayCount: number;
    fleetId: number;
  }>;
}
export interface FacilityFleetDBType {
  id: number;
  name: string;
  fleetId: number;
}
export interface GetAllFacilityDBType {
  facilityFleets: FacilityFleetDBType[];
  facilityConfigurations: FacilityDBType[];
}
export type FacilityFleet = FacilityFleetDBType;
export interface FacilityConfigurationResponse {
  fleetConfigurationId: number;
  fleets: Fleet[];
  facilities: Facility[];
}

export const initBayForFleet: BaysForFleet = {
  id: 0,
  fleetName: null,
  fleetId: 0,
  baysPerFleet: 0,
};
export const initFacility: Facility = {
  id: 0,
  hangarName: null,
  hangarLocation: 0, //initLocation,
  maintenanceUnit: 0, // initMaintenanceUnit,
  lanes: 0,
  baysPerLane: 0,
  totalBays: 0,
  baysForFleets: Array<BaysForFleet>(),
  constraints: null,
  startDate: today.toString(),
  endDate: today.toString(),
};
export interface CreateFacilityResponse {
  statusCode: number;
  isSuccess: boolean;
}
export interface FacilityBayDBType {
  id: number | null;
  bayName: string;
  bayCount: number;
  fleetId: number;
}
export interface CreateFacilityDbType {
  hangarName: string | null;
  locationId: number;
  maintenanceId: number;
  lanes: number;
  bayPerLine: number;
  startDate: string | null;
  finishDate: string | null;
  constraints: string | null;
  fleetConfigurationId: number;
  facilityBays: FacilityBayDBType[];
}
export interface UpdateFacilityDbType {
  id: number;
  hangarName: string | null;
  locationId: number;
  maintenanceId: number;
  lanes: number;
  bayPerLane: number;
  startDate: string | null;
  finishDate: string | null;
  constraints: string | null;
  fleetConfigurationId: number;
  facilityBays: FacilityBayDBType[];
}

export interface CreateAssetResponse {
  assetId: number;
  isSuccess: boolean;
  statusCode: number;
}
export interface TableItem {
  id: number | any;
  name: string;
  description?: string;
  fleetId?: string;
  aircraftType?: string;
}
export interface FormObject {
  fields:
    | {
        fieldName: string;
        displayName: string;
        required: boolean;
      }[]
    | null;
  formDisplayName: string;
  formName: string;
}
export const initFormObject: FormObject = {
  formName: "",
  formDisplayName: "",
  fields: null,
};
export const initGetAllFacilityDBType: GetAllFacilityDBType = {
  facilityFleets: Array<FacilityFleetDBType>(),
  facilityConfigurations: Array<FacilityDBType>(),
};
export const initFacilityConfiguration: FacilityConfigurationResponse = {
  fleetConfigurationId: 0,
  fleets: Array<Fleet>(),
  facilities: Array<Facility>(),
};
type FacilityFieldNames = FieldNames<Facility>;
export const FacilityFieldNames = getFieldNameMap(
  initFacility
) as FacilityFieldNames;
type AssetFieldNames = FieldNames<Asset>;
export const AssetFieldNames = getFieldNameMap(initAsset) as AssetFieldNames;
type FleetFieldNames = FieldNames<Fleet>;
export const FleetFieldNames = getFieldNameMap(initFleet) as FleetFieldNames;
type MaintenanceUnitFieldNames = FieldNames<MaintenanceUnit>;
export const MaintenanceUnitFieldNames = getFieldNameMap(
  initMaintenanceUnit
) as MaintenanceUnitFieldNames;
type UnitLocationFieldNames = FieldNames<UnitLocation>;
export const UnitLocationFieldNames = getFieldNameMap(
  initLocation
) as UnitLocationFieldNames;

export interface DeleteHoliday {
  holidayId: number;
  isMaintenance: boolean;
  isOperational: boolean;
}
