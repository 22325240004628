import React, { useEffect } from "react";
import { Labels, MaintenanceElement } from "../models/maintenanceModel";
import * as d3 from "d3";

interface Props {
  data: Array<MaintenanceElement>;
  min?: number;
  max?: number;
  ticks?: number;
}
let width = 600;
const MaintenanceSchedule: React.FC<Props> = ({
  data,
  min = 200,
  max = 5600,
  ticks = 28,
}) => {
  const headerHeight = 38;
  const itemHeight = 40;
  const dimension = {
    height: itemHeight * data.length + headerHeight,
    width: 0,
    boundedHeight: 0,
    boundedWidth: 0,
    margin: {
      top: headerHeight,
      left: 20,
      bottom: 0,
      right: 20,
    },
  };
  const drawChart = () => {
    const clientWidth =
      document.getElementById("chartWrapper")?.clientWidth || 600;
    if (width === 600) {
      width = clientWidth;
    }
    dimension.width = width;
    dimension.boundedHeight =
      dimension.height - dimension.margin.top - dimension.margin.bottom;
    dimension.boundedWidth =
      dimension.width - dimension.margin.left - dimension.margin.right;
    const dataSet: Array<MaintenanceElement> = data.map((a, idx: number) => {
      const x = { ...a };
      x.id = data.length - idx;
      return x;
    });
    const circleSize = 15;
    const domainMax = max;
    const domainMin = min;
    d3.selectAll("#chartWrapper > *").remove();
    const svg = d3
      .selectAll("#chartWrapper")
      .append("svg")
      .attr("width", dimension.width)
      .attr("height", dimension.height);
    const bounded = svg
      .append("g")
      .style(
        "transform",
        `translate(${dimension.margin.left}px, ${dimension.margin.top}px)`
      );
    const xScale = d3
      .scaleLinear()
      .domain([domainMin, domainMax])
      .range([0, dimension.boundedWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, data.length])
      .range([dimension.boundedHeight, 0]);

    const xAxis = d3.axisTop(xScale).ticks(ticks);
    bounded.append("g").attr("class", "x-axis").call(xAxis);
    d3.selectAll(".x-axis .domain").remove();
    d3.selectAll(".x-axis .tick line").remove();
    const lineGenerator = d3.line();

    dataSet.forEach((item) => {
      if (item.intervalHrs > 0) {
        const interval = parseInt(item.intervalHrs.toString());
        if (interval !== 0 && Number.isInteger(interval)) {
          const line: Array<[number, number]> = [
            [0, yScale(item.id) + 3 + circleSize / 2],
            [dimension.boundedWidth, yScale(item.id) + 3 + circleSize / 2],
          ];
          bounded
            .append("path")
            .attr("d", lineGenerator(line) as string)
            .attr("stroke-dasharray", "1,2")

            .attr("fill", "none")
            .attr("stroke", "blue");

          for (let i = interval; i <= domainMax;) {
            bounded
              .append("circle")
              .attr("cx", xScale(i))
              .attr("cy", yScale(item.id) + 3 + circleSize / 2)
              .attr("r", circleSize)
              .attr("fill", item.colorCode);
            bounded
              .append("text")
              .attr("class", "route__schedule--circle--text")
              .attr("x", xScale(i) - 2 - circleSize / 2)
              .attr("y", yScale(item.id) + 6 + circleSize / 2)
              .text(
                item.maintenanceId.length <= 3
                  ? item.maintenanceId
                  : item.maintenanceId.substr(0, 3).padEnd(4, ".")
              );
            i += interval;
          }
        }
      }
    });
  };
  useEffect(drawChart, [data, max]);
  return (

    <div className="route__schedule">
      <div className="route__schedule--left">
        {data && data.length > 0 &&
          <div className="route__schedule--route--header">
            <label>{Labels.ScheduleActivityID}</label>
            <label>{Labels.ScheduleIntervalHrs}</label>
            <label>{Labels.ScheduleEveryInterval}</label>
          </div>}
        {data
          .filter((a) => a.intervalHrs > 0)
          .map((item, idx: number) => (
            <div className="route__schedule--route--body" key={`mch${idx}`}>
              <div style={{ backgroundColor: item.colorCode }}>
                {item.maintenanceId}
              </div>
              <div>{item.intervalHrs}</div>
              <div>Y</div>
            </div>
          ))}
      </div>
      <div className="route__schedule--right">
        <div id="chartWrapper"></div>
      </div>
    </div>
  );
};

export default MaintenanceSchedule;
