import React from "react";
import { Header } from "../../components/header/default/Header";
import { Footer } from "../../components";
import { useAuth } from "../../context/authContext";

const Layout: React.FC = ({ children }) => {
  const { user } = useAuth();
  return (
    <div>
      <Header></Header>
      <div className="container-fluid">{children}</div>
      {user && <Footer />}
    </div>
  );
};

export default Layout;
