import { API } from "../../../api";
import { ServerResponse } from "../../../utils";

const getCMCLeaf = async (fleetId: string): Promise<any> => {
  try {
    const response = await API.get<ServerResponse<any>>(
      `/api/v1/EquipmentObject/${fleetId}`
    );
    if (response.data.error !== "") {
      return null;
    }
    return response.data.result;
  } catch (e) {
    return null;
  }
};

const CMCService = {
  getCMCLeaf: getCMCLeaf,
};
export { CMCService };
