import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MissionPlanningSliceType {
  plannedStartTime: Date;
  plannedEndTime: Date;
}
const initial: MissionPlanningSliceType = {
  plannedStartTime: new Date(),
  plannedEndTime: new Date(),
};

const MissionPlanningSlice = createSlice({
  name: "MissionPlanning",
  initialState: initial,
  reducers: {
    setStartTime: (state, action: PayloadAction<Date>) => {
      state.plannedStartTime = action.payload;
    },
    setEndTime: (state, action: PayloadAction<Date>) => {
      state.plannedEndTime = action.payload;
    },
  },
});

export default MissionPlanningSlice;
