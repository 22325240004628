export const subStepsLabels: { [key: number]: string } = {
  1.1: "Build Graphic Representation",
  1.2: "Build Fleet Structure",
  1.3: "Facility Configuration",
  1.4: "Build Initial Availability",
  1.5: "Fleet Baseline Calendar",
  1.6: "Fleet Event Calendar",
  1.7: "SQN Baseline + Event Calendar",
  1.8: "Fleet Event Summary",
  2.1: "Acceptance Delivery Schedule",
  2.2: "A/C Event Assignment",
  2.3: "A/C Event Summary",
  2.4: "Event RoE Assigned to A/C",
  2.5: "Acceptance/IOC Configuration",
  2.6: "A/C Baseline RoE Summary",
  2.7: "Fleet Strength",
  3.1: " Fleet Operational Status",
  3.2: "Tasking Profiles",
  4.1: "A/C System Configuration",
  4.2: "EO Configuration",
  4.3: "Operating Configuration",
  5.1: " Airframe Limits",
  5.2: "Fatigue Life Limits",
  5.3: "A/C Engine +Prop Limits",
  6.1: "Maintenance Routine Elements",
  6.2: "Maintenance Routine",
  6.3: "Maintenance Summary",
  6.4: "Maintenance Activity Hierarchy",
};

export const mainStepsLabels: { [key: number]: string } = {
  1: " Build Fleet/SQN Objectives + Schedules",
  2: " Initial Operating Capability",
  3: "Operation Profiles",
  4: "A/C Configuration",
  5: "A/C Operating Limits",
};
