import React from "react";
import { Label } from "@austaltech/components";
import "./uuc.scss";
interface Props {
  label: string;
  tagName: string;
  tagColor: string;
  includeLabel: boolean;
}
const CategoryRow: React.FC<Props> = ({
  label,
  tagName,
  tagColor,
  includeLabel,
}) => {
  return includeLabel ? (
    <div className="uuc__Box--row">
      <Label className="uuc__Box__Row--label">{label}</Label>
      <div className="uuc__Box__Row--tag" style={{ background: tagColor }}>
        {tagName}
      </div>
    </div>
  ) : (
    <div className="uuc__Box--row">
      <div className="uuc__Box__Row--tag" style={{ background: tagColor }}>
        {tagName}
      </div>
    </div>
  );
};

export default CategoryRow;
