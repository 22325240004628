export const addToStorage = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

export const getFromStorage = (key: string) => {
  return localStorage.getItem(key) || null;
};

export const removeFromStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const clearStorage = () => {
  localStorage.clear();
};

export const removeMultipleItemsFromStorage = (keysToRemove: Array<string>) => {
  keysToRemove.forEach((k) => localStorage.removeItem(k));
};
