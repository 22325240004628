import React from "react";
import { useAuth } from "../context/authContext";

/**
 * An example "login" component which demonstrates usage of React Contexts for state management.
 */

export const UserInfo: React.FC = () => {
  const { user, login, logout } = useAuth();

  if (user !== undefined) {
    return (
      <button className="btn btn__signin" onClick={logout}>
        Sign Out
      </button>
    );
  }

  return (
    <button className="btn btn__signin" onClick={login}>
      Sign In
    </button>
  );
};
