import React, { useEffect, useState } from "react";
import { createCtx } from "./createCtx";
import * as msal from "@azure/msal-browser";
import {
  addToStorage,
  getFromStorage,
  removeFromStorage,
} from "../utils/localStorage";
import {
  MSAL_USERNAME,
  SessionStorageField,
  USER_ROLES,
} from "../utils/constant";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux";
import authSlice from "../views/home/redux/authSlice";

export interface Credentials {
  email: string;
  name: string;
  roles?: Array<string>;
}

export interface AuthContextProps {
  /** Function to start logging in */
  login: () => void;
  /** Funtion to log out all users */
  logout: () => void;

  /** whether an authentication request is currently pending */
  loading: boolean;

  /** The currently logged in email address/name */
  user?: Credentials;
  /** The currently selected account */
  currentAccount?: msal.AccountInfo;
  /** The currently selected account of all logged in accounts */
  accountId: string;
  /**  All currently logged in accounts */
  accounts: Array<msal.AccountInfo>;
  setAccountId: React.Dispatch<React.SetStateAction<string>>;
}

// Configuration for MSAL
const msalConfig: msal.Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_MSAL_CLIENT_ID || "",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_MSAL_DIRECTORY_TENNANT_ID}`,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const [useAuth, AuthContextProvider] = createCtx<AuthContextProps>();

/** An example Authentication context provider.
 *
 * React Contexts can be used to decouple complex state and business logic from UI components.
 */
const AuthProvider: React.FC = ({ children }) => {
  const dispatch: AppDispatch = useDispatch();
  const [user, setUser] = useState<Credentials>();
  const [accountId, setAccountId] = useState<string>("");
  const [accounts, setAccounts] = useState<Array<msal.AccountInfo>>([]);
  const [loading, setLoading] = useState(true);

  const USER = SessionStorageField.user;
  /**
   * On first mount, check whether we have an unexpired token and immediately log the user in if one is found.
   * In future, if session-based authentication is used through the .NET Core backend this could be removed.
   * This method causes the application to make a request to Azure AD whenever the tab is refreshed.
   * An alternative to this is setting the `cacheLocation` to localStorage which will store the ID token in the browser's localStorage, sharing the SSO authentication between tabs,
   * or removing this `useEffect` function which will cause the user to appear logged out when opening a new tab with the application (until they press the "login" button).
   * see [this documentation page](https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso)
   * reference: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
   */
  useEffect(() => {
    async function tryAndAquireTokenSilently(): Promise<void> {
      const msalUsername = getFromStorage(MSAL_USERNAME) || undefined;
      const sessionUser = getFromStorage(USER);
      if (sessionUser) {
        setUser(JSON.parse(sessionUser));
        setLoading(false);
        return;
      }
      const request = {
        scopes: ["profile", "openid", "email", "User.Read"],
        loginHint: msalUsername,
      };
      try {
        await msalInstance.ssoSilent(request);
        const myAccount = msalInstance.getAllAccounts();
        const claims: any = myAccount[0].idTokenClaims
          ? myAccount[0].idTokenClaims
          : [];
        setAccounts(msalInstance.getAllAccounts());
        const userJson = {
          email: myAccount[0].username || "",
          name: myAccount[0].name || "",
          roles: claims.roles || "",
        };
        addToStorage(USER, JSON.stringify(userJson));
        dispatch(authSlice.actions.setAuthUser(userJson));
        setUser(userJson);
      } catch (e) {
        removeFromStorage(USER);
      } finally {
        setLoading(false);
      }
    }
    tryAndAquireTokenSilently();
  }, []);

  useEffect(() => {
    if (accounts.length === 1) {
      const claims: any = accounts[0].idTokenClaims
        ? accounts[0].idTokenClaims
        : [];

      setUser({
        email: accounts[0].username || "",
        name: accounts[0].name || "",
        roles: claims.roles || "",
      });
    }
  }, [accounts]);

  // Callback for login prompt success
  const handleResponse = (resp: msal.AuthenticationResult): void => {
    if (resp != null) {
      const account = resp.account;
      if (account) {
        setAccountId(account.homeAccountId);
        addToStorage(MSAL_USERNAME, account.username);
        const claims: any = account.idTokenClaims ? account.idTokenClaims : [];
        const user = {
          email: account.username || "",
          name: account.name || "",
          roles: claims.roles || "",
        };
        dispatch(authSlice.actions.setAuthUser(user));
        setUser(user);
        addToStorage(USER, JSON.stringify(user));
        setLoading(false);
      }
    }
  };

  // Used to launch the login flow when called (by a login button for example)
  const login = async () => {
    setLoading(true);
    try {
      const authPop = await msalInstance.loginPopup();
      handleResponse(authPop);
    } catch (e) {
      setLoading(false);
    }
  };

  // Will log the user out of the Azure AD account
  const logout = async () => {
    setUser(undefined);
    removeFromStorage(USER);
    const logoutRequest: msal.EndSessionRequest = {
      account: msalInstance.getAccountByHomeId(accountId) || undefined,
    };
    await msalInstance.logout(logoutRequest);
  };

  return (
    <AuthContextProvider
      value={{
        login,
        logout,
        user,
        accountId,
        accounts,
        setAccountId,
        loading,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};

/**
 *  A mock AuthProvider context to be used in testing.
 *
 *  If required for testing, this component could be improved
 *  by allowing test user information to be provided as props.
 */
export const TestAuthProvider: React.FC = ({ children }) => {
  return (
    <AuthContextProvider
      value={{
        login: () => undefined,
        logout: () => undefined,
        user: {
          email: "test-user@austal.com",
          name: "Test User",
          roles: [USER_ROLES.ACCESS_FLEET_SCHEDULE],
        },
        accountId: "1234",
        accounts: [
          {
            environment: "",
            homeAccountId: "",
            localAccountId: "",
            tenantId: "",
            username: "",
            name: "",
          },
        ],
        setAccountId: () => undefined,
        loading: false,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};

export { useAuth, AuthProvider };
