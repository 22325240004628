import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BaseLineModel {
  id: null | number;
  name: null | string;
}

export interface FleetSchedule {
  errors: Array<string>;
  lastVersionPrimaryKey: null | number;
  baselineModel: BaseLineModel;
  isModelChange: boolean | null;
  isOptimized: boolean | null;
  baselineModels: Array<BaseLineModel> | null;
  initialModel: BaseLineModel;
}
const initial: FleetSchedule = {
  errors: Array<string>(),
  lastVersionPrimaryKey: null,
  baselineModel: {
    id: null,
    name: null,
  },
  initialModel: {
    id: null,
    name: null,
  },
  isModelChange: false,
  isOptimized: false,
  baselineModels: [],
};

const fleetScheduleSlice = createSlice({
  name: "fleetSchedule",
  initialState: initial,
  reducers: {
    setLastVersionPrimaryKey: (state, action: PayloadAction<number | null>) => {
      state.lastVersionPrimaryKey = action.payload;
    },
    setBaseLineModel: (state, action: PayloadAction<BaseLineModel>) => {
      state.baselineModel = action.payload;
    },
    setInitialModel: (state, action: PayloadAction<BaseLineModel>) => {
      state.initialModel = action.payload;
    },
    setModelChange: (state, action: PayloadAction<boolean | null>) => {
      state.isModelChange = action.payload;
    },
    setModelOptimize: (state, action: PayloadAction<boolean | null>) => {
      state.isOptimized = action.payload;
    },
    setBaseLineModels: (
      state,
      action: PayloadAction<Array<BaseLineModel> | null>
    ) => {
      state.baselineModels = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.errors.push(action.payload);
    },
  },
});

export default fleetScheduleSlice;
