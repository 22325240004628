import { createSlice } from "@reduxjs/toolkit";

export interface App {
  activeProgress: { error: string; value: boolean };
}
const initial: App = {
  activeProgress: {
    error: "",
    value: false,
  },
};

const appSlice = createSlice({
  name: "app",
  initialState: initial,
  reducers: {
    requestData: (state, action) => {
      state.activeProgress = action.payload;
    },
  },
});

export default appSlice;
