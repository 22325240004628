import React from "react";
import "./label.scss";
import { CheckOutlined } from "@ant-design/icons";
type Props = {
  name: string;
  width?: number;
  height?: number;
  textStyle?: Record<string, any>;
  style?: Record<string, any>;
  borderWidth?: number;
  borderColor?: string;
  backgroundColor?: string;
  onClick?: () => void;
  className?: string;
  checkMark?: boolean;
};

const LabelBox: React.FC<Props> = ({
  name,
  width,
  textStyle,
  style,
  backgroundColor,
  height,
  onClick,
  className,
  checkMark,
}) => {
  return (
    <div
      className={`${"box-container"} ${className || ""}`}
      style={{
        position: "relative",
        width: width || 100,
        height: height || 27,
        cursor: onClick ? "pointer" : undefined,
        userSelect: onClick ? "none" : undefined,
        backgroundColor: backgroundColor,
        marginLeft: "auto",
        ...style,
      }}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <p
        className={"box-text"}
        style={{
          ...textStyle,
        }}
      >
        {name || "- -"}
      </p>
      {checkMark && (
        <div className="check-mark">
          <CheckOutlined />
        </div>
      )}
    </div>
  );
};

export default LabelBox;
