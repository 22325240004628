import React from "react";
import { Popover, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/authContext";
import { WHITE, USER_ROLES } from "../../../utils/constant";
import dotsIcon from "../../../assets/images/greyDots.png";
import useRoleCheck from "../../../hooks/userRoleCheck";
import { ConfigurationId } from "../../../utils/helper";
import { pageRoutes, PageHeaders, Tile } from "./pageRoutes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootSliceType } from "../../../redux";
import { ScenarioModel } from "../../../features/InitialBuildRegistration/redux/scenarioModelSlice";
import { tabNavigationSlice } from "../../../features/navigation/redux";
import "../default/header.scss";
import "./innerHeader.scss";
interface RouteProps {
  id: number;
  pageName: string;
  url: string;
  color: string;
}

const customPopoverStyle = () => (
  <style>
    {`.ant-popover-inner-content {
         padding: 5px; border: 1px solid #bfbfbf;
          border-radius: 3px; } .ant-popover-inner{
            box-shadow:unset
          }
          .ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow,
          .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
          display:none
          }
          .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow{
          display:none

          }
      `}
  </style>
);

export const PageRouting: React.FC = () => {
  const { push } = useHistory();
  const { user } = useAuth();
  const dispatch: AppDispatch = useDispatch();
  const userRole = user && user.roles ? true : false;
  const fleetScheduleUser = useRoleCheck(USER_ROLES.ACCESS_FLEET_SCHEDULE);
  const globalUer = useRoleCheck(USER_ROLES.ACCESS_GLOBAL_USER);
  const configId = ConfigurationId() as number;
  const { selectModelData } = useSelector<RootSliceType, ScenarioModel>(
    (state) => state.modelReducer
  );

  const routerHandler = (item: RouteProps) => {
    dispatch(tabNavigationSlice.actions.setLoadingTabs(null));
    if (selectModelData.modelId) push(`${item.url}/${configId}`);
    else {
      if (item.pageName == Tile.modeling) {
        push(`${item.url}/${configId}`);
      } else {
        return;
      }
    }
  };

  const iconFunctionalityHandler = () => {
    if (!userRole) {
      return "";
    }
    if (
      (fleetScheduleUser.hasUserRole && globalUer.hasUserRole) ||
      globalUer.hasUserRole
    ) {
      return "click";
    }
    if (fleetScheduleUser.hasUserRole) {
      return "";
    }
  };

  const iconClickHandler = () => {
    if (!userRole) {
      return "not-allowed";
    }
    if (
      (fleetScheduleUser.hasUserRole && globalUer.hasUserRole) ||
      globalUer.hasUserRole
    ) {
      return "pointer";
    }
    if (fleetScheduleUser.hasUserRole) {
      return "not-allowed";
    }
  };

  const pageRouteContent = (
    <div className="group-menu-container">
      <div className={"group-menu-grid-header"}>
        {pageRoutes.map((item: RouteProps) => (
          <div className={`inner-view `} key={`fgroups${item.id}`}>
            <div className="inner-grid" onClick={() => routerHandler(item)}>
              <Tooltip
                placement="bottomRight"
                color={item.color}
                title={
                  <div className="tooltip-section">
                    {PageHeaders[item.pageName]}
                  </div>
                }
              >
                <div className="image-wrapper">
                  <div
                    style={{
                      background: item.color,
                      cursor: selectModelData.modelId
                        ? "pointer"
                        : item.pageName == Tile.modeling
                        ? "pointer"
                        : "not-allowed",
                    }}
                    className="dot-single-bx"
                  >
                    <label className="dot-text">{item.pageName}</label>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        ))}
        {customPopoverStyle()}
      </div>
    </div>
  );

  return (
    <>
      <Popover
        placement={"bottomRight"}
        content={pageRouteContent}
        trigger={iconFunctionalityHandler()}
        overlayStyle={{
          background: WHITE,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div className={`button-box  "button-box-disabled`}>
          <img
            src={dotsIcon}
            alt="dotsIcon"
            style={{
              height: "30px",
              cursor: iconClickHandler(),
            }}
          />
        </div>
      </Popover>
    </>
  );
};
