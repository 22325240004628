import { createSlice } from "@reduxjs/toolkit";
import { MaintenanceSliceInitial } from "../models/maintenanceSliceType";
import {
  fetchIliasAssets,
  selectAirCraft,
  fetchAssetsWorkOrder,
  selectWorkOrder,
} from "../thunk/maintenanceIliasThunk";

export const maintenanceSlice = createSlice({
  name: "maintenanceSlice",
  initialState: MaintenanceSliceInitial,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(fetchIliasAssets.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        const body = action.payload.data.result.message.body;
        state.squadrons = [
          {
            type: "",
            items: body.aircraft.map((a) => ({
              text: a.taiL_NUMBER,
              items: [],
              key: a.taiL_NUMBER,
              type: "squadron",
              selected: false,
            })),
            key: "",
            text: "F-35 SQN",
          },
        ];
      }
    });
    build.addCase(fetchAssetsWorkOrder.fulfilled, (state, action) => {
      const body = action.payload.result.message.body as any;
      if (body) {
        state.workOrders = body.workorder;
      }
    });
    build.addCase(selectAirCraft, (state, action) => {
      state.squadrons[0].items.forEach((item) => {
        if (item.key === action.payload) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
    });
    build.addCase(selectWorkOrder, (state, action) => {
      state.workOrder = null;
      const workOrder = state.workOrders.find(
        (a) => a.workorderid == action.payload
      );
      if (workOrder) {
        state.workOrder = workOrder;
      }
    });
  },
});
