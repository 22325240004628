import { ServerResponse } from "../../../utils";
import { API } from "../../../api";
import { FACILITY } from "../../../api/endpoint";
import {
  CreateFacilityResponse,
  CreateFacilityDbType,
  UpdateFacilityDbType,
  GetAllFacilityDBType,
  initGetAllFacilityDBType,
} from "../fleetForms/model";

export const getFacility = async (
  fleetConfigId: number
): Promise<GetAllFacilityDBType> => {
  try {
    const response = await API.get<ServerResponse<GetAllFacilityDBType>>(
      `${FACILITY}/getbyfleet/${fleetConfigId}`
    );
    return response.data.result;
  } catch (e) {
    return initGetAllFacilityDBType;
  }
};
export const saveFacility = async (
  facilityRequest: CreateFacilityDbType
): Promise<boolean> => {
/*eslint no-undef: 0*/
  return new Promise((resolve) => {
    API.post<ServerResponse<CreateFacilityResponse>>(
      `${FACILITY}/create`,
      facilityRequest
    ).then(response => {
      if (response && !response.data.error) {
        resolve(response.data.result.isSuccess);
      } else {
        resolve(false);
      }
    });
  })
};
export const updateFacility = async (
  facilityRequest: UpdateFacilityDbType
): Promise<boolean> => {
  try {
    const response = await API.put<ServerResponse<CreateFacilityResponse>>(
      `${FACILITY}/update`,
      facilityRequest
    );
    if (response && !response.data.error) {
      return response.data.result.isSuccess;
    }
    return false;
  } catch (e) {
    return false;
  }
};
export const deleteFacility = async (
  fleetConfigId: number
): Promise<boolean> => {
  try {
    const response = await API.delete<ServerResponse<CreateFacilityResponse>>(
      `${FACILITY}/delete`,
      { data: { facilityId: fleetConfigId } }
    );
    if (response && !response.data.error) {
      return response.data.result.isSuccess;
    }
    return false;
  } catch (e) {
    return false;
  }
};
