import React, { CSSProperties } from "react";
import fcmgt from "./fcmgt.png";

interface Props {
  className?: string;
  style?: CSSProperties;
}

const FcmgtImage: React.FC<Props> = ({ className, style }) => {
  return <img alt="fcm" className={className} style={style} src={fcmgt} />;
};

export default FcmgtImage;
