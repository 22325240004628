import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { API } from "../../../api";
import { EliasResponse, ServerResponse } from "../../../utils";
import {
  EliasAssetResponse,
  WorkOrderResponse,
} from "../models/maintenanceModel";

export const fetchIliasAssets = createAsyncThunk(
  "ilias/fetchAssets",
  async () => {
    return await API.get<ServerResponse<EliasResponse<EliasAssetResponse>>>(
      "/api/v1/ILias/GetAllAssets"
    );
  }
);

export const fetchAssetsWorkOrder = createAsyncThunk(
  "ilias/fetchAssetsAirCraft",
  async (airCraftId: string) => {
    const response = await API.get<
      ServerResponse<EliasResponse<WorkOrderResponse>>
    >(`/api/v1/ILias/GetWorkOrder/${airCraftId},`);
    return response.data;
  }
);

export const selectAirCraft = createAction<string>("maintenance/selectAC");
export const selectWorkOrder = createAction<string>(
  "maintenance/selectWorkOrder"
);
