import React, { useEffect } from "react";
import * as d3 from "d3";
import moment from "moment";
import {
  maintenanceList,
  maintenceOORList,
  OperationList,
  ReportLabels,
  UUCCategoryColor,
} from "../../../models";
import CategoryContainer from "./UUCCategoryContainer";
import UUCGraph from "./UUCGraph";
import "./uuc.scss";
interface Props {
  startDate: Date;
  endDate: Date;
}

const UUCChart: React.FC<Props> = ({ startDate, endDate }) => {
  return (
    <div className="uuc__fmc__Row">
      <div className="uuc__fmc__Left--menu mb-35">
        <CategoryContainer title="Operations - IR" items={OperationList()} />
        <CategoryContainer title="Maintenance - IR" items={maintenanceList()} />
        <CategoryContainer
          title="Maintenance - OOR"
          items={maintenceOORList()}
        />
      </div>
      <div className="uuc__fmc__main">
        <UUCGraph startDate={startDate} endDate={endDate} />
      </div>
    </div>
  );
};
export default UUCChart;
