import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TabProps {
  mainTab: number;
  innerTab: number;
}

export interface NavigateTabs {
  selectLoadingTabs: TabProps | null;
}
const initial: NavigateTabs = {
  selectLoadingTabs: null,
};

 const tabNavigationSlice = createSlice({
  name: "tabNavigation",
  initialState: initial,
  reducers: {
    setLoadingTabs: (state, action: PayloadAction<TabProps | null>) => {
      state.selectLoadingTabs = action.payload;
    },
  },
});

export default tabNavigationSlice