//enum
export enum PlaningChartType {
  Fatigue = "fatigue",
  DayView = "dayView",
  WeekView = "weekView",
  StripView = "stripView",
  fatigueView = "fatigueView",
}

export interface PlanningProps {
  curriculum: Curriculum;
  missionCategory: MissionCategory;
  squadron: Squadron;
}

export enum StatusComponentType {
  TEXT = "TEXT",
  SQUARE = "SQUARE",
  CIRCLE = "CIRCLE",
  HEXOGON = "HEXOGON",
  IMAGE = "IMAGE",
  TITLE = "TITLE",
  EMPTY = "EMPTY",
  DOT = "DOT",
  FULL_SQUARE = "FULL_SQUARE",
  ARROWLEFT = "ARROWLEFT",
  ARROWDOWN = "ARROWDOWN",
  FULL_SQUARE_EXTRA = "FULL_SQUARE_EXTRA",
}

export enum RowType {
  TITLE = "TITLE",
  UNAVAILABLE = "UNAVAILABLE",
  AVAILABLE = "AVAILABLE",
}

//data types

export interface AircraftsDetails {
  title: string;
  chartData?: any;
  className: string;
  chartDate?: Date;
  dataSource?: any;
  assignChange?: any;
}

export interface StatusComponent {
  type: StatusComponentType;
  text: string;
  bgcolor: string;
  textColor: string;
  enableBorder?: boolean;
}
export interface Curriculum {
  id: string;
  name: string;
  isReoccurring: boolean;
  numberOfAirCraftRequired: number;
  flightPlanner: string;
  Squadron: string;
  flightBookingNumber: string;
}

export interface EOConfig {
  name: string;
  softwareDataLoad?: SoftwareDataLoad;
  type: string;
  airCraftImage: string;
}
export interface AirSystemConfiguration {
  minimum: string;
  airCraftImage: string;
}
export interface SoftwareDataLoad {
  imageCode: string;
  associatedSDL: string;
}
export interface OperationalStatusCategory {
  name: string;
  description: string;
  colorCode: string;
}
export interface MissionCategory {
  MissionClassification: string;
  MissionId: string;
  MissionType: string;
  IROperationalStatusCategory: OperationalStatusCategory;
  EOConfigurationRequirement: string;
  OperationalSystemConfiguration: string;
  StandardFlightTimeAllocation: string;
  FatigueLifeAccrualRate: number;
  FlightType: string;
  Airspace: string;
  Duration?: number;
  PaneId?: string;
  AssetTypeId?: string;
  EOConfiguration: EOConfig;
  AirSystemConfiguration: AirSystemConfiguration;
  SoftwareDataLoad: SoftwareDataLoad;
  NightFlying: boolean;
}

export interface AircraftsStatusComponent {
  rowType: RowType;
  id: string;
  data: StatusComponent[];
}

export interface FlightSector {
  sectorName: string;
  departureTime: string;
  arrivalTime: string;
  flightTime: string;
  assignFlight: string;
  pointA: {
    arrival: string;
    airFieldName: string;
    departure: string;
    support: boolean;
    supportAvailability: {
      airStarterUnit: boolean;
      refuellingTanker: boolean;
    };
  };
  line: {
    name: string;
    description: string;
    approved: boolean;
  };
  pointB: {
    arrival: string;
    airFieldName: string;
    departure: string;
    support: boolean;
    supportAvailability: {
      airStarterUnit: boolean;
      refuellingTanker: boolean;
    };
  };
}

export interface FlightSector2 {
  isSelected?: boolean;
  airFieldId?: string;
  plannedStartTime?: Date;
  plannedEndTime?: Date;
  locationFrom?: string;
  locationTo?: string;
  sectorName: string;
  departureTime: Date;
  arrivalTime: Date;
  flightTime: string;
  assignFlight: string;
  pointA: {
    arrival?: Date;
    airFieldName: string;
    locId?: string;
    departure?: Date;
    eventTime?: Date;
    support: boolean;
    supportAvailability: {
      airStarterUnit: boolean;
      refuellingTanker: boolean;
    };
  };
  line: {
    name: string;
    description: string;
    approved: boolean;
  };
  pointB: {
    arrival?: Date;
    airFieldName: string;
    locId?: string;
    departure?: Date;
    eventTime?: Date;
    support: boolean;
    supportAvailability: {
      airStarterUnit: boolean;
      refuellingTanker: boolean;
    };
  };
}
export interface Strip {
  plannedEndTime: Date;
  plannedStartTime: Date;
  locationFrom: any;
  locationTo: any;
  missionCategory: string;
  missionType: string;
  missionID: string;
  missionPlanID: string;
  squadronCode: string;
  squadronName: string;
}

export enum StripGroup {
  STRIP = "Strip",
  SORTIE_GROUP = "Sortie Group",
  FORMATION_GROUP = "Formation Group",
}

export interface Squadron {
  name: string;
  flight: string;
  strip?: Strip;
}

export interface Dayview {
  category: string;
  acNo: string;
  startTime: string;
  endTime: string;
  eventName: string;
  eventDescription: string;
  color: string;
  id: number;
}
export enum UUCCategoryColor {
  OPFMC = "#7195B5",
  OPPMC = "#66CCFC",
  OPAVA = "#99CCFD",
  OPFCF = "#CCCCFD",
  OPVFR = "#99CCFD",
  OPRELOC = "#CBECFE",

  MAPMIDEPOT = "#FE6665",
  MAPMIFIELD = "#FE661F",
  MAIRMOD = "#FF9D28",
  MAIRSR = "#33CB33",

  MAOORNMCM = "#CC9825",
  MAOORNMCS = "#3366CC",
  MAOORSRWRK = "#9966F9",
  MAOORUMOD = "#0B99FA",
}

export interface UUCCategory {
  label: string;
  tagName: string;
  tagColor: UUCCategoryColor;
}
export const OperationList = (): Array<UUCCategory> => {
  const list: Array<UUCCategory> = [
    {
      label: "Fully Mission Capable",
      tagName: "FMC",
      tagColor: UUCCategoryColor.OPFMC,
    },
    {
      label: "Partially Mission Capable",
      tagName: "PMC",
      tagColor: UUCCategoryColor.OPPMC,
    },
    {
      label: "Available-Ready Basic A/C",
      tagName: "AVA",
      tagColor: UUCCategoryColor.OPAVA,
    },
    {
      label: "Functional Check Fligh",
      tagName: "FCF",
      tagColor: UUCCategoryColor.OPFCF,
    },
    {
      label: "Visual Flight Rules Only",
      tagName: "VFR",
      tagColor: UUCCategoryColor.OPVFR,
    },
    {
      label: "Reassignment or Relocation",
      tagName: "RELOC",
      tagColor: UUCCategoryColor.OPRELOC,
    },
  ];
  return list;
};
export const maintenanceList = (): Array<UUCCategory> => {
  const list: Array<UUCCategory> = [
    {
      label: "Planned Maintenance Interval",
      tagName: "PMI Depot",
      tagColor: UUCCategoryColor.MAPMIDEPOT,
    },
    {
      label: "Planned Maintenance Interval",
      tagName: "PMI Field",
      tagColor: UUCCategoryColor.MAPMIFIELD,
    },
    {
      label: "In Reporting Modification",
      tagName: "IRMOD",
      tagColor: UUCCategoryColor.MAIRMOD,
    },
    {
      label: "In Reporting in Service Repair",
      tagName: "IRISR",
      tagColor: UUCCategoryColor.MAIRSR,
    },
  ];
  return list;
};
export const maintenceOORList = (): Array<UUCCategory> => {
  const list: Array<UUCCategory> = [
    {
      label: "Non Mission Capable-Maint",
      tagName: "NMCM",
      tagColor: UUCCategoryColor.MAOORNMCM,
    },
    {
      label: "Non Mission Capable-Supply",
      tagName: "NMCS",
      tagColor: UUCCategoryColor.MAOORNMCS,
    },
    {
      label: "Special Re-work",
      tagName: "SRWRK",
      tagColor: UUCCategoryColor.MAOORSRWRK,
    },
    {
      label: "Unplanned Modification",
      tagName: "UMOD",
      tagColor: UUCCategoryColor.MAOORUMOD,
    },
  ];
  return list;
};
export const ReportLabels = {
  Configurations: "Configurations",
  SoftwareDevelopmentRelease: "Software Development Release",
  Modifications: "Modifications",
  LOPaint: "LO/Paint",
  MajorOperationTasking: "Major Operation Tasking",
  Today: "Today",
};
