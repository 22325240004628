import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./aricraft.css";
import AirCarftPNG from "./ari-tree-sm.png";
import {
  PlusCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { ConfigurationId } from "../../utils/helper";
import { AppDispatch } from "../../redux";
import { useDispatch } from "react-redux";
import { treeSlice } from "../../features/navigation/redux";

interface Props {
  treeData: Array<{ name: string; isOPD: boolean, assetId: number }>;
  section?: number;
  treeClassName?: string;
  leftHandelClassName?: string;
  OnClickAdd?: () => void;
  menuItems?: Array<{ title: string; navigateTo: string }>;
  onChange: (value: string | boolean, index: number, name: string) => void;
  fleetConfiId?: string;
}
interface NodeProps {
  no: string;
  index: number;
  isOPDAsset: boolean;
  menuItems?: Array<{ title: string; navigateTo: string }>;
  onChange: (value: string | boolean, index: number, name: string) => void;
  fleetConfiId?: string;
  assetId: number
}
const defaultValues: Props = {
  treeData: [],
  section: 1,
  treeClassName: "",
  leftHandelClassName: "",
  onChange: () => "",
};

const GetNode: React.FC<NodeProps> = ({
  no,
  isOPDAsset,
  index,
  onChange,
  menuItems,
  fleetConfiId,
  assetId
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { push } = useHistory();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const configId = ConfigurationId() as number;
  const [prevValue, setPrevValue] = useState<string>(no);
  const onValueChange = (changedValue: string) => {
    setPrevValue(changedValue);
  };
  const onSave = () => {
    if (prevValue !== no) onChange(prevValue, index, "assetNumber");
    setIsEditable(false);
  };
  const onCancel = () => {
    setPrevValue(no);
    setIsEditable(false);
  };

  const assetHandler = () => {
    dispatch(treeSlice.actions.setIndividualAssetId(assetId));
    push(`/reporting-management/om/${no}/${configId}`);
  };
  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setIsEditable(true)}>
        Edit
      </Menu.Item>
      {!isOPDAsset ? (
        <Menu.Item key="1" onClick={() => onChange(true, index, "isOPDAsset")}>
          Mark as OPD
        </Menu.Item>
      ) : (
        <Menu.Item key="1" onClick={() => onChange(false, index, "isOPDAsset")}>
          Remove from OPD
        </Menu.Item>
      )}
      {menuItems &&
        menuItems.map((menu: any, i: number) => (
          <Menu.Item key={i + 2} onClick={assetHandler}>
            {menu.title}
          </Menu.Item>
        ))}
    </Menu>
  );
  return (
    <div className="at-left">
      {!isEditable ? (
        <label
          className="at-left-span"
          onDoubleClick={() => setIsEditable(true)}
        >
          {no}
        </label>
      ) : (
        <input
          type="text"
          className="at-left-span"
          value={prevValue}
          onChange={(e) => {
            onValueChange(e.target.value);
          }}
        />
      )}
      {isEditable && (
        <>
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            onClick={onSave}
            style={{ fontSize: "inherit" }}
          />
          <CloseCircleTwoTone
            twoToneColor="red"
            onClick={onCancel}
            style={{ fontSize: "inherit" }}
          />
        </>
      )}
      <Dropdown overlay={menu}>
        <img alt={"aircraft"} src={AirCarftPNG} />
      </Dropdown>
      {isOPDAsset && <label className="at-left-span">OPD</label>}
    </div>
  );
};
const AirCraftLocation: React.FC<Props> = ({
  treeData,
  section,
  treeClassName,
  leftHandelClassName,
  OnClickAdd,
  onChange,
  menuItems,
  fleetConfiId,
}) => {
  const arrLength =
    treeData.length < 20 ? Math.round(treeData.length / (section || 1)) : 10;
  const groups = treeData
    .map((_, index) => {
      return index % arrLength === 0
        ? treeData.slice(index, index + arrLength)
        : null;
    })
    .filter((e) => e);
  console.log("groups", groups);

  return (
    <div className={`arlt-container ${treeClassName}`}>
      <div className={`arlt-left-da ${leftHandelClassName}`}></div>
      {groups&& groups.length>0?groups.map((item, key) => (
        <NodeContainer
          key={`ar${key}`}
          OnClickAdd={OnClickAdd}
          LastGroup={key === groups.length - 1}
        >
          {item &&
            item.map((leaf, lkey) => (
              <li key={`arl${lkey}`}>
                {
                  <GetNode
                    assetId={leaf.assetId}
                    no={leaf.name}
                    index={
                      groups.length > 1 && key > 0
                        ? arrLength * key + lkey
                        : lkey
                    }
                    isOPDAsset={leaf.isOPD}
                    onChange={onChange}
                    menuItems={menuItems}
                    fleetConfiId={fleetConfiId}
                  />
                }
              </li>
            ))}
        </NodeContainer>
      )):<NodeContainer LastGroup={true} OnClickAdd={OnClickAdd}/>}
      
    </div>
  );
};

const NodeContainer: React.FC<{
  LastGroup: boolean;
  OnClickAdd?: () => void;
}> = ({ children, LastGroup, OnClickAdd }) => {
  return (
    <div className="air-tree">
      <ul>
        {children}
        {LastGroup && (
          <li className="div-add-asset-btn">
            <div>
              {" "}
              <PlusCircleOutlined
                style={{ fontSize: "larger" }}
                onClick={OnClickAdd}
                className="add-icon"
              />
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};
AirCraftLocation.defaultProps = defaultValues;
export default AirCraftLocation;
