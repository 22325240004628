
import {
    SUN_GLOW,
    ORANGE_PEEL,
    BITTER_SWEET,
    CINNABAR,
    SCOOTER,
    FEIJOA,
} from "../../../utils/constant";
export interface RouteProps {
    id: number;
    pageName: string;
    PageHeaders: string;
    url: string;
    color: string;
}

export const Tile = {
    reports: "RP",
    missionPlanning: "MP",
    modeling: "MD",
    fleetCapabilityManagement: "CM",
    operationManagement: "OM",
    fleetMaintenanceAnalysis: "MM",
};

export const PageHeaders: { [key: string]: string } = {
    CM: "Capability Management",
    RP: "Reporting",
    MD: "Modelling",
    OM: "Operations Management",
    MM: "Maintenance Management",
    MP: "Mission Planning",
    IO: "Integrated Operations",
    RS: "Routine Servicing",
    FMS: "Fleet Maintenance Schedule",
    IA: "Individual Asset",
    IT: "Individual Tails",
};
export const pageRoutes: Array<RouteProps> = [
    {
        id: 1,
        pageName: Tile.fleetCapabilityManagement,
        PageHeaders: PageHeaders.CM,
        url: `/capability-management`,
        color: CINNABAR,
    },
    {
        id: 2,
        pageName: Tile.operationManagement,
        PageHeaders: PageHeaders.OM,
        url: `/operation-management`,
        color: SCOOTER,
    },
    {
        id: 3,
        pageName: Tile.missionPlanning,
        PageHeaders: PageHeaders.MP,
        url: "/mission-planning",
        color: SUN_GLOW,
    },

    {
        id: 4,
        pageName: Tile.fleetMaintenanceAnalysis,
        PageHeaders: PageHeaders.MM,
        url: "/maintenance-management",
        color: FEIJOA,
    },
    {
        id: 5,
        pageName: Tile.modeling,
        PageHeaders: PageHeaders.MD,
        url: "/initialbuildregistration",
        color: BITTER_SWEET,
    },
    {
        id: 6,
        pageName: Tile.reports,
        PageHeaders: PageHeaders.RP,
        url: "/reporting-management",
        color: ORANGE_PEEL,
    },
];