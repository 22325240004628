import React from "react";
import "./report-body-container.scss";

const ReportBodyContainer: React.FC = ({ children }) => {
  return (
    <div className="body-container justify-content-center mt-5 fade-container">
      <div className="report-content-wrapper w-100 d-flex justify-content-center flex-wrap">
        {children}
      </div>
    </div>
  );
};

export default ReportBodyContainer;
