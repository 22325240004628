import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFacility,
  saveFacility,
  updateFacility,
  deleteFacility,
} from "../services/facilityService";
import {
  CreateFacilityDbType,
  UpdateFacilityDbType,
} from "../fleetForms/model";

export const fetchFacilityByFleet = createAsyncThunk(
  "facility/fetchByFleetConfig",
  async ({ fleetConfigId }: { fleetConfigId: number }) => {
    return await getFacility(fleetConfigId);
  }
);
export const saveFacilityRecord = createAsyncThunk<
  boolean,
  { facilityRequest: CreateFacilityDbType }
>(
  "facility/saveFacility",
  async ({ facilityRequest }: { facilityRequest: CreateFacilityDbType }) => {
    return await saveFacility(facilityRequest);
  }
);
export const updateFacilityRecord = createAsyncThunk<
  boolean,
  { facilityRequest: UpdateFacilityDbType }
>(
  "facility/updateFacility",
  async ({ facilityRequest }: { facilityRequest: UpdateFacilityDbType }) => {
    return await updateFacility(facilityRequest);
  }
);
export const deleteFacilityRecord = createAsyncThunk<
  boolean,
  { facilityRequest: number }
>(
  "facility/deleteFacility",
  async ({ facilityRequest }: { facilityRequest: number }) => {
    return await deleteFacility(facilityRequest);
  }
);
