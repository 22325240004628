import React, { useMemo, useEffect } from "react";
import dataJson from "../../models/otherActivity.json";
import dataRunJson from "../../models/acruntimefull.json";
import { OtherActivity, groupBy } from "../../models/maintenanceModel";
import * as d3 from "d3";

interface Props {
  min?: number;
  max?: number;
  strokeWidth?: number;
  isFull?: boolean;
  activities?: Array<OtherActivity>;
  activitiesFull?: Array<OtherActivity>;
  mainChartName?: string;
}
let width = 600;
const RunTimeOther: React.FC<Props> = ({
  min = 200,
  max = 4600,
  strokeWidth = 1,
  isFull = false,
  activities = dataJson,
  activitiesFull = dataRunJson,
  mainChartName = "chartWrapper",
}) => {
  // const data = activities as Array<OtherActivity>;
  // const dataRun = dataRunJson as Array<OtherActivity>;
  const groupData = useMemo(() => {
    return groupBy(activities);
  }, []);
  const minItem = groupData.length > 3 ? groupData.length : 4;
  const headerHeight = 0;
  const itemHeight = 40;
  const dimensios = {
    height: itemHeight * minItem, // itemHeight * groupData.length + headerHeight,
    width: 0,
    boundedHeight: 0,
    boundedWidth: 0,
    margin: {
      top: headerHeight,
      left: 10,
      bottom: 0,
      right: 20,
    },
  };

  const drawChart = () => {
    const clientWidth =
      document.getElementById(mainChartName)?.clientWidth || 600;

    if (width === 600) {
      width = clientWidth;
    }

    dimensios.width = width;
    dimensios.boundedHeight =
      dimensios.height - dimensios.margin.top - dimensios.margin.bottom;
    dimensios.boundedWidth =
      dimensios.width - dimensios.margin.left - dimensios.margin.right;
    const circleSize = 3;
    const domainMax = max;
    const domainMin = min;

    d3.selectAll("#otherActChart > *").remove();
    const svg = d3
      .selectAll("#otherActChart")
      .append("svg")
      .attr("width", dimensios.width)
      .attr("height", dimensios.height + "px");
    const bounded = svg
      .append("g")
      .style(
        "transform",
        `translate(${dimensios.margin.left}px, ${dimensios.margin.top}px)`
      );
    const xScale = d3
      .scaleLinear()
      .domain([domainMin, domainMax])
      .range([0, dimensios.boundedWidth]);
    const yScale = d3
      .scaleLinear()
      .domain([0, minItem])
      .range([dimensios.boundedHeight, 0]);
    d3.selectAll(".x-axis .domain").remove();
    d3.selectAll(".x-axis .tick line").remove();
    const lineGenerator = d3.line();

    groupData.forEach((item) => {
      if (item.day > 0) {
        const line: Array<[number, number]> = [
          [0, yScale(item.id) + itemHeight / 4],
          [
            isFull ? xScale(item.day) : dimensios.boundedWidth,
            yScale(item.id) + itemHeight / 4,
          ],
        ];
        bounded
          .append("path")
          .attr("d", lineGenerator(line) as string)
          .attr("fill", "none")
          .attr("stroke-width", strokeWidth)
          .attr("stroke", item.color);
      }
    });
    activities.forEach((item) => {
      const line: Array<[number, number]> = [
        [xScale(item.day), yScale(item.id) + itemHeight / 4],
        [xScale(item.day), yScale(0)],
      ];
      bounded
        .append("path")
        .attr("d", lineGenerator(line) as string)
        .attr("fill", "none")
        .attr("stroke-width", strokeWidth)
        .attr("stroke", item.color);

      bounded
        .append("circle")
        .attr("cx", xScale(item.day))
        .attr("cy", yScale(0) - 3)
        .attr("r", circleSize)
        .attr("fill", item.color);
      bounded
        .append("text")
        .attr("x", xScale(item.day) - item.description.length * 5)
        .attr("y", yScale(item.id) + itemHeight / 4)
        .attr("class", "text")
        .text(item.description);
    });
    if (isFull) {
      activitiesFull.forEach((item) => {
        const linetest: Array<[number, number]> = [
          [xScale(item.day) + 8, yScale(minItem)],
          [xScale(item.day) + 8, yScale(0)],
        ];
        bounded
          .append("path")
          .attr("d", lineGenerator(linetest) as string)
          .attr("fill", "none")
          .attr("stroke-width", strokeWidth)
          .attr("stroke", item.color);
        bounded
          .append("circle")
          .attr("cx", xScale(item.day) + 8)
          .attr("cy", yScale(0) - 3)
          .attr("r", circleSize)
          .attr("fill", item.color);
      });
    }
  };
  useEffect(drawChart, [activities, max]);
  useEffect(() => {
    width = 600;
  }, []);
  return (
    <div className="runtime__schedule__other">
      <div className="runtime__schedule__other--activity">
        {groupData.map((item: OtherActivity, idx: number) => (
          <div
            className="runtime__schedule__other--activityItem"
            key={`och${idx}`}
          >
            <div style={{ backgroundColor: item.color }}>{item.name}</div>
          </div>
        ))}
      </div>
      <div className="runtime__schedule__other--chart">
        <div id="otherActChart"></div>
      </div>
    </div>
  );
};

export default RunTimeOther;
