import { message } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ScenarioModel } from "../features/InitialBuildRegistration/redux/scenarioModelSlice";
import { Navigation } from "../features/navigation/redux";
import { RootSliceType } from "../redux";
import { DEFAULT_TIMEOUT, MessageType, STEP_ONE, STEP_TWO } from "./constant";
import { addToStorage } from "./localStorage";
export interface Message {
  type: string,
  content: string
}

export const ConfigurationId = () => {
  const { id: fleetConfigurationId } = useParams<any>();
  const { fleetType } = useSelector<RootSliceType, Navigation>(
    (state) => state.naviReducer
  );
  return fleetConfigurationId || fleetType?.id || "";
};


export const ScenarioModelId = () => {
  const { selectModelData } = useSelector<RootSliceType, ScenarioModel>(
    (state) => state.modelReducer
  );
  return selectModelData.modelId
};



export const ReportSection = () => {
  const { section: rptSection } = useParams<any>();
  const { reportSection } = useSelector<RootSliceType, Navigation>(
    (state) => state.naviReducer
  );
  return rptSection || reportSection || "";
};

export const ReportName = () => {
  const { name: rptName } = useParams<any>();
  const { reportName } = useSelector<RootSliceType, Navigation>(
    (state) => state.naviReducer
  );
  return rptName || reportName || "";
};

export const ReportVersion = () => {
  const { reportId: rptVersion } = useParams<any>();
  const { reportVersion } = useSelector<RootSliceType, Navigation>(
    (state) => state.naviReducer
  );
  return rptVersion || reportVersion || "";
};

export const StepIdentifier = (url: string, lastStep: string) => {
  if (url.includes("fleetbuild")) return addToStorage(STEP_ONE, lastStep);
  if (url.includes("operationmanagement"))
    return addToStorage(STEP_TWO, lastStep);
  else return null;
};


export const onMessage = (msg: Message) => {
  if (msg.type == MessageType.WARNING) {
    message.warning({
      content: msg.content,
      duration: DEFAULT_TIMEOUT,
      className: "antd-msg-custom-class",
    });
  } else if (msg.type == MessageType.INFO) {
    message.info({
      content: msg.content,
      duration: DEFAULT_TIMEOUT,
      className: "antd-msg-custom-class",
    });
  } else if (msg.type == MessageType.ERROR) {
    message.error({
      content: msg.content,
      duration: DEFAULT_TIMEOUT,
      className: "antd-msg-custom-class",
    });
  } else if (msg.type == MessageType.SUCCESS) {
    message.success({
      content: msg.content,
      duration: DEFAULT_TIMEOUT,
      className: "antd-msg-custom-class",
    });
  }
  else if (msg.type == MessageType.LOADING) {
    message.loading({
      content: msg.content,
      duration: 0,
      className: "antd-msg-custom-class",
      key: "updatable"
    });
  }
  else if (msg.type == MessageType.DESTROY) {
    message.destroy("updatable");
  }
  else {
    message.info({
      content: msg.content,
      duration: DEFAULT_TIMEOUT,
      className: "antd-msg-custom-class",
    });
  }
};

export const getCssClass = (css: any) => {
  let style = "";
  const parts = css.toLowerCase().split(" ");
  if (parts.length > 1) {
    if (Number.isInteger(parseInt(parts[0], 10))) {
      style = css
        .toLowerCase()
        .replaceAll(parts[0], "")
        .trim()
        .replaceAll(" ", "-");
    } else style = css.toLowerCase().replaceAll(" ", "-");
  } else {
    style = css.toLowerCase();
  }
  return style;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const generateYear = (endYear: number, startYear: number) => {
  const yearList = [] as Array<string>
  while (endYear >= startYear) {
    yearList.push(endYear.toString())
    endYear -= 1;
  }
  return yearList.reverse()
}
