import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FleetGroupType, FleetType } from "../types";
import type {
  FleetServerType,
  WingServerType,
} from "../../fleetBuilder/fleetForms";

import { addToSessionStorage } from "../../../utils/sessionStorage";
import { SessionStorageField } from "../../../utils/constant";
import {
  fetchAllGroups,
  fetchFleetConfigByWin,
  fetchWingByGroup,
} from "../thunk/navigationThunk";
export interface Navigation {
  selectedGroup: FleetGroupType | null;
  fleets: Array<FleetServerType>;
  wings: Array<WingServerType>;
  fleetsGroups: Array<FleetGroupType>;
  fleetType: FleetType | null;
  errors: Array<string>;
  selectWing: any;
  currentStep: string | null;
  reportSection: string | null;
  reportName: string | null;
  reportVersion: string | null;
  profileManagerInnerTabStep: number | null,
  collapse: boolean | null,
}
const initial: Navigation = {
  selectedGroup: null,
  fleetType: null,
  fleets: Array<FleetServerType>(),
  wings: Array<WingServerType>(),
  fleetsGroups: Array<FleetGroupType>(),
  errors: Array<string>(),
  selectWing: null,
  currentStep: null,
  reportSection: null,
  reportName: null,
  reportVersion: null,
  profileManagerInnerTabStep: null,
  collapse: null,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState: initial,
  reducers: {
    setFleetGroup: (state, action: PayloadAction<FleetGroupType>) => {
      state.selectedGroup = action.payload;
      addToSessionStorage(
        SessionStorageField.fleetsGroups,
        JSON.stringify(action.payload)
      );
    },
    setFleetType: (state, action: PayloadAction<FleetType>) => {
      state.fleetType = action.payload;
      addToSessionStorage(
        SessionStorageField.fleetType,
        JSON.stringify(action.payload)
      );
    },
    setFleets: (state, action: PayloadAction<Array<FleetServerType>>) => {
      state.fleets = action.payload;
      addToSessionStorage(
        SessionStorageField.fleets,
        JSON.stringify(action.payload)
      );
    },
    setWing: (state, action: PayloadAction<Array<WingServerType>>) => {
      state.selectWing = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<string>) => {
      state.currentStep = action.payload;
    },
    setProfileManagerInnerTabStep: (state, action: PayloadAction<number>) => {
      state.profileManagerInnerTabStep = action.payload;
    },
    setTreeCollapse: (state, action: PayloadAction<boolean>) => {
      state.collapse = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.errors.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAllGroups.fulfilled,
        (state, action: PayloadAction<Array<FleetGroupType>>) => {
          state.fleetsGroups = action.payload;
        }
      )
      .addCase(
        fetchWingByGroup.fulfilled,
        (state, action: PayloadAction<Array<WingServerType>>) => {
          state.wings = action.payload;
        }
      )
      .addCase(
        fetchFleetConfigByWin.fulfilled,
        (state, action: PayloadAction<Array<FleetServerType>>) => {
          state.fleets = action.payload;
          addToSessionStorage(
            SessionStorageField.fleets,
            JSON.stringify(action.payload)
          );
        }
      );
  },
});

export default navigationSlice;
