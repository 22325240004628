import React from "react";
import { Label } from "@austaltech/components";
import "./footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="navi-footer">
      <div>
        <Link to="/">
          <Label>Settings</Label>
        </Link>
      </div>
      <div>
        <Link to="/">
          <Label>Security</Label>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
