import React from "react";
import { useHistory } from "react-router";
import { Footer } from "../../components";
import { InnerHeader } from "../../components/header/innerHeader/InnerHeader";
import { useAuth } from "../../context/authContext";
import RoutePaths from "../../routePaths";

const InnerLayout: React.FC = ({ children }) => {
  const { user } = useAuth();
  const { location } = useHistory();

  return (
    <div>
      <InnerHeader />
      <div className="container-fluid">{children}</div>
      {user && location.pathname === RoutePaths.root && <Footer />}
    </div>
  );
};

export default InnerLayout;
