import { TreeNode } from "../../../components/treeView/TreeView";
import { ELiasWorkOrder } from "../models/maintenanceModel";

export interface MaintenanceSliceInitialType {
  squadrons: Array<TreeNode>;
  workOrders: Array<ELiasWorkOrder>;
  workOrder: ELiasWorkOrder | null;
}

export const MaintenanceSliceInitial: MaintenanceSliceInitialType = {
  squadrons: [],
  workOrders: [],
  workOrder: null,
};
