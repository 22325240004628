import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AssetProps {
  assetId: string;
  sqn: string;
  dataId: number;
}

export interface FleetSummaryProp {
  fleetTraget: number;
  fmcActual: number;
  fmcTraget: number;
  msATraget: number;
  msaActual: number;
  fleetActual: number;
}
export interface TreeList {
  assetList: Array<any>;
  selectAsset: AssetProps | null;
  selectedOption: any;
  selectedSqd: any;
  isLeftPanelOpen: boolean;
  individualAssetId: null | number;
  removeTreeDefaultSelection: boolean;
  airCombatGroupData: FleetSummaryProp | null;
  errors: Array<string>;
}
const initial: TreeList = {
  assetList: [],
  selectAsset: null,
  selectedOption: null,
  selectedSqd: null,
  isLeftPanelOpen: false,
  individualAssetId: null,
  removeTreeDefaultSelection: false,
  airCombatGroupData: null,
  errors: Array<string>(),
};

const treeSlice = createSlice({
  name: "tree",
  initialState: initial,
  reducers: {
    setSelectedOption: (state, action: PayloadAction<any>) => {
      state.selectedOption = action.payload;
    },
    setSelectedSqd: (state, action: PayloadAction<any>) => {
      state.selectedSqd = action.payload;
    },
    setSelectAsset: (state, action: PayloadAction<AssetProps | null>) => {
      state.selectAsset = action.payload;
    },
    setSQNList: (state, action: PayloadAction<Array<any>>) => {
      state.assetList = action.payload;
    },
    setIsLeftPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.isLeftPanelOpen = action.payload;
    },
    setIndividualAssetId: (state, action: PayloadAction<null | number>) => {
      state.individualAssetId = action.payload;
    },
    setRemoveDefaultSelection: (state, action: PayloadAction<boolean>) => {
      state.removeTreeDefaultSelection = action.payload;
    },
    setAirCombatGroupData: (
      state,
      action: PayloadAction<FleetSummaryProp | null>
    ) => {
      state.airCombatGroupData = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.errors.push(action.payload);
    },
  },
});

export default treeSlice;
