import React, { Fragment, lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Loader } from "./components/loader/loader";
import Layout from "./layouts/default/Layout";
import { useAuth } from "./context/authContext";
import { RoutePaths } from "./routePaths";
import { SessionStorageField } from "./utils/constant";
import { getFromStorage } from "./utils/localStorage";
import { useAxiosInterceptor } from "./api";
import InnerLayout from "./layouts/default/InnerLayout";
interface AppRoutes {
  exact?: boolean;
  path?: string | string[];
  layout?: React.FC;
  component: React.FC;
  routes?: Array<AppRoutes>;
  isAuth?: boolean;
}
interface Props {
  routes: Array<AppRoutes>;
}
/**
 * Maps through a `routes` object to render a React component tree for `react-router-dom`.
 *
 * You can customise this function to add per-route functionality as required, for example limiting
 * access to certain routes to specific roles or user groups.
 */
export const RenderRoutes: React.FC<Props> = ({ routes }) => {
  useAxiosInterceptor();
  const { user } = useAuth();
  const location = useLocation();
  const sessionUser = getFromStorage(SessionStorageField.user);
  let authUser = user;
  if (sessionUser) {
    authUser = JSON.parse(sessionUser);
  }

  const transitionClassHandler = (layout: any) => {
    if (layout) {
      return "unmount-page";
    } else {
      return "page";
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <TransitionGroup>
        <Switch location={location}>
          {routes.map((route, i) => {
            const Layout = route.layout || Fragment;
            const Component = route.component;
            return (
              <Route key={i} exact path={route.path}>
                {({ match }) => (
                  <div
                    style={{ position: route.layout ? "unset" : "relative" }}
                  >
                    <CSSTransition
                      key={location.key}
                      in={match != null}
                      timeout={450}
                      classNames={transitionClassHandler(route.layout)}
                      unmountOnExit
                    >
                      <div className={transitionClassHandler(route.layout)}>
                        {(!route.isAuth ||
                          (route.isAuth && authUser !== undefined)) && (
                            <Layout>
                              {route.routes ? (
                                <RenderRoutes routes={route.routes} />
                              ) : (
                                <Component />
                              )}
                            </Layout>
                          )}
                        {route.isAuth && authUser === undefined && (
                          <Redirect to="/" />
                        )}
                      </div>
                    </CSSTransition>
                  </div>
                )}
              </Route>
            );
          })}
        </Switch>
      </TransitionGroup>
    </Suspense>
  );
};

const routes: Array<AppRoutes> = [
  {
    exact: true,
    path: RoutePaths.root,
    layout: InnerLayout,
    isAuth: false,
    component: lazy(() => import("./views/home/Home")),
  },
  {
    exact: true,
    path: RoutePaths.fleetPlanning,
    layout: InnerLayout,
    isAuth: false,
    component: lazy(() => import("./views/fleetPlanning/FleetPlanning")),
  },
  {
    exact: true,
    path: RoutePaths.fleetPlanning2,
    isAuth: false,
    component: lazy(() => import("./views/fleetPlanning2/FleetPlanning")),
  },
  {
    exact: true,
    path: RoutePaths.strips,
    isAuth: false,
    component: lazy(() => import("./features/fleetPlanning2/pane-view")),
  },
  {
    exact: true,
    path: RoutePaths.allViewStrips,
    isAuth: false,
    component: lazy(() => import("./features/fleetPlanning2/all-view")),
  },
  {
    exact: true,
    path: RoutePaths.fleetOperationalStatus,
    isAuth: true,
    component: lazy(() => import("./views/home/FleetOperationalStatus")),
  },
  {
    exact: true,
    path: RoutePaths.fleetBuild,
    isAuth: true,
    component: lazy(() => import("./views/fleetBuild/FleetBuild")),
  },
  {
    exact: true,
    path: RoutePaths.fleetBuildTargetRoe,
    isAuth: true,
    component: lazy(() => import("./views/fleetBuild/FleetBuildTargetRoe")),
  },
  {
    exact: true,
    path: RoutePaths.eventRoe,
    isAuth: true,
    component: lazy(
      () => import("./views/fleetOperationManagement/EventRoeManagement")
    ),
  },
  {
    exact: true,
    path: RoutePaths.fleetOperatingLimits,
    component: lazy(
      () =>
        import("./views/fleetOperatingLimits/FleetOperatingLimitsManagement")
    ),
    isAuth: true,
  },
  {
    exact: true,
    path: RoutePaths.acceptanceConfiguration,
    isAuth: true,
    component: lazy(
      () =>
        import(
          "./views/fleetOperationManagement/AcceptanceConfigurationManagement"
        )
    ),
  },
  {
    exact: true,
    path: RoutePaths.operationManagement,
    component: lazy(
      () => import("./views/fleetOperationManagement/FleetOperationManagement")
    ),
    isAuth: true,
  },
  {
    exact: true,
    path: RoutePaths.fleetProfile,
    component: lazy(
      () => import("./views/fleetProfilesManagement/FleetProfileManagement")
    ),
    isAuth: true,
  },
  {
    exact: true,
    path: RoutePaths.admin,
    component: lazy(() => import("./views/admin/Admin")),
    isAuth: true,
  },
  {
    exact: true,
    path: RoutePaths.fleetConfigurationSummery,
    layout: InnerLayout,
    component: lazy(
      () =>
        import("./views/fleetConfigurationSummery/fleetConfigurationSummery")
    ),
    isAuth: true,
  },
  {
    exact: true,
    path: RoutePaths.fleetConfiguration,
    component: lazy(
      () =>
        import(
          "./views/fleetConfigurationManagement/FleetConfigurationManagemnt"
        )
    ),
    isAuth: true,
  },
  {
    exact: true,
    path: RoutePaths.roeSummary,
    component: lazy(
      () => import("./views/fleetOperationManagement/RoeSummaryManagement")
    ),
    isAuth: true,
  },
  {
    exact: true,
    path: RoutePaths.functions,
    isAuth: false,
    layout: Layout,
    component: lazy(() => import("./views/fleetManagement/FleetManagement")),
  },

  {
    exact: true,
    path: RoutePaths.reports,
    layout: InnerLayout,
    isAuth: false,
    component: lazy(() => import("./views/reports/Reports")),
  },
  {
    exact: true,
    path: RoutePaths.individualAsset,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(() => import("./views/IndividualAsset/IndividualAsset")),
  },
  {
    exact: true,
    path: RoutePaths.individualTails,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(() => import("./views/IndividualTails/IndividualTails")),
  },
  {
    exact: true,
    path: RoutePaths.initialBuildRegistration,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/initialBuildRegistration/initialBuildRegistration")
    ),
  },
  {
    exact: true,
    path: RoutePaths.fleetSchedule,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(() => import("./views/fleetSchedule/FleetSchedule")),
  },
  {
    exact: true,
    path: RoutePaths.iliasMaintenanceSchedule,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(() => import("./views/fleetScheduleILIAS/FleetSchedule")),
  },
  {
    exact: true,
    path: RoutePaths.integratedOperations,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () =>
        import(
          "./views/integratedOperationsMaintenance/IntegratedOperationMaintenance"
        )
    ),
  },
  {
    exact: true,
    path: RoutePaths.maintenance,
    isAuth: false,
    component: lazy(
      () => import("./views/fleetMaintenance/MaintenanceContainer")
    ),
  },
  {
    exact: true,
    path: RoutePaths.scheduleOfConstraints,
    layout: Layout,
    isAuth: false,
    component: lazy(
      () => import("./views/scheduleOfConstraints/ScheduleOfConstraints")
    ),
  },
  {
    exact: true,
    path: RoutePaths.cmcTree,
    layout: Layout,
    isAuth: false,
    component: lazy(() => import("./views/fleetMaintenance/CMCContainer")),
  },
  {
    exact: true,
    path: RoutePaths.routingService,
    layout: InnerLayout,
    isAuth: true,
    component: lazy(() => import("./views/routineServicing/RoutineServicing")),
  },
  {
    exact: true,
    path: RoutePaths.capabilityManagement,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () =>
        import(
          "./views/initialBuildRegistration/ProfileManagement/ProfileManagement"
        )
    ),
  },
  {
    exact: true,
    path: RoutePaths.operationsManagement,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/fleetOperationManagement/OperationsManagement")
    ),
  },
  {
    exact: true,
    path: RoutePaths.missionPlanning,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/MissionPlanning/MissionPlanning")
    ),
  },
  {
    exact: true,
    path: RoutePaths.maintenanceManagement,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/maintenanceManagement/MaintenanceManagement")
    ),
  },
  {
    exact: true,
    path: RoutePaths.reportingManagement,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/reportManagement/ReportManagementHome")
    ),
  },
  {
    exact: true,
    path: RoutePaths.reportingManagementCM,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/reportManagement/ReportManagement")
    ),
  },
  {
    exact: true,
    path: RoutePaths.reportingManagementCMDate,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/reportManagement/ReportManagement")
    ),
  },
  {
    exact: true,
    path: RoutePaths.reportingManagementMM,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/reportManagement/ReportManagementMM")
    ),
  },
  {
    exact: true,
    path: RoutePaths.reportingManagementOM,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/reportManagement/ReportManagementOM")
    ),
  },
  {
    exact: true,
    path: RoutePaths.reportingManagementIA,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/reportManagement/ReportManagementIA")
    ),
  },
  {
    exact: true,
    path: RoutePaths.squadronManagement,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/squadron/SquadronContainer")
    ),
  },
  {
    exact: true,
    path: RoutePaths.reportingManagementMD,
    isAuth: true,
    layout: InnerLayout,
    component: lazy(
      () => import("./views/reportManagement/ReportManagementMD")
    ),
  },
  {
    exact: true,
    path: RoutePaths.pageNotFound,
    layout: Layout,
    isAuth: false,
    component: lazy(() => import("./views/notFound/NotFound")),
  },
];

export default routes;
