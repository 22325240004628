import React from "react";
import { Tabs } from "antd";
import { Images } from "../../../components";

const { TabPane } = Tabs;

const WorkActivityTabs = () => {
  return (
    <Tabs defaultActiveKey="1" type="card" size={"small"} className="tab-set-2">
      <TabPane tab="Task 01 Work Steps" key="1">
        <div className="table-wrapper work-steps-container">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Order</th>
                <th>Work Step Description</th>
                <th>Remarks</th>
                <th className="text-center">Task Time </th>
                <th className="text-center">Document</th>
                <th className="text-center"> OQE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>Caution</td>
                <td>Prior to working on component, isolate</td>
                <td>1 min</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>02</td>
                <td>Note</td>
                <td>Wear Protective Eye Wear when draining</td>
                <td>0 min</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>03</td>
                <td>Inspect for Oil Leak from Seal</td>
                <td>Refer: Steering BAE Maintenance Page 6</td>
                <td>1 min</td>
                <td>
                  <Images className="" name={"WordIcon"} />
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>04</td>
                <td>Replace head roller bearing and lip seals</td>
                <td>Refer: Steering BAE Maintenance Page 09</td>
                <td>30 min</td>
                <td>
                  {" "}
                  <Images className="" name={"PdfIcon"} />
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>05</td>
                <td>Inspect cylinder for leakage and for for scoring</td>
                <td>Refer: Cylinder BAE Maintenance Page 1</td>
                <td>15 min</td>
                <td>
                  <Images className="" name={"WordIcon"} />
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>06</td>
                <td>Measure bronze bush’s in tie bar ends</td>
                <td>Refer: Cylinder BAE Maintenance Page 2</td>
                <td>30 min</td>
                <td>
                  <Images className="" name={"PdfIcon"} />
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>07</td>
                <td>Measure Connection Pins for Wear</td>
                <td>Refer: Set To Work Procedure Page 15</td>
                <td>20 min</td>
                <td>
                  <Images className="" name={"PdfIcon"} />
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>08</td>
                <td>Inspect rods for cracks</td>
                <td>Refer: Steering BAE Maintenance Page 12</td>
                <td>20 min</td>
                <td>
                  <Images className="" name={"PdfIcon"} />
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>09</td>
                <td>Strip and replace hydraulic cylinder for wear</td>
                <td>Refer: Steering BAE Maintenance Page 22</td>
                <td>10 min</td>
                <td>
                  <Images className="" name={"WordIcon"} />
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Replace other components as required. Document</td>
                <td>Refer: Steering BAE Maintenance Page 35</td>
                <td>15 min</td>
                <td>
                  <Images className="" name={"PdfIcon"} />
                </td>
                <td>Yes</td>
              </tr>
            </tbody>
          </table>
        </div>
      </TabPane>
      <TabPane tab="Task 01 Resources" key="2" className="task-resources">
        <div className="resources-table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Order</th>
                <th scope="col">Work Step Description</th>
                <th scope="col">Planned Time</th>
                <th scope="col">Actual Time</th>
                <th scope="col">Resource Group</th>
                <th scope="col">Maintenance Org</th>
                <th scope="col">Depot Bays</th>
                <th scope="col">Costs</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>Caution</td>
                <td>1 min</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>02</td>
                <td>Note</td>
                <td>0 min</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>03</td>
                <td>Inspect for Oil Leak from Seal</td>
                <td>1 min</td>
                <td></td>
                <td>Mechanical</td>
                <td>BAEW</td>
                <td>
                  <span className="span-depot-bay">MRO & U Bay 1</span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>04</td>
                <td>Replace head roller bearing and lip seals</td>
                <td>30 min</td>
                <td></td>
                <td>Pneumatics</td>
                <td>BAEW</td>
                <td>
                  <span className="span-depot-bay">MRO & U Bay 1</span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>05</td>
                <td>Inspect cylinder for leakage and for for scoring</td>
                <td>15 min</td>
                <td></td>
                <td>Avionics</td>
                <td>BAEW</td>
                <td>
                  <span className="span-depot-bay">MRO & U Bay 1</span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>06</td>
                <td>Measure bronze bush’s in tie bar ends</td>
                <td>30 min</td>
                <td></td>
                <td>Electrical</td>
                <td>BAEW</td>
                <td>
                  <span className="span-depot-bay">MRO & U Bay 1</span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>07</td>
                <td>Measure Connection Pins for Wear</td>
                <td>20 min</td>
                <td></td>
                <td>Mechanical</td>
                <td>BAEW</td>
                <td>
                  <span className="span-depot-bay">MRO & U Bay 1</span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>08</td>
                <td>Inspect rods for cracks</td>
                <td>20 min</td>
                <td></td>
                <td>Airframe</td>
                <td>BAEW</td>
                <td>
                  <span className="span-depot-bay">MRO & U Bay 1</span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>09</td>
                <td>Strip and replace hydraulic cylinder for wear</td>
                <td>10 min</td>
                <td></td>
                <td>Pneumatics</td>
                <td>BAEW</td>
                <td>
                  <span className="span-depot-bay">MRO & U Bay 1</span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>10</td>
                <td>Replace other components as required. Document</td>
                <td>15 min</td>
                <td></td>
                <td>Supervisory</td>
                <td>BAEW</td>
                <td>
                  <span className="span-depot-bay">MRO & U Bay 1</span>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </TabPane>
      <TabPane tab="Task 01 Materials" key="3">
        Content of card tab 1
      </TabPane>
      <TabPane tab="Task 01 Support Equipment" key="4">
        Content of card tab 1
      </TabPane>
    </Tabs>
  );
};

export default WorkActivityTabs;
