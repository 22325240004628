import React, { useEffect, useState } from "react";
import { Popover, Button, Divider } from "antd";
import { Link, useHistory } from "react-router-dom";
import Avatar from "antd/lib/avatar/avatar";
import {
  LogoutOutlined,
  QuestionCircleOutlined,
  EditFilled,
  EnvironmentOutlined,
  SettingOutlined,
  StarOutlined,
  ArrowRightOutlined,
  BellOutlined,
  BarsOutlined,
  HeartOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../../context/authContext";
import { SessionStorageField, WHITE } from "../../../utils/constant";
import { getFromStorage } from "../../../utils/localStorage";
import { PageRouting } from "./PageRouting";
import austal from "../../../assets/images/austal.png";
import VerticalDivider from "../../../assets/images/divider-verticle-menu.svg";
import { pageReportRoutes } from "../../../routes/reportPageRoutes";
import { pageRoutes, RouteProps } from "./pageRoutes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootSliceType } from "../../../redux";
import { ScenarioModel } from "../../../features/InitialBuildRegistration/redux/scenarioModelSlice";
import RoutePaths from "../../../routePaths";
import { ConfigurationId } from "../../../utils/helper";
import { FleetSchedule } from "../../../features/navigation/redux";
import { userLogout } from "../../../features/navigation/redux/actionTypes";
import "../default/header.scss";
import "./innerHeader.scss";

const customPopoverStyle = () => (
  <style>
    {`.ant-popover-inner-content {
         padding: 5px; border: 1px solid #bfbfbf;
          border-radius: 6px; } .ant-popover-inner{
            box-shadow:unset
          }
          .ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow,
          .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
          display:none
          }
          .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow{
          display:none

          }
      `}
  </style>
);

export const InnerHeader: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const [headerState, setHeaderState] = useState<RouteProps>({
    id: 0,
    pageName: "",
    PageHeaders: "",
    url: ``,
    color: "red",
  });
  const configId = ConfigurationId() as number;
  const [reportRoute, setReportRoute] = React.useState<any>({});
  const { location, push } = useHistory();
  const { user, login, logout } = useAuth();
  const path = location.pathname;
  const sessionUser = getFromStorage(SessionStorageField.user);
  const { selectModelData } = useSelector<RootSliceType, ScenarioModel>(
    (state) => state.modelReducer
  );

  const { initialModel } = useSelector<RootSliceType, FleetSchedule>(
    (state) => state.fleetScheduleReducer
  );
  let authUser = user;
  if (sessionUser) {
    authUser = JSON.parse(sessionUser);
  }

  const pageHeaderHandler = () => {
    const splitPath = path.split("/");
    if (!headerState.url.includes(splitPath[1])) {
      const header = pageRoutes.find((h) => h.url.includes(splitPath[1]));
      if (header) {
        setHeaderState({ ...header });
        return true;
      }
      return true;
    }
    return false;
  };

  const checkReportNameTags = () => {
    const splitPath = path.split("/");
    if (splitPath.length > 2 && splitPath[2]) {
      const routeIndex = pageReportRoutes.findIndex(
        (x) =>
          String(x.name).toLocaleLowerCase() ==
          String(splitPath[2]).toLocaleLowerCase()
      );
      if (routeIndex !== -1) {
        setReportRoute(pageReportRoutes[routeIndex]);
      }
    }
  };

  const logoutHandler = () => {
    logout();
    dispatch(userLogout());
  };

  useEffect(() => {
    checkReportNameTags();
    pageHeaderHandler();
  }, [location.pathname]);

  const userContent = (
    <div className="group-menu-container user-info-wrap" style={{ width: 200 }}>
      <div>
        {user && (
          <div className="">
            <span className="user-name">{user.name} FLTLT</span>
            <br />
            <span className="location-edit">
              <EnvironmentOutlined />
              &nbsp;76 SQN - Williamtown &nbsp;&nbsp;&nbsp;
              <EditFilled />
            </span>
          </div>
        )}
        <Divider />
        <div className="user-actions-btn">
          <Button
            style={{ width: "100%" }}
            type="text"
            className="d-flex align-items-center"
          >
            <SettingOutlined />
            Settings
          </Button>
        </div>
        <div className="user-actions-btn">
          <Button
            style={{ width: "100%" }}
            onClick={logoutHandler}
            type="text"
            className="d-flex align-items-center"
          >
            <LogoutOutlined />
            Sign Out
          </Button>
        </div>
      </div>
      {customPopoverStyle()}
    </div>
  );

  const favoriteContent = (
    <div className="group-menu-container user-info-wrap" style={{ width: 200 }}>
      <div className="menu-item-favorite">
        <ul>
          <li>
            <a href="/">
              <BellOutlined />
              <p>My notifications</p>
            </a>
          </li>
          <li>
            <a href="/">
              <BarsOutlined />
              <p>My work items</p>
            </a>
          </li>
          <li>
            <a href="/">
              <HeartOutlined />
              <p>Favorites</p>
            </a>
          </li>
        </ul>
      </div>
      {customPopoverStyle()}
    </div>
  );

  const routeToPage = () => {
    if (headerState && headerState.url) {
      push(headerState.url);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light inner-nav-container mb-10">
      <Link className="navbar-brand" to="/">
        <img src={austal} alt="austal_logo" className="company-logo" />
      </Link>
      <ul className="navbar-nav mr-auto ">
        {headerState.id !== 0 && (
          <div className="header-title outter-ly-ot">
            <div className="title-wrapper">
              <span
                onClick={() => routeToPage()}
                className="mini-title-box"
                style={{ background: headerState.color, cursor: "pointer" }}
              >
                {headerState.pageName}
              </span>
              {!!reportRoute.name && (
                <span
                  className="mini-title-box"
                  style={{ background: reportRoute.color }}
                >
                  {String(reportRoute.name).toLocaleUpperCase()}
                </span>
              )}
              <h1 className="title-header" style={{ color: headerState.color }}>
                {headerState.PageHeaders}
              </h1>

              <div className="header-vertical-text-container">
                <div
                  className="vertical-title"
                  style={{ background: headerState.color, borderRadius: 3 }}
                >
                  <p>{selectModelData.modelType}</p>
                </div>
              </div>
            </div>
            <div className="underline">&nbsp;</div>
          </div>
        )}
      </ul>
      <ul className="navbar-nav ml-auto d-flex justify-content-center align-items-center inner-nav-right-actions">
        {process.env.REACT_APP_VERSION &&
          process.env.REACT_APP_VERSION == "DEV" && (
            <li className="nav-item blink_me">
              {process.env.REACT_APP_VERSION}
            </li>
          )}
        <li className="nav-item">
          <div className="label-wrapper">
            {selectModelData.stringModelId && path !== RoutePaths.root && (
              <p className="mb-0 active-model-no-box">
                <a href="/squadron-management" style={{ color: "#5a5a5a" }}>
                  Model ID : <b> {selectModelData.stringModelId} </b>
                </a>
                {!path.includes(String("initialbuildregistration")) && (
                  <SwapOutlined
                    className="model-arrow ml-2"
                    onClick={() =>
                      push(`/initialbuildregistration/${configId}`)
                    }
                  />
                )}
              </p>
            )}
            {initialModel &&
              initialModel.name &&
              path.includes(String("maintenance-management")) && (
                <p className="mb-0 base-model ">
                  Base Version : <b> {initialModel.name}</b>
                </p>
              )}
          </div>
        </li>
        <li className="nav-item">
          <Link to="/" className="nav-link link-gray">
            <Popover
              placement={"bottomRight"}
              content={favoriteContent}
              trigger={user ? "click" : ""}
              overlayStyle={{
                background: WHITE,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <StarOutlined />
            </Popover>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/" className="nav-link link-gray">
            <img src={VerticalDivider} style={{ height: "32px" }} />
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/" className="nav-link link-gray">
            <QuestionCircleOutlined />
          </Link>
        </li>
        {user !== undefined && (
          <li className="nav-item">
            <div>
              <Popover
                placement={"bottomRight"}
                content={userContent}
                trigger={user ? "click" : ""}
                overlayStyle={{
                  background: WHITE,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <Avatar
                  size={36}
                  style={{ color: WHITE, backgroundColor: "#00587a" }}
                >
                  {authUser && authUser.name && authUser.name.charAt(0)}
                </Avatar>
              </Popover>
            </div>
          </li>
        )}
        <li className="menu-icon-route">
          <PageRouting />
        </li>

        <li>
          {user === undefined && (
            <Button
              style={{ width: 100 }}
              onClick={login}
              type="text"
              className="user-sign-in-btn"
            >
              Sign In
            </Button>
          )}
        </li>
      </ul>
    </nav>
  );
};
