import React from "react";
import { FormBox } from "@austaltech/components";
import { Labels } from "../../models/ScheduleActivityModel";
import type {
  ActivityType,
  MaintenanceElement,
} from "../../models/maintenanceModel";
import ScheduleActivityForm from "../../components/ScheduleActivityForm";
import MaintenanceSchedule from "../../components/MaintenanceSchedule";
import { MaintenanceActivityTypeIds } from "../../../../utils/constant";
import "./scheduleActivity.scss";
interface Props {
  aircraftType: string;
  elementsList: MaintenanceElement[];
  activityTypes: ActivityType[];
  isTabView?: boolean;
}

const ScheduledActivities: React.FC<Props> = ({
  aircraftType,
  elementsList,
  activityTypes,
  isTabView,
}) => {
  return (
    <div className="route__activities">
      {!isTabView && <h1>{Labels.StepTitle}</h1>}
      {/* <FormBox label={Labels.FormTitle}> */}
        <div className="route__activities--froms route-wrapper">
          <ScheduleActivityForm
            title={Labels.FormRoutineTitle}
            dataSet={elementsList
              .filter(
                (a) =>
                  a.maintenanceActivityType ===
                  MaintenanceActivityTypeIds.ROUTING_SERVICING
              )
              .map((item) => ({
                activityName:
                  activityTypes?.find(
                    (a) => a.activityTypeId === item.maintenanceActivityType
                  )?.activityType || "",
                color: item.colorCode,
                id: item.maintenanceId,
                intervalDays: item.intervalDays,
                intervalHours: item.intervalHrs,
                workingDays: item.workingDays,
              }))}
          />
          <ScheduleActivityForm
            title={Labels.FormSpecialTitle}
            dataSet={elementsList
              .filter(
                (a) =>
                  a.maintenanceActivityType ===
                  MaintenanceActivityTypeIds.SPECIAL_SERVICING
              )
              .map((item) => ({
                activityName:
                  activityTypes?.find(
                    (a) => a.activityTypeId === item.maintenanceActivityType
                  )?.activityType || "",
                color: item.colorCode,
                id: item.maintenanceId,
                intervalDays: item.intervalDays,
                intervalHours: item.intervalHrs,
                workingDays: item.workingDays,
              }))}
          />
          <ScheduleActivityForm
            title={Labels.FormOtherTitle}
            dataSet={elementsList
              .filter(
                (a) =>
                  a.maintenanceActivityType !==
                    MaintenanceActivityTypeIds.ROUTING_SERVICING &&
                  a.maintenanceActivityType !==
                    MaintenanceActivityTypeIds.SPECIAL_SERVICING
              )
              .map((item) => ({
                activityName:
                  activityTypes?.find(
                    (a) => a.activityTypeId === item.maintenanceActivityType
                  )?.activityType || "",
                color: item.colorCode,
                id: item.maintenanceId,
                intervalDays: item.intervalDays,
                intervalHours: item.intervalHrs,
                workingDays: item.workingDays,
              }))}
          />
        </div>
      {/* </FormBox> */}
      <br />
      <FormBox label={`${aircraftType} ${Labels.FormChartTitle}`}>
        <MaintenanceSchedule data={elementsList} />
      </FormBox>
    </div>
  );
};

export default ScheduledActivities;
