import * as React from "react";
import "./icon.scss";

const LeftLineArrow = (props: React.SVGProps<SVGSVGElement>) => {
  const { onClick } = props;
  return (
    <svg
      width={42}
      height={19}
      overflow="hidden"
      onClick={onClick}
      className="pointer"
      {...props}
    >
      <defs>
        <clipPath id="clip8">
          <rect x={299} y={83} width={42} height={19} />
        </clipPath>
      </defs>
      <g clipPath="url(#clip8)" transform="translate(-299 -83)">
        <path
          d="M340.777 92.8334 314.167 92.8333 314.167 92.1666 340.777 92.1667ZM315.5 96.5 307.5 92.4999 315.5 88.5Z"
          fill="#7F7F7F"
        />
        <path
          d="M304.5 102.069 304.5 84.5"
          stroke="#7F7F7F"
          strokeWidth={0.666667}
          strokeMiterlimit={8}
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default LeftLineArrow;
