import React, { useState } from "react";
import { Tree } from "antd";
import "antd/dist/antd.css";
import { CMCService } from "../../services/CMCService";
import ReactDOM from "react-dom";
import "./cmc-tree.scss";
interface DataNode {
  title: string;
  key: string;
  isLeaf?: boolean;
  children?: DataNode[];
}

const initTreeDate: DataNode[] = [];
const updateTreeData = (
  list: DataNode[],
  key: React.Key,
  children: DataNode[]
): DataNode[] => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
};

const CMCTree: React.FC = () => {
  const [treeData, setTreeData] = useState(initTreeDate);
  React.useEffect(() => {
    async function fetchMyAPI() {
      const response = await CMCService.getCMCLeaf("");
      if (response !== null) {
        if (response?.length > 0) {
          setTreeData([{ title: response[0].name, key: response[0].code }]);
        }
      }
    }
    fetchMyAPI();
  }, []);

  const getNodes = (res: any) => {
    const d = res.map((item: any) => {
      return { title: item.name, key: item.code };
    });
    return d;
  };

  const onLoadData = async ({ key, children }: any) => {
    const response = await CMCService.getCMCLeaf(key);
    if (response !== null) {
      if (response?.length > 0) {
        const data = getNodes(response);
        setTreeData((origin) => updateTreeData(origin, key, data));
      }
    }
  };

  return (
    <div className="cmc-tree-container notransition" id="cmctree">
      <Tree
        showLine={{ showLeafIcon: false }}
        loadData={onLoadData}
        treeData={treeData}
      />
    </div>
  );
};
export default CMCTree;
