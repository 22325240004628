import React from "react";
import "./fleetbuildlayout.scss";

const BodyContainer: React.FC = ({ children }) => {
  return (
    <div className="body-container">
      <div className="fleet-build-wrapper">{children}</div>
    </div>
  );
};

export default BodyContainer;
