export const Labels = {
  FormTitle: "Maintenance Routine Element",
  StepTitle: "Step 1",
  ThActiveType: "Maintenance Activity Type",
  ThID: "ID",
  ThIntervalHours: "Interval (Hrs)",
  ThIntervalDays: "Interval (Days)",
  ThWorkingDays: "Working Days",
  ThLocation: "Location",
  ThHanger: "Hangar",
  ThLane: "Lane",
  ThPrimaryGroup: "Primary Resource Group",
  ThSecondaryGroup1: "Secondary Resource Group1",
  ThSecondaryGroup2: "Secondary Resource Group2",
  ThSpecialistGroup: "Specialist Resource Group",
  WorkingHours: "Working Hours",
  cost: "Cost",
  FormChartTitle: "Maintenance Schedule",
  ScheduleActivityID: "Maintenance Activity ID",
  ScheduleIntervalHrs: "Interval (Hrs)",
  ScheduleEveryInterval: "Every Interval",
};

export interface MaintenanceElementDemo {
  activityName: string;
  id: string;
  color: string;
  intervalHours: number;
  intervalDays: number;
  workingDays: number;
  location: string;
  hanger: string;
  lane: string;
  primaryGroup: string;
  secondaryGroup1: string;
  secondaryGroup2: string;
  specialistGroup: string;
  totalHours: number;
  idx: number;
  [key: string]: string | number | any;
}
export interface ActivitySummaryType {
  activityName: string;
  id: string;
  color: string;
  icon?: string;
  title?: string;
  idx: number;
  [key: string]: string | number | any;
  occurrence: Array<ActivitySummaryOccurrence>;
}
export interface ActivitySummaryOccurrence {
  interval: number;
  name: string;
  subName?: string;
  color: string;
}
export const InitMaintenanceElementDemo: MaintenanceElementDemo = {
  activityName: "",
  id: "",
  color: "",
  intervalHours: 0,
  intervalDays: 0,
  workingDays: 0,
  location: "",
  hanger: "",
  lane: "",
  primaryGroup: "",
  secondaryGroup1: "",
  secondaryGroup2: "",
  specialistGroup: "",
  totalHours: 0,
  idx: 0,
};

export type FieldNames<T> = {
  [K in keyof T]: K;
};
const getFieldNameMap = (srcObj: any) =>
  Object.keys(srcObj).reduce((obj: Record<string, unknown>, k) => {
    obj[k] = k;
    return obj;
  }, {});

type MaintenanceElementDemoFieldNames = FieldNames<MaintenanceElementDemo>;
export const MaintenanceElementDemoFieldNames = getFieldNameMap(
  InitMaintenanceElementDemo
) as MaintenanceElementDemoFieldNames;

export interface OtherActivity {
  id: number;
  name: string;
  color: string;
  day: number;
  description: string;
  [k: string]: string | number;
}

export const groupBy = (xs: Array<OtherActivity>) => {
  return xs.reduce((rv: Array<OtherActivity>, x: OtherActivity) => {
    const findItem = rv.find((a) => a.id == x.id);
    if (!findItem) {
      rv.push(x);
    }
    return rv;
  }, []);
};
export interface RibbonYear {
  year: string;
  month: string;
}
export interface ValueId {
  id: string;
  value: string;
}
export const PrimaryList = (): Array<ValueId> => {
  const dropArray: Array<ValueId> = new Array(8)
    .fill(0)
    .map((item, idx) => ({ id: (idx + 1).toString(), value: `RG ${idx + 1}` }));
  dropArray.push({ id: "9", value: "Con 1" });
  return dropArray;
};

export interface ActivityType {
  id: number;
  activityType: string;
  activityTypeId: number;
}
export interface MaintenanceElement {
  id: number;
  maintenanceId: string;
  colorCode: string;
  intervalHrs: number;
  intervalDays: number;
  workingDays: number;
  fleetConfigurationId: number;
  workingHrs: number;
  cost: number;
  maintenanceActivityType: number;
  isDeepMaintenance: boolean;
  consumabales: number;
  parts: number;
  spares: number;
  sAndTE: number;
  workForceAF: number;
  workForceAE: number;
  workForceAV: number;
  workForceAS: number;
  workForceAR: number;
  workForceSF: number;
  workForceNDT: number;
  workForceLS: number;
  workForceFL: number;
  workForceFK: number;
  bayIds?: Array<any> | null;
}
export const InitMaintenanceElement: MaintenanceElement = {
  id: 0,
  maintenanceId: "",
  colorCode: "",
  intervalHrs: 0,
  intervalDays: 0,
  workingDays: 0,
  fleetConfigurationId: 0,
  workingHrs: 0,
  cost: 0,
  maintenanceActivityType: 0,
  isDeepMaintenance: false,
  consumabales: 0,
  parts: 0,
  spares: 0,
  sAndTE: 0,
  workForceAF: 0,
  workForceAE: 0,
  workForceAV: 0,
  workForceAS: 0,
  workForceAR: 0,
  workForceSF: 0,
  workForceNDT: 0,
  workForceLS: 0,
  workForceFL: 0,
  workForceFK: 0,
  bayIds: ["0"],
};
type MaintenanceElementFields = FieldNames<MaintenanceElement>;
export const MaintenanceElementsFieldNames = getFieldNameMap(
  InitMaintenanceElement
) as MaintenanceElementFields;

export interface EliasAsset {
  operationaL_STATUS: string;
  squadroN_DESC: string;
  squadron: string;
  taiL_NUMBER: string;
  operationaL_ABILITIES: {
    operationaL_ABILITY: Array<{
      ability: string;
      compatibility: string;
    }>;
  };
  meters: {
    meter: Array<{
      meterac: string;
      value: string;
    }>;
  };
}

export interface ELiasWorkOrder {
  workorderid: string;
  eststarttime: string;
  estendtime: string;
  plandate: string;
  asset: string;
  ppmpair: string;
  status: string;
  activities: {
    activity: Array<ELiasWorkOrderActivity>;
  };
}
export const emptyELiasWorkOrder: ELiasWorkOrder = {
  workorderid: "",
  activities: {
    activity: [],
  },
  asset: "",
  estendtime: "",
  eststarttime: "",
  plandate: "",
  ppmpair: "",
  status: "",
};
export interface ELiasWorkOrderActivity {
  activitynumber: number;
  trade: string;
  duration: number;
  workOrder: ELiasWorkOrder;
  allocations: object;
}

//Ilias Body response
export interface WorkOrderResponse {
  workorder: Array<ELiasWorkOrder>;
}
export interface EliasAssetResponse {
  aircraft: Array<EliasAsset>;
}
