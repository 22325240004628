import React, { useEffect } from "react";
import {
  ActivitySummaryType,
  MaintenanceElementDemo,
  Labels,
} from "../../models/maintenanceModel";
import * as d3 from "d3";

interface Props {
  data: Array<MaintenanceElementDemo>;
  min?: number;
  max?: number;
  ticks?: number;
  summary?: Array<ActivitySummaryType>;
}
interface ArrowDataSource {
  y: number;
  x: number;
  color: string;
}
let width = 600;
const MaintenanceSchedule: React.FC<Props> = ({
  data,
  min = 200,
  max = 5600,
  ticks = 28,
  summary,
}) => {
  const headerHeight = 42;
  const itemHeight = 40;
  console.log("height", itemHeight * data.length + headerHeight);
  const dimensios = {
    height: itemHeight * data.length + headerHeight,
    width: 0,
    boundedHeight: 0,
    boundedWidth: 0,
    margin: {
      top: headerHeight,
      left: 20,
      bottom: 0,
      right: 20,
    },
  };
  const circleSize = 15;
  const domainMax = max;
  const domainMin = min;
  const drawArrow = (
    svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    xScale: d3.ScaleLinear<number, number, never>,
    yScale: d3.ScaleLinear<number, number, never>
  ) => {
    const lineGenerator = d3.line();
    const sortdata = data
      .map((a) => ({ idx: a.idx, val: a.intervalHours }))
      .sort((a, b) => a.idx - b.idx);
    summary?.forEach((item) => {
      if (item.id === "MODS") {
        item.occurrence.forEach((arrow) => {
          const activity = sortdata.filter(
            (b) => arrow.interval % b.val === 0
          )[0];
          const circleBottom = itemHeight / 2 + circleSize / 2;
          const topline =
            yScale(activity ? activity.idx : data.length) +
            (activity ? circleBottom : 0);
          const arrowTop = activity ? 5 : 0;
          const line: Array<[number, number]> = [
            [xScale(arrow.interval), yScale(0)],
            [xScale(arrow.interval), topline],
          ];
          const linetrangle: Array<[number, number]> = [
            [xScale(arrow.interval) - 4, topline + arrowTop],
            [xScale(arrow.interval), topline - (activity ? 2 : 8)],
            [xScale(arrow.interval) + 4, topline + arrowTop],
            [xScale(arrow.interval) - 4, topline + arrowTop],
          ];
          svg
            .append("path")
            .attr("d", lineGenerator(line) as string)
            .attr("fill", "none")
            .attr("stroke-width", 0.3)
            .attr("stroke", "black");
          svg
            .append("path")
            .attr("d", lineGenerator(linetrangle) as string)
            .attr("fill", arrow.color)
            .attr("stroke-width", 0.3)
            .attr("stroke", "black");
        });
      }
    });
  };
  const drawChart = () => {
    const clientWidth =
      document.getElementById("chartfullWrapper")?.clientWidth || 600;
    if (width === 600) {
      width = clientWidth;
    }
    dimensios.width = width;
    dimensios.boundedHeight =
      dimensios.height - dimensios.margin.top - dimensios.margin.bottom;
    dimensios.boundedWidth =
      dimensios.width - dimensios.margin.left - dimensios.margin.right;
    const dataSet: Array<MaintenanceElementDemo> = data.map(
      (a, idx: number) => {
        a.idx = data.length - idx;
        return a;
      }
    );

    d3.selectAll("#chartfullWrapper > *").remove();
    const svg = d3
      .selectAll("#chartfullWrapper")
      .append("svg")
      .attr("width", dimensios.width)
      .attr("height", dimensios.height);
    const bounded = svg
      .append("g")
      .style(
        "transform",
        `translate(${dimensios.margin.left}px, ${dimensios.margin.top}px)`
      );
    const xScale = d3
      .scaleLinear()
      .domain([domainMin, domainMax])
      .range([0, dimensios.boundedWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, data.length])
      .range([dimensios.boundedHeight, 0]);

    const xAxis = d3.axisTop(xScale).ticks(ticks);
    bounded.append("g").attr("class", "x-axis").call(xAxis);
    d3.selectAll(".x-axis .domain").remove();
    d3.selectAll(".x-axis .tick line").remove();
    const lineGenerator = d3.line();

    dataSet.forEach((item) => {
      if (item.intervalHours > 0) {
        const interval = parseInt(item.intervalHours.toString());
        if (interval !== 0 && Number.isInteger(interval)) {
          const line: Array<[number, number]> = [
            [0, yScale(item.idx) + 3 + circleSize / 2],
            [dimensios.boundedWidth, yScale(item.idx) + 3 + circleSize / 2],
          ];
          bounded
            .append("path")
            .attr("d", lineGenerator(line) as string)
            .attr("stroke-dasharray", "1,2")

            .attr("fill", "none")
            .attr("stroke", "blue");

          for (let i = interval; i <= domainMax; ) {
            bounded
              .append("circle")
              .attr("cx", xScale(i))
              .attr("cy", yScale(item.idx) + 3 + circleSize / 2)
              .attr("r", circleSize)
              .attr("fill", item.color);
            bounded
              .append("text")
              .attr("class", "route__schedule--circle--text")
              .attr("x", xScale(i) - 2 - circleSize / 2)
              .attr("y", yScale(item.idx) + 6 + circleSize / 2)
              .text(
                item.id.length <= 3
                  ? item.id
                  : item.id.substr(0, 3).padEnd(4, ".")
              );
            i += interval;
          }
        }
      }
    });
    drawArrow(bounded, xScale, yScale);
  };

  useEffect(drawChart, [data, max]);
  return (
    <div className="route__schedule">
      <div className="route__schedule--left">
        <div className="route__schedule--route--header">
          <label>{Labels.ScheduleActivityID}</label>
          <label>{Labels.ScheduleIntervalHrs}</label>
          <label>{Labels.ScheduleEveryInterval}</label>
        </div>
        {data
          .filter((a) => a.intervalHours > 0)
          .map((item, idx: number) => (
            <div className="route__schedule--route--body" key={`mch${idx}`}>
              <div style={{ backgroundColor: item.color }}>{item.id}</div>
              <div>{item.intervalHours}</div>
              <div>Y</div>
            </div>
          ))}
      </div>
      <div className="route__schedule--right">
        <div id="chartfullWrapper"></div>
      </div>
    </div>
  );
};

export default MaintenanceSchedule;
