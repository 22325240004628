//Date Format
export const SIMULATION_ENGINE_DATE_FORMAT = "YYYY-MM-DD";
export const CALENDER_DATE_FORMAT = "dd/MM/yyyy";
export const CALENDER_DATE_DEFAULT_FORMAT = "DD/MM/YYYY";

export const DATE_RANGE_FORMAT = "YYYY, MM, DD";
export const DAY_VIEW_DATA_FORMAT = "YYYY-MM-DD";
export const DATE_DD = "DD";
export const MOMENT_DAY = "day";
export const MOMENT_DAYS = "days";
export const MOMENT_HOUR = "hour";
export const MOMENT_MINUTE = "minute";
export const DAY_VIEW_HEADER_FORMAT = "dddd DD";
export const DATE_FORMAT = "DD";
export const MONTH_VIEW_FORMAT = "DD MMM YYYY";
export const FULL_MONTH_VIEW_FORMAT = "dd MMMM yyyy";
export const MONTH_VIEW_FORMAT_SIMPLE = "dd MMM yyyy";

export const HOLIDAY_DATE_FORMAT = "YYYY/MM/DD";
export const DAY_FORMAT = "dddd";
export const DAY_WITH_DATE = "dddd DD/MM/YYYY";
export const YEAR_FORMAT = "YYYY";
export const DATEFORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
export const TOOL_TIP_FORMAT = "Do [of] MMMM YYYY";
export const DAY_VIEW_DATE_FORMAT = "MMMM DD, YYYY";
export const MONTH = "month";
export const DAYS = "days";
export const DAY = "day";
export const DEFAULT_DB_DATE = "0001-01-01T00:00:00";
export const FLEET_SCHEDULE_DATE_FORMAT = "DD MMMM YY hh:mm a";
export const JS_DEFAULT_FORMAT = "MM/DD/YYYY";

//Colors in calender
export const ORANGE = "#FF6358";
export const WHITE = "#fff";
export const BLIZZARD_BLUE = "#a6d1f5";
export const ALICE_BLUE = "#D6EAF8";
export const DIM_GREY = "#5B9BD5";
export const SERENADE = "#FBE5D6";
export const FERN_GREEN = "#548235";
export const FLAMINGO = "#e6614b";
export const DARK_TANGERINE = "#FF9D14";
export const CORAL = "#FF714D";
export const YELLOW = "#FFFF00";
export const BLACK = "#000000";
export const DODGER_BLUE = "#1890FF";
export const DISABLED_GREY = "#EBEBE4";
export const DISABLED_TEXT = "#C6C6C6";
export const RED = "#FF0000";
export const LIGHT_SKY_BLUE = "#42c0f7";
export const LIGHTER_BLUE = "#ddf3fe";
export const HONEYDEW = "#EAFCEA";
export const TURQUOISE = "rgb(66, 192, 247)";
export const WHITE_ICE = "#CCFFE5";
export const VERY_LIGHT_GREY = "#cecece";
export const GREY = "#7F7F7F";
export const SPINDLE = "#BCCEDE";
export const AMBER = "#FFC000";
export const POLO_BLUE = "#8eacc8";
export const TEA_GREEN = "#D4E8C6";
export const APPLE = "#70ad47";
export const BOTTICELLI = "#d9d9d9";

//Tiles
export const SUN_GLOW = "#FAD538";
export const ORANGE_PEEL = "#FF9900";
export const BITTER_SWEET = "#FF6B6B";
export const CINNABAR = "#e3472c";
export const SCOOTER = "#3890A7";
export const PALE_GREEN = "#98FB98";
export const LIGHT_GREY = "#F2F2F2";

//Colors in fleet
export const SOLITUDE = "#DEEBF7"; // default background color fleet
export const FACILITY = "#83aebe";

export const FEIJOA = "#A9D18E";

//System Colors
export const LIGHT_BLUE = "#307893";

export const DM_COLOR = "#fceade";
export const OM_COLOR = "#e5eff9";

//Regex
export const NUMBER_VALIDATION = /^(0|[1-9]\d*)$/;
export const FLOAT_NUMBER_VALIDATION = /^[0-9]*(?:\.[0-9]*)?$/;
export const REMOVE_SPACE_REGEX = /\s+/g;
export const STRING_NUM_VALIDATION = /^-?\d+$/;
export const NUMBER_VALIDATIONSPACE = /[A-Za-z !@#$%^&*()]/g;
export const REMOVE_THOUSAND_SEPERATE = /(\d+),(?=\d{3}(\D|$))/g;

//Stepper main nodes
export const STEPPER_MAIN_NODES = [0, 10, 18, 21, 25, 29];

//Stepper main steps
export const STEP_ONE = "stepOne";
export const STEP_TWO = "stepTwo";
export const STEP_THREE = "stepThree";
export const STEP_FOUR = "stepFour";
export const STEP_FIVE = "stepFive";
export const STEP_SIX = "stepSix";

export const STEP_ARRAY = [
  STEP_ONE,
  STEP_TWO,
  STEP_THREE,
  STEP_FOUR,
  STEP_FIVE,
  STEP_SIX,
];

type ANY_KEY_TYPE = {
  [key: string]: any;
};

export const IMAGE_PATH: ANY_KEY_TYPE = {
  ChartAircraft: require("../assets/images/chart-dm1.svg"),
  ChartAircraftSchedule: require("../assets/images/flighschedulechart.jpg"),
  ExpandReduce: require("../assets/images/expand-reduce.svg"),
  ExpandOut: require("../assets/images/expand-out.svg"),
  AirCraftEqConfigurationFive: require("../assets/images/eq-configuration-five.svg"),
  StarGreen: require("../assets/images/star-green.svg"),
  AirCraftAirSystemConfigGreen: require("../assets/images/air-system-config-green-air-craft.svg"),
  StatusCountImg: require("../assets/images/w-status-count-icon.svg"),
  AssingAirCraft: require("../assets/images/assign-aircraft.svg"),
  Aircraft1: require("../assets/images/aircraft1.png"),
  ThreeAirCraft: require("../assets/images/three-aircraft.svg"),
  FlightSectorAircraft: require("../assets/images/flight-sector-aircraft.svg"),
  DateCalendar: require("../assets/images/date-calendar-icon.svg"),
  MultiSectorIcon: require("../assets/images/next-multi-select-icon.svg"),
  Hawk127: require("../assets/images/hawk127.svg"),
  FLIGHT_GRAY: require("../assets/images/baseline-aircraft.svg"),
  FLIGHT_LIGHT_GRAY: require("../assets/images/config-1-aircraft.svg"),
  FLIGHT_ORANGE: require("../assets/images/config-2-aircraft.svg"),
  FLIGHT_LIGHT_PINK: require("../assets/images/config-3-aircraft.svg"),
  FLIGHT_LIGHT_GREEN: require("../assets/images/config-4-aircraft.svg"),
  FLIGHT_BLUE: require("../assets/images/config-6-aircraft.svg"),
  FLIGHT_YELLOW: require("../assets/images/config-7-aircraft.svg"),
  FLIGHT_PURPLE: require("../assets/images/config-8-aircraft.svg"),
  STAR_GREEN: require("../assets/images/symbol-start-30px.svg"),
  STAR_PINK: require("../assets/images/symbol-start-40px.svg"),
  GreyCircle: require("../assets/images/grey-circle.svg"),
  FleetMaintenanceIcon: require("../assets/images/fleet-maintenance-icon.svg"),
  OperationMgtIcon: require("../assets/images/operation-mgt-icon.svg"),
  AnalysisMaintenanceIcon: require("../assets/images/analysis-maintenance-icon.svg"),
  OrangeColorAircraftIcon: require("../assets/images/orange-color-aircraft.svg"),
  CalenderIcon: require("../assets/images/calender-icon.svg"),
  PrevNextBtnIcon: require("../assets/images/pre-event.svg"),
  WordIcon: require("../assets/images/word-icon.svg"),
  PdfIcon: require("../assets/images/pdf-icon.svg"),
  WeekViewIcon: require("../assets/images/week-view.svg"),
  LineBlueAirField: require("../assets/images/line--blue-air.svg"),
  LineGreenAirField: require("../assets/images/line--green-air.svg"),
  Sqn76: require("../assets/images/squadron/76-sqn.png"),
  Sqn79: require("../assets/images/squadron/79-sqn.png"),
  Sqn4: require("../assets/images/squadron/4-sqn.png"),
  DefaultSqn: require("../assets/images/squadron/sqn-default-cl.png"),
  FatigueLifePlane: require("../assets/images/FatigueLifePlane.svg"),
  pylonsplane: require("../assets/images/pylonsplane.svg"),
  acEnginePlusPropLimits: require("../assets/images/acEnginePlusPropLimits.svg"),
  MultiRole: require("../assets/images/MultiRole.svg"),
  cargopax: require("../assets/images/cargopax.svg"),
  multiRoleCargoPaxTransport: require("../assets/images/multirolecargopaxtransport.png"),
  MultiRoleRecevery: require("../assets/images/MultiRoleRecevery.png"),
  MultiRoleEmergencyRelief: require("../assets/images/MultiRoleEmergencyRelief.png"),
  MultiRoleSearchandRescue: require("../assets/images/MultiRoleSearchandRescue.png"),
  MultiRoleRapidResponse: require("../assets/images/MultiRoleRapidResponse.png"),
  SpecialMissionPassenger: require("../assets/images/specialMissionPassenger.png"),
  SpecialMissionPaxTransport: require("../assets/images/SpecialMissionPaxTransport.png"),
  SpecialMissionPaxRecovery: require("../assets/images/SpecialMissionPaxRecovery.png"),
  SpecialMissionEmergencyRelief: require("../assets/images/SpecialMissionEmergencyRelief.png"),
  SpecialMissionSearchandRescue: require("../assets/images/SpecialMissionSearchandRescue.png"),
  SpecialMissionRapidResponse: require("../assets/images/SpecialMissionRapidResponse.png"),
  TankerTransport: require("../assets/images/TankerTransport.png"),
  TankerPassenger: require("../assets/images/TankerPassenger.png"),
  TankerRecovery: require("../assets/images/TankerRecovery.png"),
  TankerEmergencyRelief: require("../assets/images/TankerEmergencyRelief.png"),
  TankerSearchandRescue: require("../assets/images/TankerSearchandRescue.png"),
  TankerRapidResponse: require("../assets/images/TankerRapidResponse.png"),
  CargoSearchandRescue: require("../assets/images/CargoSearchandRescue.png"),
  cargoEmergencyRelief: require("../assets/images/cargoEmergencyRelief.png"),
  CargoRecovery: require("../assets/images/CargoRecovery.png"),
  CargoPassenger: require("../assets/images/CargoPassenger.png"),
  CargoPaxtestTransport: require("../assets/images/CargoPaxtestTransport.png"),
  CargoRapidResponse: require("../assets/images/CargoRapidResponse.png"),
  PlanAirCraft: require("../assets/images/plan-aircraft.svg"),
  SideAriCraft: require("../assets/images/side-aircraft.svg"),
  TankPlane: require("../assets/images/TankPlane.svg"),
  DetailedPlane: require("../assets/images/DetailedPlane.svg"),
  pioletone: require("../assets/images/pioletone.svg"),
  piolottwo: require("../assets/images/piolot2.svg"),
  searchicon: require("../assets/images/searchicon.svg"),
  azarrow: require("../assets/images/azarrow.svg"),
  DoubleClick: require("../assets/images/double-click-icon.png"),
  bae: require("../assets/images/bea-system.png"),
  airFrameLimits: require("../assets/images/air-frame-limits.svg"),
  assetsLink: require("../assets/images/assets-link.svg"),
  mapView: require("../assets/images/map.PNG"),
};
//API Response message for modals
export const DEFAULT_TIMEOUT = 3;
export const DEFAULT_ERROR_MESSAGE =
  "Oops, something went wrong. Please try again.";
export const ADD_SIMULATION_SUCCESS =
  "Successfully created new simulation process";
export const ADD_EVENT_SUCCESS = "Successfully added new event";
export const DELETE_EVENT_SUCCESS = "Successfully deleted event";
export const UPDATE_EVENT_SUCCESS = "Successfully update event";
export const ADD_HOLIDAY_SUCCESS = "Successfully added new holiday";
export const ADD_LOCATION_SUCCESS = "Successfully added new location";
export const UPDATE_LOCATION_SUCCESS = "Successfully update location";
export const DELETE_LOCATION_SUCCESS = "Successfully delete location";
export const DELETE_HOLIDAY_SUCCESS = "Successfully deleted holiday";
export const UPDATE_HOLIDAY_SUCCESS = "Successfully update holiday";

export const UPDATE_ADD_OPERATIONAL_SUCCESS = "Successfully update event";
export const ADD_OPERATIONAL_SUCCESS = "Successfully add event";

export const RESULT_NOT_FOUND = "There were no results found.";
export const DELETE_OPERATIONAL_EVENT_SUCCESS = "Successfully deleted event";
export const ADD_OPERATIONAL_EVENT_DETAILS_SUCCESS =
  "Successfully add event data to calender";

export const EVENT_FINISH_TIME_WARNING =
  "Please select correct event finish time. finish time must greater than start time";
export const EVENT_START_TIME_WARNING =
  "Please select correct event start time. start time must less than finish time";

export const PUBLISH_SUCCESS = "Successfully mark as public";
export const PUBLISH_PRIVATE_SUCCESS = "Successfully mark as private";
export const ASSET_ASSIGN_SUCCESS = "Successfully assign assert";
export const CONFIGURATION_CHANGE_SUCCESS = "Successfully change configuration";
export const CONFIGURATION_DUPLICATE_SUCCESS =
  "Successfully duplicate configuration";

export const AIRCRAFT_VALIDATION =
  "No of aircraft must equal or higher than no of tern per event";

export const DELETE_WARNING = "Sure to delete?";
export const ASSET_PROPERTY_SET_SUCCESS =
  "Successfully update asset properties";
export const PDS_LOADING_FAILED =
  "Oops, something went wrong. Product Schedule(PDS) loading failed";
export const MODELING_DATA_RANGE_ERROR = "Please check modeling date range";

export const SELECT_ASSET_WARNING = "Please select any asset before load view";

export const MAINTENANCE_HISTORY_SAVE_SUCCESS =
  "Successfully added maintenance history";

export const SUCCESSFULLY_ADD_MAINTENANCE_HISTORY =
  "Successfully added maintenance history to view";

export const DATE_RANGE_VALIDATION_CHECK = "Please select valid date range";
export const MAINTENANCE_TYPE_VALIDATION_CHECK =
  "Please select at leaset one type";
export const VERSION_DELETE_SUCCESS = "Successfully delete version";
export const VERSION_UPDATE_SUCCESS = "Successfully update version";
export const USER_VERSION_CREATED_SUCCESS = "Successfully user version created";
export const VERSION_LOADED_SUCCESS = "Successfully loaded version";
export const NO_CHANGES = "There is no any change to update";

export const NO_PAYLOAD = "There is no any activity to load";
export const VERSION_PROCEED_WARNING =
  "Please create new version or select old version to proceed";
export const ALREADY_ADDED = "Selected version is already added into view";
export const LOCK_WARNING = "Please unlock to proceed";
export const CREATED_MAINTAIN_ACTIVITY_SUCCESS =
  "Successfully created maintain activity ";
export const UPDATE_MAINTAIN_ACTIVITY_SUCCESS =
  "Successfully updated maintain activity ";
export const MAXIMUM_WARNING = " is not a valid value";
export const DELETE_SUCCESS_FLEET_TARGET = "Successfully delete record";
export const ADDED_SUCCESS_FLEET_TARGET = "Successfully added new record";
export const UPDATE_SUCCESS_FLEET_TARGET = "Successfully update record";
export const DELETE_SUCCESS_MAINTAIN_ELEMENT =
  "Successfully deleted maintain element record";
export const UPDATE_SUCCESS_MAINTAIN_ELEMENT =
  "Successfully updated maintain element record";

export const MODEL_SELECT_WARNING = "Select Any Scenario model to proceed.";
export const RECURRING_WARNING =
  "No any selected holiday to recurring, Please select holiday to proceed";

export const BASELINE_SUCCESS = "Successfully set baseline version";
export const BASELINE_REMOVE_SUCCESS = "Successfully remove baseline";
export const MAINTENANCE_UNIT_REMOVE_SUCCESS =
  "Successfully deleted maintenance unit";
export const ASSET_ALREADY_ASSIGNED =
  "Assert is already assign for another event";
export const ASSET_REMOVE_SUCCESS = "assert is removed Successfully";
export const SET_INITIAL_CONFIG_SUCCESS =
  "Successfully added initial configuration ";
export const FLEET_SIZE_WARNING = "Maximum fleet size is 100";
export const TOTAL_ASSET_WARNING =
  "Total asset must be equal or less than fleet size limit";
export const ASSET_WARNING = "Fleet size limit must be greater than zero";
export const MAINTENANCE_EXIT =
  "Maintenance id is already exit, Please select another maintenance id";
export const MAXIMUM_COMPARISON = "Maximum limit is two ";
export const FILL_ALL_FIELD =
  "Please fill in all the fields in configuration attributes section to proceed";
export const OPERATIONAL_CONFIGURATION_DELETE_SUCCESS =
  "Successfully deleted configuration";
export const OPERATIONAL_CONFIGURATION_ADD_SUCCESS =
  "Successfully added new configuration";
export const OPERATIONAL_CONFIGURATION_UPDATE_SUCCESS =
  "Successfully update profile configuration";
export const FILL_ALL_REQUIRED_FIELD = "Please fill in all required fields";
export const ROUTINE_ELEMENTS_ADD_SUCCESS =
  "Successfully add new maintenance routine element";

export const ROUTINE_ELEMENTS_UPDATE_SUCCESS =
  "Successfully update maintenance routine element";

  export const CAMM2_DATA_INFO =
    "Currently no any camm2 data";

export enum SessionStorageField {
  fleetsGroups = "fleetsGroups",
  fleetType = "fleetType",
  fleets = "fleets",
  user = "user",
  GroupsError = "GroupsLoadError",
}

export const MSAL_USERNAME = "msal_username";

export enum HolidayTypes {
  PUBLIC_HOLIDAY = 0,
  FLEET_CONFIGURATION_HOLIDAY = 1,
}

export const ADD__SUCCESS_MESSAGE = "Successfully added the ";
export const UPDATE__SUCCESS_MESSAGE = "Successfully updated the ";

export const DELETE__SUCCESS_MESSAGE = "Successfully deleted the ";

export const RESET_CAMM2_SUCCESS = "Successfully reset camm2 data ";


export const FleetOperationalStatusTypes = {
  OPERATIONAL_STATUS_CATEGORY: {
    id: 1,
    name: "operationalstatuscategories",
    displayName: "Operational Status Categories",
  },
  MAINTENANCE_IN_REPORTING: {
    id: 2,
    name: "maintenance-inreporting",
    displayName: "Maintenance-In Reporting",
  },
  MAINTENANCE_OUT_OF_REPORTING: {
    id: 3,
    name: "maintenance-outofreporting",
    displayName: "Maintenance-Out Of Reporting",
  },
  IN_OPERABLE: { id: 4, name: "in-operable", displayName: "In-Operable" },
};
export enum AirCraftConfigurationTypeIds {
  AIR_SYSTEM_CONFIGURATION = 1,
  SOFTWARE_DATA_LOADS = 2,
  FOLLOW_ON_MODERNIZATION = 3,
  MAINTENANCE_FACILITY = 4,
}
export enum MaintenanceActivityTypeIds {
  SOFTWARE_UPGRADES = 7,
  OTHER_MAINTENANCES = 6,
  PAINT = 5,
  MODIFICATION = 4,
  CAPABILITY_UPGRADES = 3,
  SPECIAL_SERVICING = 2,
  ROUTING_SERVICING = 1,
}
//Headers
export const AUTH_USER = "AuthUser";
export const LOGGING_USER = "user";
export const LAST_STEP = "LastStep";
export const LAST_URL = "Url";
export const AUTH_USER_NAME = "AuthUserName";
export const CONFIGURATION_ID = "FleetConfigurationId";

//Calender views
export enum ViewTypes {
  DAY_VIEW = "day",
  WEEK_VIEW = "week",
  MONTH_VIEW = "month",
  SCHEDULE_VIEW = "schedule",
  NEW_EVENT = "event",
}

export const DEFAULT_DATE_TIME = "0001-01-01T00:00:00";

export const ModelTypes = [
  {
    id: 1,
    type: "Build",
    disableValue: [2],
  },
  {
    id: 2,
    type: "Active",
    disableValue: [1],
  },
  {
    id: 3,
    type: "What If",
    disableValue: [1],
  },
];

export const ModelClassifications = [
  {
    id: 1,
    type: "Initial Build",
    disableValue: [2],
  },
  {
    id: 2,
    type: "Active Plan",
    disableValue: [1],
  },
  {
    id: 3,
    type: "What If Scenario",
    disableValue: [1],
  },
];

export const USER_ROLES = {
  ACCESS_FLEET_SCHEDULE: "Access.FleetSchedule",
  ACCESS_GLOBAL_USER: "Access.Global",
  ACCESS_DENIED: "",
};

export const MessageType = {
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
  DESTROY: "destroy",
};

export const MAINTAIN_VALIDATE_STATUS_CODE = 409;
