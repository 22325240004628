import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import "../event-schedule-form.scss";

interface Props {
  clickHandler(): void;
  Side: Direction;
}
export enum Direction {
  LEFT = 0,
  RIGHT,
}

const RangeSlideButton: React.FC<Props> = ({ clickHandler, Side }) => {
  let css = "nav-left left-paddle paddle";
  let iconName: IconDefinition = faCaretLeft;
  if (Side === Direction.RIGHT) {
    css = "nav-right right-paddle paddle";
    iconName = faCaretRight;
  }
  return (
    <div className={css} onClick={clickHandler}>
      <a href="#">
        <FontAwesomeIcon icon={iconName} />
      </a>
    </div>
  );
};

export default RangeSlideButton;
