import React, { useEffect } from "react";
import { ActivitySummaryType } from "../../models/maintenanceModel";
import * as d3 from "d3";

interface Props {
  data: Array<ActivitySummaryType>;
  min?: number;
  max?: number;
  ticks?: number;
}
let width = 600;
const maxItems = 3;
const GPMaintenanceSchedule: React.FC<Props> = ({
  data,
  min = 200,
  max = 5600,
}) => {
  const headerHeight = 0;
  const itemHeight = 60;
  const dimension = {
    height: itemHeight * maxItems + headerHeight,
    width: 0,
    boundedHeight: 0,
    boundedWidth: 0,
    margin: {
      top: headerHeight,
      left: 20,
      bottom: 0,
      right: 20,
    },
  };
  const drawChart = () => {
    const clientWidth =
      document.getElementById("gpchartWrapper")?.clientWidth || 600;
    if (width === 600) {
      width = clientWidth;
    }
    dimension.width = width;
    dimension.boundedHeight =
      dimension.height - dimension.margin.top - dimension.margin.bottom;
    dimension.boundedWidth =
      dimension.width - dimension.margin.left - dimension.margin.right;
    const dataSet: Array<ActivitySummaryType> = data.map((a, idx: number) => {
      a.idx = data.length - idx;
      return a;
    });
    const domainMax = max;
    const domainMin = min;
    d3.selectAll("#gpchartWrapper > *").remove();
    const svg = d3
      .selectAll("#gpchartWrapper")
      .append("svg")
      .attr("width", dimension.width)
      .attr("height", dimension.height);
    const bounded = svg
      .append("g")
      .style(
        "transform",
        `translate(${dimension.margin.left}px, ${dimension.margin.top}px)`
      );
    const xScale = d3
      .scaleLinear()
      .domain([domainMin, domainMax])
      .range([0, dimension.boundedWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, maxItems])
      .range([dimension.boundedHeight, 0]);

    const lineGenerator = d3.line();

    dataSet.forEach((item, idx: number) => {
      if (item.icon && item.icon === "ac") {
        const line: Array<[number, number]> = [
          [0, yScale(item.idx) + itemHeight / 2],
          [dimension.boundedWidth, yScale(item.idx) + itemHeight / 2],
        ];
        bounded
          .append("path")
          .attr("d", lineGenerator(line) as string)
          .attr("fill", "none")
          .attr("stroke-width", 0.3)
          .attr("stroke", "gray");
      }
      if (idx === 0) {
        item.occurrence.forEach((c) => {
          const line1: Array<[number, number]> = [
            [xScale(c.interval), 0],
            [xScale(c.interval), yScale(item.idx) + (itemHeight / 2 - 5)],
          ];
          bounded
            .append("path")
            .attr("d", lineGenerator(line1) as string)
            .attr("fill", "none")
            .attr("stroke-width", 0.3)
            .attr("stroke", "black");
        });
      }
      item.occurrence.forEach((evn) => {
        if (item.icon && item.icon === "ac") {
          const dimmer = bounded
            .append("g")
            .attr("class", "dimmer")
            .style(
              "transform",
              `translate(${xScale(evn.interval) - 15}px, ${
                yScale(item.idx) + 35 - itemHeight / 2
              }px)`
            );
          dimmer
            .append("image")
            .attr("height", 50)
            .attr("width", 50)

            .attr("href", "../assets/images/plan.png");
          dimmer
            .append("text")
            .attr("class", "runtimefull__sum-cp--text")
            .attr("y", itemHeight)
            .text(evn.name);
        } else if (item.icon && item.icon === "star") {
          const dimmer = bounded
            .append("g")
            .attr("class", "dimmer")
            .style(
              "transform",
              `translate(${xScale(evn.interval) - 15}px, ${
                yScale(item.idx) + 10 - itemHeight / 2
              }px)`
            );
          dimmer
            .append("svg")
            .attr("viewBox", "0 0 600 600")
            .attr("width", "40")
            .attr("x", 6)
            .attr("y", 35)
            .attr("height", "40")
            .append("polygon")
            .attr("points", "100,10 40,198 190,78 10,78 160,198")
            .attr(
              "style",
              `fill:${evn.color};stroke:${evn.color};stroke-width:5;fill-rule:nonzero;`
            );
          dimmer
            .append("text")
            .attr("class", "runtimefull__sum-cp--text")
            .attr("y", itemHeight)
            .text(evn.name);
        } else {
          bounded
            .append("text")
            .attr("class", "runtimefull__sum-cp--text")
            .attr("x", xScale(evn.interval) - evn.name.length)
            .attr("y", yScale(item.idx) + 2 + itemHeight / 2)
            .text(evn.name);
        }
      });
    });
  };
  useEffect(drawChart, [data, max]);
  return (
    <div className="runtimefull__sum">
      <div className="runtimefull__sum--left">
        <div className="runtimefull__sum--route--body">
          <div className="runtimefull__sum-main">
            <div
              className="runtimefull__sum-main-text"
              style={{ backgroundColor: "brown" }}
            >
              {"MODS"}
            </div>
            <span>(FoM)</span>
          </div>
          <div className="runtimefull__sum-main">
            <div className="runtimefull__sum-main-icon">
              <img src="../assets/images/plan.png" />
            </div>
            <span>A/C Configuration</span>
          </div>
          <div className="runtimefull__sum-main">
            <div
              className="runtimefull__sum-main-text"
              style={{ backgroundColor: "green" }}
            >
              {"SDL"}
            </div>
          </div>
        </div>
      </div>
      <div className="runtimefull__sum--right">
        <div id="gpchartWrapper"></div>
      </div>
    </div>
  );
};

export default GPMaintenanceSchedule;
