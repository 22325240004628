import React from "react";
import { LIGHT_BLUE } from "../../utils/constant";
import { LabelBox } from "../../components";
import { styles } from "./styles";

interface Props {
  config: any;
  width?: number;
  className?: string;
}

enum Image {
  AIRCRAFT_CONFIG_IMAGE_LIGHT_ORANGE = require("../../assets/images/eq-configuration-five.svg"),
  STAR_GREEN = require("../../assets/images/star-green.svg"),
  FLIGHT_LIGHT_GREEN = require("../../assets/images/air-system-config-green-air-craft.svg"),
}

const FlightConfig: React.FC<Props> = ({ config, width }) => {
  return (
    <div style={styles.columnContainer}>
      <img
        src={Image[config.imageCode]}
        style={{ width: width || 70, objectFit: "contain" }}
      />
      <p style={{ fontSize: 9, color: LIGHT_BLUE }}>{config.label}</p>
    </div>
  );
};

const SoftwareConfig: React.FC<Props> = ({ config, className }) => {
  return (
    <div style={styles.rowContainer} className={className || ""}>
      <img
        src={Image[config.imageCode]}
        style={{ width: 25, objectFit: "contain", marginRight: 10 }}
      />
      <LabelBox name={config.label} width={60} backgroundColor="transparent" />
    </div>
  );
};

export { FlightConfig, SoftwareConfig };
