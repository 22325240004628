export const stepsSource = [
  {
    label: "Maintenance Routine Elements",
    name: "6.1",
  },
  {
    label: "Maintenance Routine",
    name: "6.2",
  },
  {
    label: "Maintenance Summary",
    name: "6.3",
  },
  {
    label: "Maintenance Summary",
    name: "6.4",
  },
  {
    label: "Maintenance Activity Hierarchy",
    name: "6.5",
  },
  {
    label: "Event ROE Assigned to A/C",
    name: "2.4",
  },
  {
    label: "Acceptance/IOC Configuration",
    name: "2.5",
  },
  {
    label: "A/C Baseline ROE Summary",
    name: "2.6",
  },
  {
    label: "Fleet Strength",
    name: "2.7",
  },
  {
    label: "",
    name: "2.8",
  },
];
