export const Labels = {
  FormTitle: "Routine Maintenance Scheduled Activities",
  StepTitle: "Step 2",
  ThActiveType: "Maintenance Activity Type",
  ThID: "ID",
  ThIntervalHours: "Interval (Hrs)",
  ThIntervalDays: "Interval (Days)",
  EffortInDays: "Effort (Days)",
  FormRoutineTitle: "Routine Servicing Events",
  FormSpecialTitle: "Special Servicing Events",
  FormOtherTitle: "Other Maintenance Events",
  FormChartTitle: "Maintenance Schedule",
};

export interface ActivityRow {
  activityName: string;
  id: string;
  color: string;
  intervalHours: number;
  intervalDays: number;
  workingDays: number;
  [key: string]: string | number | any;
}

export const InitActiveRow: ActivityRow = {
  activityName: "",
  id: "",
  color: "",
  intervalHours: 0,
  intervalDays: 0,
  workingDays: 0,
};
export enum Formtypes {
  RoutineService = "Routine Service",
  SpecialServicing = "Special Servicing",
  Other = "Other",
}
