/** navigation action */
export const FETCH_GROUP_REQUEST = "navi/FetchGroupRequest";
export const FETCH_GROUP_FLEET_REQUEST = "navi/FetchGroupFleetsRequest";
export const FETCH_WING_REQUEST = "navi/FetchWingRequest";

/**Clear reducers data */
export const CLEAR_RECORD='clearHistory'
export const CLEAR_SCHEDULER_DETAILS ='fleetSchedule/ClearHistory'
export const LOGOUT = 'authUser/Logout'

export const clearHistory = () => {
  return { type: CLEAR_RECORD };
};


export const clearFleetScheduleHistory = () => {
  return { type: CLEAR_SCHEDULER_DETAILS };
};


export const userLogout = () => {
  return { type: LOGOUT };
}