import React, { CSSProperties } from "react";
import aircraft from "./aircraft.svg";

interface Props {
  className?: string;
  style?: CSSProperties;
}

const AircraftImage: React.FC<Props> = ({ className, style }) => {
  return (
    <img alt="aircraft" className={className} style={style} src={aircraft} />
  );
};

export default AircraftImage;
