//Note: fix spelling errors after backend api fixed

//Navigation endpoints(login page)
export const GROUP = "/api/v1/Group";
export const FLEET_CONFIGURATION = "/api/v1/FleetConfiguration";
export const WINGS_GROUP = "api/v1/Wings/Group";

//Asset availability endpoints
export const FLEET = "/api/v1/fleet";
export const ASSET_AVAILABILITY = "/api/v1/AssetsAvailability";
export const AVAILABILITY_CALCULATION =
  "/api/v1/AssetsAvailability/calculation";
export const MAINTENANCE_UNIT = "/api/v1/MaintenanceUnit";
export const FLEET_BUILD_CONFIGURATION =
  "/api/v1/FleetConfiguration/fleetbuild";

export const FACILITY = "/api/v1/Facility";

//BaseLine calender endpoint
export const PUBLIC_HOLIDAY = "/api/v1/PublicHoliday/year";
export const HOLIDAY = "/api/v1/PublicHoliday";
export const FLEET_CONFIGURATION_HOLIDAY =
  "/api/v1/PublicHoliday/OperationalHoliday/FleetConfiguration";
export const FLEET_EVENT = "/api/v1/Event/year";
export const FLEET_BASELINE_FLEET = "api/v1/FleetBaseLine/fleetconfiguration";
export const FLEET_BASELINE = "/api/v1/FleetBaseLine";
export const SET_EVENT = "/api/v1/Event";
export const GET_EVENT_BY_FLEET_CONFIGURATION =
  "/api/v1/Event/fleetConfiguration";
export const GET_EVENT_LOCATIONS = "/api/v1/EventLocation/FleetConfiguration";
export const SET_EVENT_LOCATION = "/api/v1/EventLocation";

//Simulation engine
export const SIMULATION_ENGINE =
  "/api/v1/SimulationEngine/AircraftHoursOptimization";
export const BASELINE_YEAR_PLAN = "/api/v1/SimulationEngine/BaselineYearPlan";
export const OPTIMIZED_SCHEDULE =
  "/api/v1/SimulationEngine/OptimizedScheduleReal";
export const OPTIMIZED_SCHEDULE_ASYNC =
  "/api/v1/SimulationEngine/OptimizedScheduleRealAsync";
export const MAINTENANCE_ASSIGNMENTS = "/api/v1/MaintenanceAssignment";
export const GET_MAINTENANCE_SCHEDULES = "/api/v1/MaintenanceSchedule";
export const DELETE_MAINTENANCE_SCHEDULES = "/api/v1/FleetSchedule";
export const REGENERATE_MAINTENANCE_SCHEDULES = "/api/v1/FleetSchedule";

export const SIMULATION_ENGINE_AVAILABLE_AIRCRAFT =
  "/api/v1/SimulationEngine/AvailableAircraft";
export const GET_FATIGUELIFEDATA = "/api/v1/ReportData/GetFatigueLifeData";
//Build Fleet Structure Endpoints (step 1.2)
export const GET_UNIT_LOCATION = "/api/v1/UnitLocation";
export const SQUADRON = "/api/v1/Squadron";
export const ASSIGN_CONFIGURATION = "/api/v1/FleetConfiguration/assign";
//Fleet Configuration Summery
export const CHANGE_CONFIGURATION = "/api/v1/FleetConfiguration/ChangeKey";
export const DUPLICATE_CONFIGURATION =
  "/api/v1/FleetConfiguration/DuplicateFleetConfiguration";

//Event calender

export const OPERATIONAL_EVENT = "/api/v1/OperationalEvent";
export const OPERATIONAL_EVENT_SUMMARY =
  "/api/v1/OperationalEvent/GetOperationalEventSummary";

export const GET_OPERATIONAL_EVENT =
  "/api/v1/OperationalEvent/FleetConfiguration";
export const GET_OPERATIONAL_EVENT_WEEK_VIEW =
  "/api/v1/OperationalEvent/ViewCalender";
export const GET_MONTH_CALENDER = "api/v1/OperationalEvent/MonthlyViewCalender";

export const FLEET_BY_WING = "api/v1/Fleet/wing";
export const ASSETS = "/api/v1/Assets";

//Operating Capability
export const GET_OPERATING_CAPABILITY_EVENTS =
  "/api/v1/OperationalEvent/OperatingCapabilityEvent";
export const ASSIGN_OPERATIONAL_EVENT = "/api/v1/Assets/AssignOperationalEvent";
//fleet operational status
export const OPERATIONS_IN_REPORTING = "api/v1/OperationsInReporting";

export const MARK_AS_PUBLIC = "/api/v1/FleetConfiguration/MarkAsPublic";

//AirCraft Configuration
export const AIRCRAFT_CONFIGURATION = "/api/v1/AirCraftConfiguration";
export const FLEET_AIRCRAFT_CONFIGURATION =
  "/api/v1/AirCraftConfiguration/FleetConfiguration/";
//Maintenance Element (6.1 step)
export const MAINTENANCE_ELEMENT = "/api/v1/MaintenanceRoutineElement";
export const MAINTENANCE_ACTIVITY_TYPES = "/api/v1/MaintenanceActivityType";
//flight pro
export const GET_EVENTS_FLIGHT_PRO = "/api/v1/FlightPro";
export const GET_ASSET_MISSON_MAINTENACE_DATA =
  "/api/v1/FlightPro/GetAssetMissionMaintenanceData";
export const FLIGHT_PRO_CREATE_STRIP_GROUP =
  "/api/v1/FlightPro/CreateStripGroup";
export const GET_FLIGHT_PRO_CONFIGURATIONS =
  "/api/v1/FlightPro/FlightProConfigurations";

//Assert relate
export const GET_ASSETS_CONFIGURATION = "/api/v1/Assets";
export const PRODUCT_SCHEDULE = "/api/v1/Assets/ProductSchedule";
export const ASSETS_VIEW_CALENDER = "/api/v1/Assets/ViewCalender";
export const ASSETS_MONTH_CALENDER = "/api/v1/Assets/ViewMonthlyCalender";
export const ALL_PRODUCT_SCHEDULE_BY_WEEKS =
  "/api/v1/Assets/AllProductSchedulesByWeeks";

//Scenario Models
export const SCENARIO_MODEL = "/api/v1/ScenarioModel";
//User Details
export const USER_BY_EMAIL = "/api/v1/User";
export const ALL_PRODUCT_SCHEDULES = "/api/v1/Assets/AllProductSchedules";

//Fleet schedule
export const MAINTENANCE_ASSIGNMENT =
  "/api/v1/MaintenanceAssignment/FleetConfiguration";

export const MAINTENANCE_ASSIGNMENT_HISTORY =
  "/api/v1/MaintenanceAssignmentHistory";
export const MAINTENANCE_ASSIGNMENT_EXPORT = "api/v1/FleetSchedule/schedule";
export const CONVERT_OPTIMIZED_AS_USER_VERSION =
  "/api/v1/MaintenanceAssignmentHistory/DuplicateOptimizedVersion";
export const ASSIGNMENT_HISTORY_MARK_AS_PUBLIC =
  "/api/v1/MaintenanceAssignmentHistory/MarkAsPublic";

export const UPDATE_MAINTENANCE_ASSIGNMENT =
  "/api/v1/MaintenanceAssignment/UpdateMaintenanceAssignment";
export const GET_ENG_OVERHAUL_DATA =
  "/api/v1/MaintenanceAssignment/GetEngineOverhaul";

export const GET_ALPINE_DATA =
  "/api/v1/MaintenanceSummaryReport/GetMaintenanceSummaryReport";

export const GET_OPERATIONAL_CAPACITY_DATA =
  "/api/v1/MaintenanceSummaryReport/GetOperationalCapacityChartData";

export const PRESERVATION = "/api/v1/MaintenanceAssignment/EnginePreservation";

export const BAY_LIST = "/api/v1/Bay";

export const ADD_MAINTAIN_ACTIVITIES =
  "/api/v1/MaintenanceAssignment/AddMaintenanceAssignment";
export const UPDATE_MAINTAIN_ACTIVITIES =
  "/api/v1/MaintenanceAssignment/UpdateMaintenanceAssignment";

//
export const AVAILABILITY_SERVICEABILITY = "/api/v1/ReportFleetWD";
export const FLEET_TARGET = "/api/v1/FleetTarget";
export const FLEET_TARGET_SCENARIO_MODEL = "/api/v1/FleetTarget/ScenarioModel";
export const GET_FLEET_ROE = "/api/v1/MaintenanceSummaryReport/GetFleetROE";
export const FLEET_SUMMARY_SCHEDULE =
  "/api/v1/MaintenanceSummaryReport/GetFleetSummarySchedule";
export const GET_HOURS_AVAILABILITY = "api/v1/ReportData/GetHoursAvailable";
//uuc
export const UUCREPORT = "/api/v1/AssetUuc/GetAssetUucReport";

export const TABS = "/api/v1/Tab";
export const GET_RESOURCES_CONSTRAINS =
  "/api/v1/ReportData/GetResourceConstraints";

//Maintenance BaseLine
export const MAINTENANCE_BASELINE_CONFIGURATION =
  "api/v1/MaintenanceBaseLine/fleetconfiguration";
export const MAINTENANCE_BASELINE = "/api/v1/MaintenanceBaseLine";
export const MAINTENANCE_LOCATION =
  "api/v1/MaintenanceFacility/GetMaintenanceLocationData";
export const MAINTENANCE_ROUTINE_ELEMENTS =
  "/api/v1/MaintenanceRoutineElement/FleetConfiguration";
export const ASR_REPORT = "/api/v1/ReportFleetWD";
export const GET_FLEET_CONFIG_ID = "/api/v1/FleetConfiguration/ScenarioModel";
export const GET_ACTIVE_FLEET_CONFIG =
  "/api/v1/FleetConfiguration/GetActiveFleetConfiguration";
export const GET_ACTIVE_SCENARIO_MODEL =
  "/api/v1/ScenarioModel/GetActiveScenarioModelData";
export const MAINTENANCE_BASELINE_CALENDER_GENERATE =
  "/api/v1/MaintenanceBaseLine/BaseLineCalendar";

export const GET_MAINTENANCE_ACTIVITIES =
  "/api/v1/MaintenanceAssignmentHistory/GetMaintenanceAssignmentsByHistory";

export const MARK_AS_BASELINE =
  "/api/v1/MaintenanceAssignmentHistory/MarkAsBaseline";
export const GET_ACTIVE_MAINTENANCE_ASSIGNMENT =
  "api/v1/MaintenanceAssignmentHistory/GetActiveMaintenanceAssignment/FleetConfigurationId";
export const GET_MAINTENANCE_HOLIDAYS =
  "/api/v1/PublicHoliday/MaintenanceHoliday/FleetConfiguration";
export const BASELINE_CALENDAR_GENERATE =
  "api/v1/FleetBaseLine/BaseLineCalendar";

export const HOLIDAYS_FOR_FLEETCONFIG =
  "/api/v1/PublicHoliday/FleetConfiguration";

export const GET_WORK_ORDER_DATA = "/api/v1/WorkOrder";
export const REMOVE_MAINTENANCE_UNIT =
  "/api/v1/MaintenanceRoutineElement/RemoveMaintenanceUnit";
export const GET_MAINTENANCE_HISTORY_BY_CONFIGURATION =
  "/api/v1/MaintenanceAssignmentHistory/FleetConfiguration";
export const SEED_CONFIGURATION = "/api/v1/Data/SeedFleetConfiguration";

export const MODEL_MARK_AS_PUBLIC = "/api/v1/ScenarioModel/MarkAsPublic";

// perations Management
export const AV_AIRFRAME_LIMITS = "/api/v1/ReportData/GetAirFrameLimitsData";
export const ENGINE_LIMITS = "/api/v1/ReportData/GetEngineLimitsData";
export const DETAILED_AIRCRAFT_LIMIT =
  "/api/v1/ReportData/GetAircraftComponentLimitsData";
export const LIFE_SUPPORT_EQUIPMENT =
  "/api/v1/ReportData/GetLifeSupportEquipmentData";

export const DELIVERY_ACCEPTANCE_SCHEDULE_DATA =
  "api/v1/ReportData/GetAllDeliveryAcceptanceScheduleData";
export const SEED_WHAT_IF_DATA = "/api/v1/Data/SeedWhatIfData";

// Delivery Acceptance Schedule

export const TRANSITION_PLAN =
  "/api/v1/ReportDeliveryAcceptanceCharts/GetTransitionPlanChartData";

export const SQUADRON_CHART =
  "/api/v1/ReportDeliveryAcceptanceCharts/GetSquadronChartData";
export const CONFIGURATION_CHART =
  "/api/v1/ReportDeliveryAcceptanceCharts/GetConfigurationChartData";
export const CAMM2DATA = "/api/v1/MaintenanceBroadcast/GetCamm2MaintenanceData";

export const OPERATIONAL_CONFIGURATION = "api/v1/OperationalConfiguration";
export const RESET_CAMM2_DATA = "/api/v1/MaintenanceBroadcast";
export const CREATE_FLIGHT_PRO = "/api/v1/FlightPro";

export const CALCULATE_DAY_RATE =
  "/api/v1/MaintenanceActivityType/CalculateDayRate";

export const GET_MAINTENANCE_ASSIGNMENT_BY_HISTORY =
  "/api/v1/MaintenanceAssignmentHistory/GetMaintenanceAssignmentsByHistory";
export const GET_ASSET_INTEGRATED_OPERATIONS =
  "/api/v1/ReportData/GetAssetIntegratedOperations";
export const GET_FLEET_SUMMARY = "/api/v1/AssetUuc/GetFleetSummaryStatus";
