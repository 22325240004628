import React from "react";
import icon from "./icon.svg";

interface Props {
  className?: string;
  handleChange?: () => void;
  id?: string;
}

const CalenderIcon: React.FC<Props> = ({ className, handleChange, id }) => {
  return (
    <img
      id={id}
      alt="CalenderIcon"
      className={className}
      src={icon}
      onClick={handleChange}
    />
  );
};

export default CalenderIcon;
